import React, {useCallback, useContext, useEffect, useRef, useState} from "react"
import {login} from "service/auth.service"
import {AppContext} from "lib/contextLib";
import {Link, useNavigate} from "react-router-dom";
import {Button, ButtonToolbar, Content, FlexboxGrid, Form, Panel, toaster} from "rsuite";
import {LoginFormModel} from "lib/formModels";
import {TextField} from "components/textField";
import {NotificationCustom} from "components/common/notification";
import '../styles/login.css'
import Config from "../config.json";
import {GoogleLoginButton} from "react-social-login-buttons";
import {LoginSocialGoogle} from "reactjs-social-login";
import {CustomSmallSpinner} from "../components/common/spinner";


export function Login() {

    const googleRef = useRef();
    const formRef = React.useRef();
    let navigate = useNavigate();
    const {setUser} = useContext(AppContext);

    const [payload, setPayload] = useState({email: "", password: ""});
    const [formError, setFormError] = React.useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('user')) {
            navigate('/profile');
        }
    }, []);


    const handleLogin = async (payloadSocial, isSocial = false) => {
        toaster.clear()
        setLoading(true);
        const response = await login(isSocial ? payloadSocial : {...payload, isSocial});
        if (response.status) {
            setUser(response.data);
            setLoading(false);
            navigate('/profile');
        } else {
            setUser(null);
            toaster.push(
                (
                    <NotificationCustom type='error'>
                        {response.message}
                    </NotificationCustom>
                )
                , {placement: 'topStart'})
            setLoading(false);
        }
    }


    const onLoginStart = useCallback(() => {
        // alert("login start");
        setLoading(true)
    }, []);

    const onLogoutFailure = useCallback(() => {
        alert("logout fail");
    }, []);

    const onLogoutSuccess = useCallback(() => {
        alert("logout success");
    }, []);

    //
    // const handleCreate = async (payload) => {
    //     toaster.clear()
    //     const res = await login(payload, true)
    //     if (res.status) {
    //         toaster.push(<NotificationCustom type="success" placement={'topEnd'} header='Success'>Account Created</NotificationCustom>);
    //         navigate('/login');
    //     } else {
    //         toaster.push(<NotificationCustom type="error" placement={'topEnd'} header='Error'> {res.message}</NotificationCustom>);
    //     }
    //     setLoading(false)
    // };

    return (
        <div className="wrapper login-page text-start">
            <Content>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={12}>
                        <Panel
                            // style={{backgroundColor:'#0C3144'}}
                            header={
                                <h3 className='default-color'>Login</h3>
                            }
                            bordered shaded
                        >

                            <Form
                                ref={formRef}
                                onChange={setPayload}
                                onCheck={setFormError}
                                formValue={payload}
                                model={LoginFormModel}
                                fluid
                            >
                                <TextField name="email" label="Email" helpText='Required'/>
                                <TextField name="password" type='password' label="Password" helpText='Required'/>

                                <Form.Group>
                                    <ButtonToolbar>
                                        <Button
                                            appearance="primary"
                                            // type="submit"
                                            // disabled={loading}
                                            onClick={handleLogin}
                                            disabled={Object.keys(formError).length !== 0 || loading}
                                            loading={loading}
                                            className="default-bgcolor login-btn"
                                        >{loading ? 'LOGGING IN' : 'LOGIN'}
                                        </Button>
                                        <h3 style={{textAlign: 'center'}}>OR</h3>

                                        {
                                            loading ? <CustomSmallSpinner sm={true}/> :

                                                <LoginSocialGoogle
                                                    scope={'profile email'}
                                                    ref={googleRef}
                                                    style={{margin: 0}}
                                                    client_id={Config.REACT_APP_GG_APP_ID}
                                                    onLogoutFailure={onLogoutFailure}
                                                    onLoginStart={onLoginStart}
                                                    redirect_uri={Config.REDIRECT_URI}
                                                    onLogoutSuccess={onLogoutSuccess}
                                                    onResolve={async ({provider, data}) => {

                                                        const payload = {
                                                            email: data.email,
                                                            isSocial: true,
                                                        }
                                                        await handleLogin(payload, true)

                                                    }}
                                                    onReject={(err) => {
                                                        console.log("Error", err);
                                                        setLoading(false)
                                                    }}
                                                >
                                                    <GoogleLoginButton>
                                                        <span>Log In With Google</span>
                                                    </GoogleLoginButton>
                                                </LoginSocialGoogle>
                                        }

                                        <Button
                                            as={Link}
                                            to='/forget_password'
                                            className="rs-btn rs-btn-link"
                                            style={{width: '100%', margin: '5px 0'}}
                                        >
                                            Forgot Your Password?
                                        </Button>

                                        <Button
                                            as={Link}
                                            to="/register"
                                            className='new-to-eduinfo rs-btn rs-btn-link'
                                            style={{width: '100%', margin: '25px 0 5px 0', color: 'white'}}
                                        >
                                            New To EDUINFO? <span className="default-color">Register Now</span>
                                        </Button>


                                    </ButtonToolbar>
                                </Form.Group>


                            </Form>

                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </div>
    );
}