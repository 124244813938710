import {convertMediaToArray} from "../lib/utility";
import Config from "../config.json";
// import jsonwebtoken from "jsonwebtoken";
import crypto from "crypto-js";

const baseUrl = Config.BASE_API_URL;
const generateS3Url = baseUrl + Config.GENERATE_S3_URL;
const deleteObjectUrl = baseUrl + Config.DELERE_OBJECT_URL;

export async function generateS3BucketUrl(totalImages, token) {
    const url = `${generateS3Url}${totalImages}`;
    const response = await fetch(url, {
        method: 'GET',
        headers:  getHeaderToken(token)
    })

    return await response.json();
}

export async function generateS3BucketExtensionUrl(totalImages,extension, token) {
    const url = `${generateS3Url}${totalImages}&extension=${extension}`;
    const response = await fetch(url, {
        method: 'GET',
        headers:  getHeaderToken(token)
    })

    return await response.json();
}


export async function uploadTOS3BucketUrl(file, url) {

    const response = await fetch(url, {
        method: 'PUT',
        headers:  {
            "Content-Type": "multipart/form-data"
        },
        body: file
    })

    if (response.status === 200) {
      return {status: true, message: "Success uploaded"};
    }else {
        return {status: false, message: "Unable to upload"};
    }
}

export async function deleteObject(key,token) {
    const url = `${deleteObjectUrl}${key}`;

    // const token = crypto.AES.encrypt(new Date(), Config.JWT_SECRET_KEY_DELETE_S3_OBJECT).toString();

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {token},
    })

    if (response.status === 200) {
      return {status: true, message: "Success uploaded"};
    }else {
        return {status: false, message: "Unable to upload"};
    }
}



export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
