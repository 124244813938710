import React, {useState} from 'react';
import {Button, ButtonToolbar, Col, Content, Form, Grid, Row} from "rsuite";
import {RegisterFormModel} from "lib/formModels";
import {TextField} from "components/textField";

const JobSeekerRegister = () => {
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const [formValue, setFormValue] = React.useState({
        name: '',
        email: '',
        age: '',
        password: '',
        verifyPassword: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = () => {
        setIsSubmitted(true)
        if (!formRef.current.check()) {
            console.error('Form Error');
            setIsSubmitted(false)
            return;
        }

        setIsSubmitted(false)
        // console.log(formValue, 'Form Value');
    };

    const handleCheckEmail = () => {
        formRef.current.checkForField('email', checkResult => {
            console.log(checkResult);
        });
    };

    return (

        <Content>
            <Grid fluid>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    disabled={isSubmitted}
                    model={RegisterFormModel}
                    fluid
                >






                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="name" label="Username" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="email" label="Email" helpText='Required' type="email"/>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>

                            <TextField name="password" label="Password" helpText='Required' type="password" autoComplete="off"/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="verifyPassword" label="Verify password" helpText='Required' type="password"
                                       autoComplete="off"/>
                        </Col>
                    </Row>

                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary"
                                // type="submit"
                                    onClick={handleSubmit}
                                    loading={isSubmitted}
                                    disabled={Object.keys(formError).length !== 0 || isSubmitted}

                            >Submit</Button>
                            {/*<Button appearance="default">Cancel</Button>*/}
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Grid>
        </Content>
    );
};

export default JobSeekerRegister;
