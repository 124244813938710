import Config from "../config.json";

const baseUrl = Config.BASE_API_URL;

const getBanners = baseUrl + Config.GET_BANNERS_URL


export async function getAllBanners() {
    const response = await fetch(`${getBanners}`)
    const temp = await response.json();
    if (temp.status) {
        return {data: temp.data.Data, count: temp.data.count};
    }
    return {data: [], count: 0};
}

// export async function getAllAnnCat() {
//     const response = await fetch(`${getAnnCatUrl}`)
//     const temp = await response.json();
//     if (temp.status) {
//         return {data: temp.data.annData, count: temp.data.count};
//     }
//     return {data: [], count: 0};
// }

// export async function getAnnouncements(com_id, token) {
//     const url = getBlogsUrl + '/' + com_id;
//     const response = await fetch(url, {
//         method: 'GET',
//         headers:  getHeader(token)
//     })
//
//     return await response.json();
// }

// export async function getAnnouncementsDetail(ann_id) {
//
//     const url = getBlogsUrl + '/' + ann_id;
//     const response = await fetch(url)
//
//     let tempData = await response.json();
//     if (tempData.status) {
//         return tempData.data;
//     }
//
//     return tempData
//
//
//     // return await response.json();
// }
//
// export async function getAnnouncementsType(token = '') {
//
//     const response = await fetch(getCompanyTypeUrl, {
//         method: 'GET',
//         headers: getHeader(token)
//     })
//
//     const responseData = await response.json();
//     if (!responseData.status)
//         return []
//
//     return responseData.data.map(item => {
//         return {value: item.cot_id, label: item.name}
//     })
//
// }
//
//
// function getHeader(token = '') {
//     return {
//         "Content-Type": "application/json",
//         'Accept': 'application/json',
//         'Authorization': `Bearer ${token}`,
//     }
// }
//
// function getMultipartHeader(token = '') {
//     return {
//         'Content-Type': 'multipart/form-data',
//         'Authorization': `Bearer ${token}`,
//     }
// }
//
//
// export function getHeaderToken(token = '') {
//     return {
//         'Authorization': `Bearer ${token}`,
//     }
// }
