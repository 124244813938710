import Config from "../../config.json";
import React, {useEffect, useState} from "react";
import {CustomSpinner} from "../common/spinner";
import {createReview, getAllReviews} from "../../service/review.service";
import Rating from "@mui/material/Rating";
import RatingCarousel from "react-bootstrap/Carousel";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Box from "@mui/material/Box";
import {Button, Col, List, Row, toaster} from "rsuite";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {getCurrentUser} from "../../service/auth.service";
import Hidden from "@mui/material/Hidden";
import TextField from "@mui/material/TextField";
import {NotificationCustom} from "../common/notification";

export const CommunityRating = ({id, type}) => {
    const baseUrl = Config.BASE_API_URL;
    const [resData, setResData] = React.useState([]);
    const [hasMore, setHasMore] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = useState(0);
    const [index, setIndex] = useState(0);

    useEffect(async () => {
        setLoading(true);
        await fetchData();
        setLoading(false);
    }, []);
    if (loading) return <CustomSpinner/>;
    const fetchData = async () => {
        const url = `${baseUrl}/api/client/review?review_type_id=${id}&review_type=${type}`;
        const response = await getAllReviews(url);
        setValue(average(response.data.map(d => d.rating)));
        setResData(response?.data)
    };
    const average = arr => arr.reduce((a, b) => a + b, 0) / arr.length;

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <div>
            <h1 style={{color: "red", textAlign: "left"}}>Community Rating</h1>
            {
                resData.length === 0 ?
                    <div style={{textAlign: 'center'}}>
                        No Reviews
                    </div> :
                    <>

                        <Rating name="read-only" value={value} readOnly/>
                        <RatingCarousel
                            activeIndex={index}
                            touch={true}
                            onSelect={handleSelect}
                            variant="light"
                            controls={true}
                            fade={false}
                            keyboard={true}
                            prevIcon={
                                <>
                                    <ArrowCircleLeftIcon/>
                                </>
                            }
                            nextIcon={
                                <>
                                    <ArrowCircleRightIcon/>
                                </>
                            }
                        >
                            {resData?.map((d, index) => (

                                <RatingCarousel.Item key={index}>
                                    <Box
                                        sx={{
                                            "& > legend": {mt: 2},
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}
                                    >
                                        <List hover>
                                            <List.Item
                                                style={{
                                                    maxWidth: "50em",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                }}

                                            >

                                                <h5 style={{padding: "8px 16px"}}>{d?.full_name}</h5>
                                                {/*<ListItem alignItems="flex-start">*/}
                                                {/*    /!*<ListItemAvatar>*!/*/}
                                                {/*    /!*    <Avatar*!/*/}
                                                {/*    /!*        alt={d?.user?.full_name}*!/*/}
                                                {/*    /!*        src={d?.user?.image}*!/*/}
                                                {/*    /!*    />*!/*/}
                                                {/*    /!*</ListItemAvatar>*!/*/}
                                                {/*    <ListItemText*/}
                                                {/*        primary={d?.full_name}*/}
                                                {/*        // secondary={*/}
                                                {/*        //     <React.Fragment>*/}
                                                {/*        //         <Typography*/}
                                                {/*        //             sx={{display: "inline"}}*/}
                                                {/*        //             component="span"*/}
                                                {/*        //             variant="body2"*/}
                                                {/*        //             color="text.primary"*/}
                                                {/*        //         >*/}
                                                {/*        //             Ali Connors*/}
                                                {/*        //         </Typography>*/}
                                                {/*        //         {" — I'll be in your neighborhood doing errands this…"}*/}
                                                {/*        //     </React.Fragment>*/}
                                                {/*        // }*/}
                                                {/*    />*/}
                                                {/*</ListItem>*/}
                                                <ListItem>
                                                    <Typography
                                                        variant="body1"
                                                        gutterBottom
                                                        style={{color: "#333"}}
                                                    >
                                                        {d?.message}
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Rating name="read-only" value={d?.rating} readOnly/>
                                                </ListItem>
                                            </List.Item>
                                        </List>
                                    </Box>
                                </RatingCarousel.Item>
                            ))
                            }
                        </RatingCarousel>
                    </>
            }
        </div>
    );
};
export const YourReviews = ({id, type}) => {
    const [value, setValue] = React.useState(5);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
    const [isSubmited, setIsSubmited] = useState(true);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(localStorage.getItem('user'));
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [user, setUser] = useState(getCurrentUser());


    const formData = {
        "Rating:": value,
        "full_name:": fullName,
        "email": email,
        "comment": comment,
        "review_type": type,
        "review_type_id": id,
    };

    // let navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        toaster.clear()
        setIsSubmited(false)
        // if (!isUserLoggedIn) {
        //     handleOpen();
        //     return;
        // }

        // const data = new FormData(event.currentTarget);
        const payload = {
            "email": email,
            "name": fullName,
            "message": comment,
            "rating": value,
            "review_type": type,
            "review_type_id": id
        }
        console.log(payload);
        const response = await createReview(payload)

        if (response.status) {
            toaster.push(<NotificationCustom type="success" placement={'topEnd'} header='Success'></NotificationCustom>);
            setIsSubmited(true)
            setFullName('');
            setEmail('');
            setComment('');
            setValue(0);
        } else {
            toaster.push(<NotificationCustom type="error" placement={'topEnd'} header='Error'> {response.message}</NotificationCustom>);
        }

        setIsSubmited(true)
    };
    return (
        <div>
            <h1 style={{color: "red", textAlign: "left"}}>Your Reviews</h1>
            <Box sx={{width: "100%"}} component="form" onSubmit={handleSubmit}>
                <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                />
                <Hidden only={["xs", "sm"]}>
                    <Row>
                        <Col>
                            &nbsp;
                            <TextField
                                required
                                id="outlined-required"
                                autoComplete="name"
                                name="full_name"
                                value={fullName}
                                onChange={e => setFullName(e.target.value)}
                                label="Full Name"
                                style={{width: "26em", height: "5em"}}
                                disabled={!isSubmited}
                            />
                        </Col>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <Col>
                            &nbsp;
                            <TextField
                                required
                                id="outlined-required"
                                autoComplete="email"
                                label="Email Address"
                                name="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                disabled={!isSubmited}
                                style={{width: "26em", height: "5em"}}
                            />
                        </Col>
                    </Row>
                </Hidden>
                <Hidden only={["xl", "lg", "md"]}>
                    <Row>
                        <Col>
                            &nbsp;
                            <TextField
                                required
                                id="outlined-required"
                                autoComplete="name"
                                name="full_name"
                                value={fullName}
                                onChange={e => setFullName(e.target.value)}
                                label="Full Name"
                                disabled={!isSubmited}
                                style={{width: "18em", height: "5em"}}
                            />
                        </Col>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <Col>
                            &nbsp;
                            <TextField
                                required
                                id="outlined-required"
                                autoComplete="email"
                                label="Email Address"
                                name="email"
                                onChange={e => setEmail(e.target.value)}
                                style={{width: "18em", height: "5em"}}
                            />
                        </Col>
                    </Row>
                </Hidden>
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "100%",
                    }}
                >
                    <Hidden only={["sm", "xs"]}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Comment"
                            name="comment"
                            multiline
                            autoComplete="comment"
                            rows={6}
                            value={comment}
                            style={{width: "53.2em"}}
                            fullWidth
                            disabled={!isSubmited}
                            onChange={(e) => {
                                setComment(e.target.value);
                            }}
                        />
                    </Hidden>
                    <Hidden only={["lg", "xl", "md"]}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Comment"
                            name="comment"
                            multiline
                            autoComplete="comment"
                            rows={6}
                            value={comment}
                            style={{width: "18.5em"}}
                            fullWidth
                            disabled={!isSubmited}
                            onChange={(e) => {
                                setComment(e.target.value);
                            }}
                        />
                    </Hidden>
                </Box>
                <br/>
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "100%",
                    }}
                >
                    {
                        <Button
                            // variant="outlined"
                            // size="large"
                            // style={{ color: "red", borderColor: "red" }}
                            disabled={!isSubmited}
                            loading={!isSubmited}
                            className={'d-w-r-btn'}
                            style={{width: 'auto'}}
                            type="submit"
                        >
                            Send Message
                        </Button>
                    }
                </Box>
            </Box>
            {/*<Modal open={open} onClose={handleClose}>*/}
            {/*    <Modal.Header>*/}
            {/*        <Modal.Title>Log In / Sign Up</Modal.Title>*/}
            {/*    </Modal.Header>*/}

            {/*    <Modal.Body>*/}
            {/*        <div>*/}
            {/*            <p>To Give a review, you have to log in first</p>*/}
            {/*            <Button as={Link} to={'/login'} className={'d-w-r-btn'} style={{width: 'auto', marginRight: '10px'}}>*/}
            {/*                Log In*/}
            {/*            </Button>*/}

            {/*            <Button as={Link} to={'/signup'} className={'d-w-r-btn'} style={{width: 'auto'}}>*/}
            {/*                Sign Up*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*    </Modal.Body>*/}
            {/*    <Modal.Footer>*/}
            {/*        <Button className={'d-r-w-btn'} style={{width: 'auto'}} onClick={handleClose} appearance="subtle">*/}
            {/*            Close*/}
            {/*        </Button>*/}
            {/*    </Modal.Footer>*/}
            {/*</Modal>*/}
        </div>
    );
};
