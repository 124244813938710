import Config from "../config.json";

const baseUrl = Config.BASE_API_URL;


//
// export async function getJobSeeker(jos_id, token) {
//
//     const response = await fetch(getUrl + '/' + jos_id , {
//         method: 'GET',
//         headers: getHeader(token)
//     })
//     let response_josn = await response.json()
//
//     if (response_josn.status) {
//         // console.log(response_josn.data)
//
//
//         response_josn.data.dob = response_josn?.data?.dob ? new Date(response_josn?.data?.dob) : new Date()
//
//         const cit_id = response_josn?.data?.city?.cit_id
//         const cou_id = response_josn?.data?.city?.country?.cou_id
//         response_josn.data.city = cit_id ? cit_id : -1
//         response_josn.data.country = cou_id ? cou_id :-1
//         response_josn.data.skill = response_josn?.data?.skill.map(({name})=>name)
//         // console.log(response_josn.data)
//
//
//         return response_josn;
//     } else {
//         return response_josn;
//     }
// }

export async function createContactUs(payload,) {

    const response = await fetch(`${baseUrl}/api/client/contactus/create`, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
        }

    })

    return await response.json();


}



function getHeader(token = '') {
    return {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
