import Config from "../config.json";

const baseUrl = Config.BASE_API_URL;
const loginUrl = baseUrl + Config.LOGIN_USER_URL
const registerUrl = baseUrl + Config.REGISTER_USER_URL
const updateUrl = baseUrl + Config.UPDATE_USER_URL
const sendTokenUrl = baseUrl + Config.SEND_TOKEN_URL


export async function login(payload) {

    // console.log(payload);

    const response = await fetch(loginUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers:  getHeader()
    })

    const responseData = await response.json();

    if(responseData.status){
        if (responseData.data.access_token) {
            localStorage.setItem("user", JSON.stringify(responseData.data));
            return responseData;
        }else{
            return {
                status: false,
                message: "Token not found",
                data: {}
            }
        }

    }else {
        return responseData;
    }
}

export function logout() {

    localStorage.removeItem("user");
    // setUser(null)
    // window.location.reload();

    // return <Negative to='/login' />
}

export async function register(payload,isSocial=false) {

    const response = await fetch(registerUrl, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader()
    })

    const responseData = await response.json();

    if(responseData.status && !isSocial ){
        const emailResponse = await sendToken(responseData.data.use_id)
        if(emailResponse.status) {
            return {
                status: true,
                message: "User created successfully, please check your email to verify your account",
                data: {}
            }
        }else {
            return {
                status: false,
                message: "User created successfully, but email not sent, Resend Email",
                data: {}
            }
        }

    }else{
        return responseData;
    }

}

export async function forgetpassword(payload) {

    const response = await fetch(baseUrl + Config.SEND_TOKEN_URL, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader()
    })

    return await response.json();
}

export async function checkEmail(payload) {

    const response = await fetch(baseUrl + Config.CHECK_EMAIL_URL, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader()
    })

    return await response.json();
}

export async function changePassword(payload, token) {

    const response = await fetch(baseUrl + Config.CHANGE_PASSWORD_URL, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader(token)
    })

    return await response.json();

    // if(responseData.status){
    //     return {
    //         status: true,
    //         message: "Password changed successfully",
    //         data: {}
    //     }
    // }else{
    //     return responseData;
    // }

}

export async function resetPassword(payload) {

    const response = await fetch(baseUrl + Config.RESET_PASSWORD_URL, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader()
    })

    return await response.json();
}


export function getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
}

export function setCurrentUser(user) {
    return localStorage.setItem('user', JSON.stringify(user));
}

async function  sendToken(userId) {

    const response = await fetch(sendTokenUrl, {
        body: JSON.stringify({userId}),
        method: 'POST',
        headers: getHeader()
    })

    return await response.json();

}


export async function updateUser(payload, token) {

    const response = await fetch(updateUrl, {
        body: JSON.stringify(payload),
        method: 'PUT',
        headers: getHeader(token)
    })

    return await response.json();

}



function getHeader(token = '') {
    return {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
