import React, {useEffect, useState} from "react";
import {CustomSpinner} from "components/common/spinner";
import {Carousel, Col, Container, Content, Divider, Grid, Panel, Row, Sidebar} from "rsuite";
import ReactPlayer from "react-player/lazy";
import {HiOutlineLocationMarker} from "react-icons/hi";
import "./../../styles/coursedetail.css";
import {useLocation, useParams} from "react-router-dom";
// import Button from "@mui/material/Button";
import Config from "../../config.json";
import {getCoursesDetail} from "service/course.service";
import {dateOptionsYYMMDD, dateTimeNumericOptions, ReadMore,} from "../../lib/utility";
import Hidden from "@mui/material/Hidden";
import {CommunityRating, YourReviews} from "../REVIEWS/reviews";


export default function CourseDetailView() {
    const location = useLocation();

    let {cou_id} = useParams();

    const baseUrl = Config.BASE_API_URL;
    const getCoursesDetailUrl = baseUrl + Config.GET_COURSES_DETAIL_URL;
    const getCoursesDetailIdUrl = `${getCoursesDetailUrl}${cou_id}`;
    const GOOGLE_MAP_API_KEY = Config.GOOGLE_MAP_API_KEY;

    const [resData, setResData] = useState({});
    const [loading, setLoading] = useState(true);
    const [cases, setCases] = useState("speakers");

    useEffect(async () => {
        setLoading(true);
        const resDetail = await getCoursesDetail(getCoursesDetailIdUrl);
        setResData(resDetail?.data);
        setLoading(false);
    }, []);

    if (loading) return <CustomSpinner/>;

    const CurrentPin = ({text}) => {
        return (
            <div>
                <HiOutlineLocationMarker color={"red"} size={28}/>
                <p style={{color: "red"}}>{text}</p>
            </div>
        );
    };

    function createMapOptions(maps) {
        // next props are exposed at maps
        // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
        // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
        // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
        // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
        // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
        return {
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL,
            },
            mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT,
            },
            mapTypeControl: true,
        };
    }

    return (
        <>
            <Hidden only={["xs", "sm"]}>
                <Container className="wrapper">
                    <Divider style={{background: "transparent", margin: "6px"}}/>
                    <Panel
                        bordered
                        style={{background: "#fff", border: "2px solid #ccc"}}
                    >
                        <div style={{display: "inline", width: 200}}>
                            <Grid fluid>
                                <Row className="show-grid">
                                    <Col xsHidden xs={12} md={6}>
                                        <img
                                            src={resData?.course_company[0]?.company.logo}
                                            style={{width: "180px", height: "180px"}}
                                        />
                                    </Col>
                                    <Col xs={12} md={16} style={{marginTop: "20px"}}>
                                        <h1 style={{color: "red", textAlign: "left"}}>
                                            {" "}
                                            {resData?.name}
                                        </h1>
                                        <h6>
                                            <b>Language: </b>
                                            {resData?.language}
                                        </h6>
                                        <h6>
                                            <b>Offered At: </b>
                                            {resData?.offeredAt}
                                        </h6>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    </Panel>

                    <Grid fluid>
                        <Row gutter={10} className="row flex-custom-reverse">
                            <Col
                                lg={16}
                                md={16}
                                sm={24}
                                xs={24}
                                style={{backgroundColor: "white", paddingBottom: "20px"}}
                            >
                                <Panel shaded style={{textAlign: "justify"}}>
                                    <div
                                        className="panel-margin"
                                        style={{textAlign: "justify"}}
                                    >
                                        <Container style={{textAlign: "justify"}}>
                                            <Container style={{textAlign: "justify"}}>
                                                <Sidebar>
                                                    <Panel
                                                        shaded
                                                        bordered
                                                        bodyFill
                                                        style={{textAlign: "justify", width: 340}}
                                                    >
                                                        <Panel>
                                                            <h5
                                                                style={{
                                                                    textAlign: "left",
                                                                    color: "red",
                                                                    fontSize: "1.5rem",
                                                                }}
                                                            >
                                                                DETAILS
                                                            </h5>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <p>
                                                                        <b>Language: </b>
                                                                    </p>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <p>{resData?.language}</p>
                                                                </Col>
                                                                {/* <Col xs={12}>
                              <p>
                                <b>Offered At: </b>
                              </p>
                            </Col>
                            <Col xs={12}>
                              <p>{resData?.offeredAt}</p>
                            </Col> */}
                                                                <Col xs={12}>
                                                                    <p>
                                                                        <b>Price: </b>
                                                                    </p>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <p>{resData?.price}</p>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <p>
                                                                        <b>Start Date: </b>
                                                                    </p>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <p>
                                                                        {new Date(
                                                                            resData?.startDate
                                                                        ).toLocaleDateString(
                                                                            "en-us",
                                                                            dateOptionsYYMMDD
                                                                        )}
                                                                    </p>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <p>
                                                                        <b>End Date: </b>
                                                                    </p>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <p>
                                                                        {new Date(
                                                                            resData?.endDate
                                                                        ).toLocaleDateString(
                                                                            "en-us",
                                                                            dateOptionsYYMMDD
                                                                        )}
                                                                    </p>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <p>
                                                                        <b>Duration: </b>
                                                                    </p>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <p>{resData?.duration} Min</p>
                                                                </Col>
                                                            </Row>
                                                            <h5
                                                                style={{
                                                                    textAlign: "left",
                                                                    color: "red",
                                                                    fontSize: "1.5rem",
                                                                }}
                                                            >
                                                                AVAILABILITY
                                                            </h5>
                                                            {resData?.availability.map((item, index) => {
                                                                return (
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <p>
                                                                                <b>{item.day}: </b>
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12}>
                                                                            <p>
                                                                                {new Date(item.time).toLocaleDateString(
                                                                                    "en-us",
                                                                                    dateTimeNumericOptions
                                                                                )}
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                );
                                                            })}
                                                        </Panel>
                                                    </Panel>
                                                </Sidebar>
                                                <Content>
                                                    {" "}
                                                    <div
                                                        style={{
                                                            textAlign: "justify",
                                                            marginLeft: "6em",
                                                        }}
                                                    >
                                                        <ReadMore
                                                            text={resData?.description}
                                                            words={1700}
                                                        />
                                                    </div>
                                                </Content>
                                            </Container>
                                        </Container>

                                        <Carousel>
                                            {resData?.media?.images?.map((src, index) => (
                                                <img
                                                    // src='/images/download.jpg'
                                                    src={`${src}`}
                                                    alt="Event Picture"
                                                />
                                            ))}
                                        </Carousel>
                                    </div>
                                </Panel>
                            </Col>
                            <Col
                                lg={8}
                                md={8}
                                sm={24}
                                xs={24}
                                style={{backgroundColor: "white", paddingBottom: "20px"}}
                            >
                                <Panel shaded>
                                    {resData?.course_company.map((d, i) => (
                                        <>
                                            <Panel
                                                bordered
                                                style={{background: "#fff", border: "1px solid #333"}}
                                            >
                                                <div style={{display: "inline"}}>
                                                    <Grid fluid>
                                                        <Row className="show-grid">
                                                            <Col xsHidden xs={12} md={6}>
                                                                <img
                                                                    src={d?.company?.logo}
                                                                    style={{width: "180px", height: "180px"}}
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={16}>
                                                                <h1
                                                                    style={{
                                                                        color: "darkblue",
                                                                        textAlign: "left",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {d?.company?.name}
                                                                </h1>
                                                                <h6>{d?.company?.foundYear}</h6>
                                                                <h6>
                                                                    <ReadMore
                                                                        text={d?.company?.description}
                                                                        words={180}
                                                                    />
                                                                </h6>
                                                            </Col>
                                                        </Row>
                                                    </Grid>
                                                </div>
                                            </Panel>
                                        </>
                                    ))}

                                    <br/>
                                    <CommunityRating id={resData?.cou_id} type={'COURSE'}/>
                                    <br/>
                                    <YourReviews id={resData?.cou_id} type={'COURSE'}/>
                                    <br/>
                                    {/* <div className="panel-margin"> */}
                                    {/* Google Map Block*/}

                                    {/* <div style={{height: '50vh', width: '100%'}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{'key': GOOGLE_MAP_API_KEY}}
                                    center={{'lat': resData.lat, 'lng': resData.log}}
                                    defaultZoom={11}
                                >
                                    <AnyReactComponent
                                        lat={resData.lat}
                                        lng={resData.log}
                                        text={resData?.title}
                                    />

                                </GoogleMapReact>
                            </div>*/}

                                    {/* {
                                    resData.venue === 'location' &&

                                    <Panel shaded className={'map_panel'}>


                                        <div style={{height: '50vh', width: '100%'}}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{
                                                    'key': GOOGLE_MAP_API_KEY, language: 'en'
                                                }}
                                                // onClick={(e) => console.log(e.lat)}
                                                // center={{'lat': resData.lat, 'lng': resData.log}}
                                                center={[resData?.lat, resData?.log]}
                                                defaultCenter={[resData?.lat, resData?.log]}
                                                // defaultZoom={11}
                                                zoom={14}
                                            >

                                                <AnyReactComponent
                                                    lat={resData?.lat}
                                                    lng={resData?.log}
                                                    text={resData?.title}
                                                />

                                            </GoogleMapReact>
                                        </div>
                                    </Panel>
                                } */}
                                    {/* </div> */}
                                </Panel>
                            </Col>
                        </Row>
                    </Grid>
                </Container>
            </Hidden>
            <Hidden only={["lg", "xl", "md"]}>
                <Container className="wrapper">
                    <Panel
                        bordered
                        style={{background: "#fff", border: "2px solid #ccc"}}
                    >
                        <div style={{display: "inline", width: 200}}>
                            <Grid fluid>
                                <Row className="show-grid">
                                    <Col xsHidden xs={12} md={6}>
                                        <img
                                            src={resData?.course_company[0]?.company.logo}
                                            style={{width: "180px", height: "180px"}}
                                        />
                                    </Col>
                                    <Col xs={12} md={16} style={{marginTop: "20px"}}>
                                        <h1 style={{color: "red", textAlign: "left"}}>
                                            {" "}
                                            {resData?.name}
                                        </h1>
                                        <h6>
                                            <b>Language: </b>
                                            {resData?.language}
                                        </h6>
                                        <h6>
                                            <b>Offered At: </b>
                                            {resData?.offeredAt}
                                        </h6>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    </Panel>
                    {/*<Divider/>*/}
                    <Panel>
                        <Grid fluid style={{marginLeft: 15, marginRight: 15}}>
                            <Row gutter={20}>
                                <Col
                                    lg={8}
                                    md={8}
                                    sm={24}
                                    xs={24}
                                    style={{backgroundColor: "white"}}
                                >
                                    <Panel
                                        shaded
                                        bordered
                                        bodyFill
                                        style={{textAlign: "justify", width: 340}}
                                    >
                                        <Panel>
                                            <h5
                                                style={{
                                                    textAlign: "left",
                                                    color: "red",
                                                    fontSize: "1.5rem",
                                                }}
                                            >
                                                DETAILS
                                            </h5>
                                            <Row>
                                                <Col xs={12}>
                                                    <p>
                                                        <b>Language: </b>
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <p>{resData?.language}</p>
                                                </Col>
                                                {/* <Col xs={12}>
                              <p>
                                <b>Offered At: </b>
                              </p>
                            </Col>
                            <Col xs={12}>
                              <p>{resData?.offeredAt}</p>
                            </Col> */}
                                                <Col xs={12}>
                                                    <p>
                                                        <b>Price: </b>
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <p>{resData?.price}</p>
                                                </Col>
                                                <Col xs={12}>
                                                    <p>
                                                        <b>Start Date: </b>
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <p>
                                                        {new Date(resData?.startDate).toLocaleDateString(
                                                            "en-us",
                                                            dateOptionsYYMMDD
                                                        )}
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <p>
                                                        <b>End Date: </b>
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <p>
                                                        {new Date(resData?.endDate).toLocaleDateString(
                                                            "en-us",
                                                            dateOptionsYYMMDD
                                                        )}
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <p>
                                                        <b>Duration: </b>
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <p>{resData?.duration} Min</p>
                                                </Col>
                                            </Row>
                                            <h5
                                                style={{
                                                    textAlign: "left",
                                                    color: "red",
                                                    fontSize: "1.5rem",
                                                }}
                                            >
                                                AVAILABILITY
                                            </h5>
                                            {resData?.availability.map((item, index) => {
                                                return (
                                                    <Row>
                                                        <Col xs={12}>
                                                            <p>
                                                                <b>{item.day}: </b>
                                                            </p>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <p>
                                                                {new Date(item.time).toLocaleDateString(
                                                                    "en-us",
                                                                    dateTimeNumericOptions
                                                                )}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </Panel>
                                    </Panel>
                                </Col>

                                <Col
                                    lg={16}
                                    md={16}
                                    sm={24}
                                    xs={24}
                                    style={{backgroundColor: "white", paddingBottom: "20px"}}
                                >
                                    <Panel shaded>
                                        <Carousel autoplay shape={"bar"}>
                                            {resData?.media?.images?.map((src, index) => (
                                                <img
                                                    key={index}
                                                    // src={`${src}?random=${index}`}
                                                    src={`${src}`}
                                                    alt="Company Picture"
                                                />
                                            ))}
                                        </Carousel>

                                        <h3 style={{textAlign: "left"}}>About</h3>
                                        <Panel style={{textAlign: "justify"}}>
                                            <ReadMore text={resData?.description} words={1200}/>
                                        </Panel>
                                        <div className="mb-5 ">
                                            <Carousel>
                                                {resData?.media?.videos.map((src, index) => (
                                                    <div key={index} className=" player-wrapper">
                                                        <ReactPlayer
                                                            className=" react-player"
                                                            width="100%"
                                                            // height='100%'
                                                            controls={true}
                                                            light
                                                            url={`${src}`}
                                                            // style={{position:'absolute',top:0,left:0}}
                                                        />
                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                    </Panel>
                                </Col>
                            </Row>
                            <Col
                                // lg={8}
                                // md={8}
                                // sm={24}
                                // xs={24}
                                style={{backgroundColor: "white", paddingBottom: "20px"}}
                            >
                                <Panel shaded>
                                    {resData?.course_company.map((d, i) => (
                                        <>
                                            <Panel
                                                bordered
                                                style={{background: "#fff", border: "1px solid #333"}}
                                            >
                                                <div style={{display: "inline"}}>
                                                    <Grid fluid>
                                                        {/* <Row >
                          <Col xsHidden xs={12} md={6}>
                            <img
                              src={d?.company?.logo}
                              style={{ width: "180px", height: "180px" }}
                            />
                          </Col>
                          <Col xs={12} md={16}>
                            <h1
                              style={{ color: "darkblue", textAlign: "left" }}
                            >
                              {" "}
                              {d?.company?.name}
                            </h1>
                            <h6>{d?.company?.foundYear}</h6>
                            <h6>
                              <ReadMore
                                text={d?.company?.description}
                                words={180}
                              />
                            </h6>
                          </Col>
                        </Row> */}
                                                        <h1
                                                            style={{color: "darkblue", textAlign: "left"}}
                                                        >
                                                            {" "}
                                                            {d?.company?.name}
                                                        </h1>
                                                        <h6>{d?.company?.foundYear}</h6>
                                                        <h6>
                                                            <ReadMore
                                                                text={d?.company?.description}
                                                                words={180}
                                                            />
                                                        </h6>
                                                    </Grid>
                                                </div>
                                            </Panel>
                                        </>
                                    ))}

                                    {/* <br />
              <CommunityRating />
              <br />
              <YourReviews />
              <br /> */}
                                    {/* <div className="panel-margin"> */}
                                    {/* Google Map Block*/}

                                    {/* <div style={{height: '50vh', width: '100%'}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{'key': GOOGLE_MAP_API_KEY}}
                                    center={{'lat': resData.lat, 'lng': resData.log}}
                                    defaultZoom={11}
                                >
                                    <AnyReactComponent
                                        lat={resData.lat}
                                        lng={resData.log}
                                        text={resData?.title}
                                    />

                                </GoogleMapReact>
                            </div>*/}

                                    {/* {
                                    resData.venue === 'location' &&

                                    <Panel shaded className={'map_panel'}>


                                        <div style={{height: '50vh', width: '100%'}}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{
                                                    'key': GOOGLE_MAP_API_KEY, language: 'en'
                                                }}
                                                // onClick={(e) => console.log(e.lat)}
                                                // center={{'lat': resData.lat, 'lng': resData.log}}
                                                center={[resData?.lat, resData?.log]}
                                                defaultCenter={[resData?.lat, resData?.log]}
                                                // defaultZoom={11}
                                                zoom={14}
                                            >

                                                <AnyReactComponent
                                                    lat={resData?.lat}
                                                    lng={resData?.log}
                                                    text={resData?.title}
                                                />

                                            </GoogleMapReact>
                                        </div>
                                    </Panel>
                                } */}
                                    {/* </div> */}
                                </Panel>
                            </Col>
                        </Grid>
                        <br/>
                        <CommunityRating id={resData?.cou_id} type={'COURSE'}/>
                        <br/>
                        <YourReviews id={resData?.cou_id} type={'COURSE'}/>
                        <br/>
                    </Panel>
                </Container>
            </Hidden>
        </>
    );
}

CourseDetailView.auth = true;
