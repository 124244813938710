import React from 'react';
import {Button, Col, Divider, Footer, Grid, IconButton, Row} from 'rsuite'
import "../styles/footer.css"
import {GrFacebook, GrInstagram, GrTwitter, GrYoutube} from "react-icons/gr";
import {Link} from "react-router-dom";

function CustomFooter(props) {
    return (

        <Footer className='footer'>
            <div className='wrapper' style={{padding: '15px', backgroundColor:"#0C3144"
            }}>
                <Grid fluid>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <p style={{fontSize: '1.5rem'}}>EduTrust</p>
                            <p>Your #1 Education Guide</p>
                            <div style={{paddingTop: '15px'}}>

                                <IconButton target={'_blank'} href={'#'}
                                            icon={<GrFacebook size={25}/>} appearance="subtle" circle/>

                                <IconButton target={'_blank'} href={'#'}
                                            icon={<GrYoutube size={25}/>} appearance="subtle" circle/>
                                <IconButton target={'_blank'} href={'#'}
                                            icon={<GrTwitter size={25}/>} appearance="subtle" circle/>
                                <IconButton target={'_blank'} href={'#'}
                                            icon={<GrInstagram size={25}/>} appearance="subtle" circle/>
                            </div>


                        </Col>

                        <Col xs={24} sm={24} md={16} lg={16}>
                            <ul className='footer-list'>
                                <li>

                                    <Button
                                        as={Link}
                                        to={'/'}
                                        className='d-w-r-btn'
                                    >
                                        Home
                                    </Button>
                                </li>
                                <li>

                                    <Button
                                        as={Link}
                                        to={'/university'}
                                        className='d-w-r-btn'
                                    >
                                        Universities
                                    </Button>
                                </li>
                                <li>

                                    <Button
                                        as={Link}
                                        to={'/schools'}
                                        className='d-w-r-btn'
                                    >
                                        Schools
                                    </Button>
                                </li>
                                <li>

                                    <Button
                                        as={Link}
                                        to={'/jobs'}
                                        className='d-w-r-btn'
                                    >
                                        Jobs
                                    </Button>
                                </li>
                                <li>

                                    <Button
                                        as={Link}
                                        to={'/events'}
                                        className='d-w-r-btn'
                                    >
                                        Events
                                    </Button>
                                </li>
                                <li>

                                    <Button
                                        as={Link}
                                        to={'/aboutus'}
                                        className='d-w-r-btn'
                                    >
                                        About Us
                                    </Button>
                                </li>
                                <li>

                                    <Button
                                        as={Link}
                                        to={'/contactus'}
                                        className='d-w-r-btn'
                                    >
                                        Contact Us
                                    </Button>
                                </li>
                                <li>

                                    <Button
                                        as={Link}
                                        to={'/news'}
                                        className='d-w-r-btn'
                                    >
                                        News
                                    </Button>
                                </li><li>

                                    <Button
                                        as={Link}
                                        to={'/blogs'}
                                        className='d-w-r-btn'
                                    >
                                        Blogs
                                    </Button>
                                </li>
                            </ul>
                        </Col>


                    </Row>
                </Grid>
                <Divider style={{backgroundColor: '#f5f5f5', margin: '15px 0'}}/>
                <div className="copyright">
                    <p style={{textAlign: 'center'}}>
                        © Edutrust.info - All Rights Reserved 2022 &nbsp;&nbsp;
                        {/*| <a style={{color: 'white'}}  title="Privacy Policy" rel="nofollow">Privacy
                            Policy</a> - <a style={{color: 'white'}} title="Terms &amp; Conditions"
                           rel="nofollow">Terms &amp; Conditions</a>*/}


                    </p>
                </div>
            </div>
        </Footer>
    );
}

export default CustomFooter;