import {Badge} from "rsuite";
import React from "react";
import EditIcon from "@rsuite/icons/Edit";

export const Column = (handleEdit,setValueNav) => [

    {
        name: 'Title',
        selector: row => row.title,
        sortable: true,
        sortField: 'title',
        // minWidth: "150px",
    },
    {
        name: 'Employment Type',
        selector: row => row.employmentType,
        sortable: true,
        sortField: 'employmentType',
        // grow: 2,
        // maxWidth: "300px",
        // minWidth: "100px",
        // cell: (row) => <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
        //                     data-bs-toggle="tooltip" title={row.PurchaseItemName}>{row.PurchaseItemName}</div>

    },
    {
        name: 'Verified',
        selector: row => row.isActive,
        sortable: true,
        sortField: 'isActive',
        // Width: "20px",
        // center: true,
        cell: row => row.isActive ? <Badge color="green" /> : <Badge color="red" />
    },
    {
        name: 'Minimum Experience',
        selector: row => row?.minExperience,
        sortable: true,
        sortField: 'minExperience',
        minWidth: "80px",
        // cell: row => <div >{row.companytype.name}</div>
    },
    {
        name: 'Company',
        selector: row => row?.company?.name,
        // sortable: true,
        // sortField: 'QtyInHand',
        // minWidth: "150px",
        // maxWidth: '300px',
        compact:true,
        wrap:true
        // cell: row => <div >{row.companytype.name}</div>
    },
    {
        name: 'Edit',
        cell: (row)=> <a onClick={() => {
            handleEdit(row.job_id)
        }
        } style={{fontSize: "2em",color:'#0C3144'}}><EditIcon  /> </a>

    }
]