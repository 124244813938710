import {Container, Divider, Panel, Row} from "rsuite";
import '../styles/news.css';
import LatestNews from "../components/news/latestNews";
import RecentNews from "../components/news/recentNews";

const News = () => {


    return (
        <Container className={'wrapper'}>
            <Divider style={{background: 'transparent', margin: '10px 0'}}/>

            <div className={"border-default"} style={{background: 'white', textAlign: 'center', alignContent: 'center'}}>
                <h1 className={'default-color'} style={{margin: '20px 0'}}>NEWS</h1>
            </div>

            <Panel bodyFill header={<h6 className={'border-bottom'}>Latest News</h6>}>
                <Row>
                    <LatestNews/>
                </Row>
            </Panel>

            <Panel header={<h6 className={'border-bottom'}>Recent News</h6>}>
                <RecentNews/>

            </Panel>
        </Container>
    )
}

export default News;