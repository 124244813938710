import React, {useContext, useState} from "react";
import {Dropdown, Nav, Navbar} from 'rsuite';
import NoticeIcon from '@rsuite/icons/Notice';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import ExitIcon from '@rsuite/icons/Exit';
import {AppContext} from "lib/contextLib";
import {Link, useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom"
import {useStateWithDeps} from "use-state-with-deps";

const NavbarComponent = () => {
    const sampleLocation = useLocation();
    const [activeKey, setActiveKey] = useStateWithDeps(sampleLocation?.pathname, [sampleLocation?.pathname]);
    console.log(activeKey);
    console.log(sampleLocation.pathname);

    let navigate = useNavigate();

    function changeLocation(placeToGo){
        navigate(placeToGo, { replace: true });
        window.location.reload();
    }

    const {user, setUser} = useContext(AppContext);

    return (
        <div className="navbar-wrapper to-uppercase">
            <Navbar appearance="subtle">

                <Navbar.Brand style={{padding: 0}} as={Link} to={'/'}>
                    <img

                        alt={'logo'}
                        src={require('../../../images/logo.png')}
                        height='100%'
                    />

                </Navbar.Brand>
                <Nav activeKey={activeKey} pullRight className={"nav-header"}>
                    <Nav.Item active={activeKey == '/university'} as={Link} to="/university">UNIVERSITIES</Nav.Item>
                    <Nav.Item active={activeKey == '/schools'} as={Link} to="/schools">SCHOOLS</Nav.Item>
                    <Nav.Item active={activeKey == '/jobs'} as={Link} to='/jobs'>JOBS</Nav.Item>
                    <Nav.Item active={activeKey == '/events'} as={Link} to='/events'>EVENTS</Nav.Item>
                    <Nav.Item active={activeKey == '/courses'} as={Link} to='/courses'>COURSES</Nav.Item>
                    {
                        !!user &&

                    <Nav.Item active={activeKey == '/notification'} as={Link} to='/notification'><NoticeIcon/></Nav.Item>
                    }
                    {
                        !!user ?
                            <Dropdown active={activeKey == '/profile'} title="ACCOUNTS">
                                <Nav.Item icon={<UserInfoIcon/>} as={Link} to='/profile'>Profile</Nav.Item>
                                <Nav.Item as={Link} to='/logout' icon={<ExitIcon/>}>Log Out</Nav.Item>
                            </Dropdown>
                            :
                            <Nav.Item active={activeKey == '/login'} as={Link} to='/login'>Login</Nav.Item>
                    }


                </Nav>

            </Navbar>
        </div>

    );

};

export default NavbarComponent;
