import React from "react";
import {ButtonGroup, DatePicker, IconButton} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";

export const CourseAvailability = ({value = [], onChange, fieldError,isSubmitted=false}) => {
    const errors = fieldError ? fieldError.array : [];
    const [course, setCourse] = React.useState(value.length === 0 ? [new Date()] : value);
    const handleChangeCourse = nextCourse => {
        setCourse(nextCourse);
        onChange(nextCourse);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextCourse = [...course];
        nextCourse[rowIndex] = value;
        handleChangeCourse(nextCourse);
    };

    const handleMinus = () => {
        handleChangeCourse(course.slice(0, -1));
    };
    const handleAdd = () => {
        handleChangeCourse(course.concat([new Date()]));
    };
    return (
        <table style={{width: 'inherit'}}>
            <thead>
            <tr>
                <Cell>Available At:</Cell>
            </tr>
            </thead>
            <tbody>
            {course.map((rowValue, index) => (
                <CourseDetail
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index] : null}
                    onChange={handleInputChange}
                />
            ))}
            </tbody>
            <tfoot>
            <tr>
                <Cell colSpan={2} style={{paddingTop: 10}}>
                    <ButtonGroup size="xs">
                        <IconButton onClick={handleAdd} icon={<PlusIcon/>}/>
                        <IconButton disabled={value.length === 1} onClick={handleMinus} icon={<MinusIcon/>}/>
                    </ButtonGroup>
                </Cell>
            </tr>
            </tfoot>
        </table>
    );
};

const CourseDetail = ({rowValue = {}, onChange, rowIndex, rowError}) => {
    const handleChangeDate = value => {
        onChange(rowIndex, value);
    };
    return (
        <tr>
            <Cell>
                {/*<Input value={rowValue} onChange={handleChangeDate} accepter={DatePicker}/>*/}
                <DatePicker oneTap value={new Date(rowValue)} onSelect={handleChangeDate} format="yyyy-MM-dd  HH:mm:ss"/>
                {rowError ? <ErrorMessage>{rowError.errorMessage}</ErrorMessage> : null}
            </Cell>
        </tr>
    );
};
const ErrorMessage = ({children}) => <span style={{color: 'red'}}>{children}</span>;
const Cell = ({children, style, ...rest}) => (
    <td style={{padding: '2px 4px 2px 0', verticalAlign: 'top', ...style}} {...rest}>
        {children}
    </td>
);
