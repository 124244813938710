import {Badge} from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import React from "react";

export const CompanyColumn = (handleEdit, setValueNav) =>
    [

        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
            minWidth: "150px",
            wrap: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            sortField: 'email',
            // grow: 2,
            // maxWidth: "300px",
            minWidth: "200px",

            // cell: (row) => <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
            //                     data-bs-toggle="tooltip" title={row.PurchaseItemName}>{row.PurchaseItemName}</div>

        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true,
            sortField: 'phone',
            minWidth: "120px",
            // right: true

        },

        {
            name: 'Verified',
            selector: row => row.isActive,
            sortable: true,
            sortField: 'isActive',
            maxWidth: "20px",
            center: true,
            cell: row => row.isActive ? <Badge color="green" /> : <Badge color="red" />
        },
        {
            name: 'Company Type',
            selector: row => row.companytype.name,
            // sortable: true,
            // sortField: 'QtyInHand',
            // maxWidth: "80px",
            // cell: row => <div >{row.companytype.name}</div>
        },
        // {
        //     name: 'City',
        //     selector: row => row.city.name,
        //     // sortField: 'CustomerName',
        // },
        {
            name: 'Country',
            selector: row => row.city.country.name,
            sortField: 'PurchasingCategoryName',
            // cell: (row) => <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
            //                     title={row.PurchasingCategory.PurchasingCategoryName}>{row.PurchasingCategory.PurchasingCategoryName}</div>

        },
        {
            name: 'Edit',
            cell: (row)=> <a onClick={() => {
                handleEdit(row.com_id)
                setValueNav('editCompany')
            }
            } style={{fontSize: "2em",color:'#0C3144'}}><EditIcon  /> </a>,
maxWidth: "20px",
        }
    ]

