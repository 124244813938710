import React, {useEffect, useState} from 'react';
import {Button, ButtonToolbar, Col, Content, Form, Grid, Row, toaster} from "rsuite";
import {TextField} from "components/textField";
import {ChangePasswordFormModel} from "lib/formModels";
import {changePassword, getCurrentUser} from "service/auth.service";
import {useNavigate} from "react-router-dom";
import {NotificationCustom} from "../common/notification";

function ChangePassword(props) {
    const formRef = React.useRef();
    const [user, setUser] = useState(getCurrentUser());
    const [formError, setFormError] = React.useState({});
    const [formValue, setFormValue] = React.useState({
        password: '',
        newPassword: '',
        verifyNewPassword: '',
        use_id: user.use_id
    });
    let navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(async () => {
        if (!localStorage.getItem('user')) {
            navigate('/');
        }
    }, []);

    const handleSubmit = () => {
        setIsSubmitted(true);
        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }

        changePassword(formValue, user.access_token).then(res => {
            setMessage(res.message);
            if (res.status) {
                toaster.push(<NotificationCustom type="success" header='success'> {res.message}</NotificationCustom>);
            } else {
                toaster.push(<NotificationCustom type="error" header='Error'> {res.message}</NotificationCustom>);
                // toaster.push(notification('error', '', res.message));
            }

        }).catch(err => {
            // setMessage(res.message);
            setFormError(err.response.data.errors);
        });

        setIsSubmitted(false)

        // console.log(formValue, 'Form Value');
    };

    //foo@domain.com
    //try to enter this email in the form. it will asynchronously check email


    return (
        <Content>
            <Grid fluid style={{marginLeft: 15}}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={ChangePasswordFormModel}
                    disabled={isSubmitted}
                    fluid
                    className='Change-password-form'
                >

                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <TextField name="password" label="Old Password" helpText='Type Old Password'
                                               type="password"
                                               autoComplete="off"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>

                                    <TextField name="newPassword" label="New Password"
                                               helpText='Password Should be greater than 6 characters' type="password"
                                               autoComplete="off"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <TextField name="verifyNewPassword" label="Verify New Password"
                                               helpText='These Fields Should Match ' type="password"
                                               autoComplete="off"/>

                                </Col>
                            </Row>
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary"
                                            className='d-w-r-btn'
                                            style={{width: 'auto'}}
                                        // type="submit"
                                            onClick={handleSubmit}
                                            disabled={Object.keys(formError).length !== 0 || isSubmitted}
                                            loading={isSubmitted}
                                    >Submit</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            {/*<div className='default-bgcolor' style={{padding:'20px'}}>
                                <Placeholder.Graph/>
                            </div>*/}
                        </Col>
                    </Row>

                </Form>
            </Grid>
        </Content>

    );
}

export default ChangePassword;