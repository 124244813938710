import {Badge} from "rsuite";
import React from "react";
import EditIcon from "@rsuite/icons/Edit";

export const Column = (handleEdit,setValueNav) => [
    {
        name: 'Sr. No.',
        cell: (row, index) => index + 1,
        // render:(text,record,index)=>`${index+1}`,
        // selector: row => row.cou_id,
        // sortable: true,
        // sortField: 'cou_id',
        maxWidth: "50px",
        // compact: true,
        // wrap: true
    },

    {
        name: 'Course Name',
        selector: row => row.name,
        sortable: true,
        sortField: 'name',
        // minWidth: "150px",
        compact: true,
        grow: 3,
        wrap: true
    },
    {
        name: 'Price ($)',
        selector: row => row.price,
        sortable: true,
        sortField: 'price',
        // grow: 2,
        // maxWidth: "100px",
        // minWidth: "100px",
        // cell: (row) => <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
        //                     data-bs-toggle="tooltip" title={row.PurchaseItemName}>{row.PurchaseItemName}</div>

    },

    // {
    //     name: 'Language',
    //     selector: row => row?.language,
    //     sortable: true,
    //     sortField: 'language',
    //     minWidth: "80px",
    //     // cell: row => <div >{row.companytype.name}</div>
    // },
    {
        name: 'Duration(Hr)',
        selector: row => row?.duration,
        sortable: true,
        sortField: 'duration',
        // minWidth: "150px",
        // maxWidth: '100px',
        // compact:true,
        // wrap:true
        // cell: row => <div >{row.companytype.name}</div>
    },
    {
        name: 'Offered At',
        selector: row => row?.offeredAt,
        sortable: true,
        sortField: 'offeredAt',
        // maxWidth: "180px",
        // cell: row => <div >{row.companytype.name}</div>
    },
    // {
    //     name: 'Start Date',
    //     selector: row => new Date(row.startDate).toLocaleDateString('en-US',{year:'numeric',month:'short',day:'numeric',hour:'numeric',minute:'numeric'}),
    //     sortable: true,
    //     sortField: 'startDate',
    //     // Width: 160,
    //     // compact: true,
    //     wrap: true
    // },
    // {
    //     name: 'End Date',
    //     selector: row => new Date(row.endDate).toLocaleDateString('en-US',{year:'numeric',month:'short',day:'numeric',hour:'numeric',minute:'numeric'}),
    //     sortable: true,
    //     sortField: 'endDate',
    //     // minWidth: 160
    //     // compact: true,
    //     wrap: true
    // },
    {
        name: 'Status',
        selector: row => row.isActive,
        sortable: true,
        sortField: 'isActive',
        // maxWidth: "50px",
        // center: true,
        cell: row => row.isActive ? <Badge color="green" /> : <Badge color="red" />
    },
    {
        name: 'Edit',
        cell: (row)=> <a onClick={() => {
            handleEdit(row.cou_id)
        }
        } style={{fontSize: "2em",color:'#0C3144'}}><EditIcon  /> </a>

    }
]