import {Schema} from "rsuite";

const {StringType, NumberType, ArrayType, ObjectType, DateType} = Schema.Types;

function asyncCheckEmail(email) {
    return new Promise(resolve => {
        setTimeout(() => {
            // checkEmail(email).then(res => {resolve(res.data);});

            if (email === 'foo@domain.com') {
                resolve(false);
            } else {
                resolve(true);
            }
        }, 100);
    });
}

export const RegisterFormModel = Schema.Model({
    firstName: StringType()
        .containsLetterOnly('Only accept letters')
        .maxLength(15,'First name is too long')
        .isRequired('This field is required.'),
    lastName: StringType()
        .containsLetterOnly('Only accept letters')
        .maxLength(15,'First name is too long')
        .isRequired('This field is required.'),
    email: StringType()
        .isEmail('Please enter a valid email address.')
        .addRule((value, data) => {
            return asyncCheckEmail(value);
        }, 'Email address already exists')
        .isRequired('This field is required.'),
    password: StringType()
        .minLength(6, 'password should be more then 6 characters')
        .maxLength(32, 'password should be less then 32 characters')

        .isRequired('This field is required.'),
    verifyPassword: StringType()
        .addRule((value, data) => {

            return value === data.password;

        }, 'The two passwords do not match')
        .isRequired('This field is required.'),
});

export const LoginFormModel = Schema.Model({
    email: StringType()
        .isEmail('Please enter a valid email address.')
        .isRequired('This field is required.'),
});
export const contactUsFormModel = Schema.Model({
    email: StringType()
        .isEmail('Please enter a valid email address.')
        .isRequired('This field is required.'),
    phone: NumberType()
        .isRequired('This field is required.'),
    subject: StringType()
        .maxLength(100, 'subject should be less then 100 characters')
        .isRequired('This field is required.'),
    fullName: StringType()
        .maxLength(30, 'fullName should be less then 30 characters')
        .isRequired('This field is required.'),

});
export const ForgotPasswordFormModel = Schema.Model({
    email: StringType()
        .isEmail('Please enter a valid email address.')
        .isRequired('This field is required.'),
});

export const CreateCompanyFormModel = Schema.Model({
    logo: StringType()
        .isRequired('This field is required.'),
    instituteName: StringType()
        .isRequired('This field is required.'),
    category: NumberType()
        .isRequired('This field is required.'),
    city: NumberType()
        .isRequired('This field is required.'),
    country: NumberType()
        .isRequired('This field is required.'),
    address: StringType()
        .isRequired('This field is required.'),
    email: StringType()
        .isEmail('Please enter a valid email address.')
        .isRequired('This field is required.'),
    website: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    phone: NumberType()
        .isRequired('This field is required.'),
    lat: NumberType()
        .min(0, 'Minimum 0')
        .isRequired('This field is required.'),
    log: NumberType()
        .min(0, 'Minimum 0')
        .isRequired('This field is required.'),
    fbURL: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    instaURL: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    youtubeURL: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    twitterURL: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    mediaUrl: ArrayType().of(
        ObjectType().shape({
            path: StringType()
                .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
            // .isRequired('Required.'),
            mediaType: StringType()
                .isOneOf(['Image', 'Video'], 'Can only type `Javascript` and `CSS`')
                .isRequired('Required.')
        })
    ),
    companyContacts: ArrayType().of(
        ObjectType().shape({
            personName: StringType()
                .isRequired('Required.'),
            email: StringType()
                .isEmail('Please enter a valid email address.')
                .isRequired('Required.'),
            designation: StringType()
                .isRequired('Required'),
            phone: NumberType()
                .isRequired('Required')
        })
    )


});

export const CompanyJobFormModel = Schema.Model({
    title: StringType()
        .isRequired('This field is required.'),
    employmentType: StringType()
        .isRequired('This field is required.'),
    minExperience: NumberType()
        .min(0, 'Minimum 0')
        .isRequired('This field is required.'),
    mediaUrl: ArrayType().of(
        ObjectType().shape({
            path: StringType()
                .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
                // .isRequired('Required.'),
            mediaType: StringType()
                .isOneOf(['Image', 'Video'], 'Can only Select Video')
                .isRequired('Required.')
        })
    ),



});

export const ChangePasswordFormModel = Schema.Model({
    newPassword: StringType()
        .minLength(6, 'password should be more then 6 characters')
        .isRequired('This field is required.'),

    verifyNewPassword: StringType()
        .addRule((value, data) => {
            return value === data.newPassword;
        }, 'The two passwords do not match')
        .isRequired('This field is required.'),
});

export const CreateCompanyEventFormModel =(formValue,editOrNew) =>  Schema.Model({
    title: StringType()
        .isRequired('This field is required.'),
    evc_id: NumberType()
        .isRequired('This field is required.'),
    evs_id: NumberType()
        .isRequired('This field is required.'),
    evt_id: NumberType()
        .isRequired('This field is required.'),
    website: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    lat: NumberType()
        .min(0, 'Minimum 0'),
        // .isRequired('This field is required.'),
    log: NumberType()
        .min(0, 'Minimum 0'),
        // .isRequired('This field is required.'),
    address: StringType(),
    // .isRequired('This field is required.'),
    city: NumberType(),
    // .isRequired('This field is required.'),
    country: NumberType(),
    // .isRequired('This field is required.'),
    startDate: DateType()
        // .min(editOrNew ? formValue.startDate :  new Date(Date.now() - (3600 * 1000 * 24)), 'Cannot select a date in the past.')
        .isRequired('This field is required.'),
    endDate: DateType()
        .isRequired('This field is required.'),
    mediaUrl: ArrayType().of(
        ObjectType().shape({
            path: StringType()
                .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
            // .isRequired('Required.'),
            mediaType: StringType()
                .isOneOf(['Image', 'Video'], 'Can only select Videos')
                .isRequired('Required.')
        })
    ),


    speakers: ArrayType().of(
        ObjectType().shape({
            name: StringType()
                .isRequired('This field is required.'),
            designation: StringType()
                .isRequired('This field is required.'),
            email: StringType().isEmail('Please enter a valid email address.')
                .isRequired('This field is required.'),
            // phone: NumberType()
            //     .isRequired('This field is required.'),
            fbURL: StringType()
                .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
            // .isRequired('This field is required.'),
            instaURL: StringType()
                .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
            // .isRequired('This field is required.'),
            youtubeURL: StringType()
                .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
            // .isRequired('This field is required.'),
            twitterURL: StringType()
                .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
            // .isRequired('This field is required.'),

        })
    ),

    ticketPrice: ArrayType().of(
        ObjectType().shape({
            name: StringType()
                .isRequired('This field is required.'),
            price: NumberType()
                .isRequired('This field is required.'),

        })
    ),


});

export const JobSeekerFormModel = Schema.Model({
    firstName: StringType()
        .isRequired('This field is required.'),
    lastName: StringType()
        .isRequired('This field is required.'),
    email: StringType()
        .isEmail('Please enter a valid email address.')
        .isRequired('This field is required.'),
    website: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    phone: NumberType()
        .isRequired('This field is required.'),
    currentPosition: StringType()
        .isRequired('This field is required.'),
    currentSalary: NumberType()
        .isRequired('This field is required.'),
    expectedSalary: NumberType()
        .isRequired('This field is required.'),
    lat: NumberType()
        .min(0, 'Minimum 0')
        .isRequired('This field is required.'),
    log: NumberType()
        .min(0, 'Minimum 0')
        .isRequired('This field is required.'),
    postalCode: NumberType()
        .isRequired('This field is required.'),
    address: StringType()
        .isRequired('This field is required.'),
    city: NumberType()
        .isRequired('This field is required.'),
    country: NumberType()
        .isRequired('This field is required.'),


    skill: ArrayType()
        .of(
            StringType('The tag should be a string')
                .isRequired()
        ),

    education: ArrayType().of(
        ObjectType().shape({
            instituteName: StringType()
                .isRequired('This field is required.'),
            fieldOfStudy: StringType()
                .isRequired('This field is required.'),
            grade: StringType()
                .isRequired('This field is required.'),
            degree: StringType()
                .isRequired('This field is required.'),
            description: StringType()
                .isRequired('This field is required.'),
        })
    ),

    experience: ArrayType().of(
        ObjectType().shape({
            title: StringType()
                .isRequired('This field is required.'),
            companyName: StringType()
                .isRequired('This field is required.'),
            description: StringType()
                .isRequired('This field is required.'),
        })
    ),
    project: ArrayType().of(
        ObjectType().shape({
            projectName: StringType()
                .isRequired('This field is required.'),
            projectUrl: StringType()
                .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
                // .isRequired('This field is required.'),
            description: StringType()
                .isRequired('This field is required.'),
        })
    ),

    fbURL: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    // .isRequired('This field is required.'),
    instaURL: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    // .isRequired('This field is required.'),
    youtubeURL: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    // .isRequired('This field is required.'),
    twitterURL: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    // .isRequired('This field is required.'),

});

export const CompanyCourseFormModel = (formValue,editOrNew) =>  Schema.Model({
    name: StringType()
        .isRequired('This field is required.'),
    price: NumberType()
        .min(0, 'Minimum 0')
        .isRequired('This field is required.'),
    language: StringType()
        .isRequired('This field is required.'),
    website: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    duration: NumberType()
        .min(1, 'Minimum 1')
        .isRequired('This field is required.'),
    offeredAt: NumberType()
        .isRequired('This field is required.'),
    startDate: DateType()
        // .min(editOrNew ? formValue.startDate :  new Date(Date.now() - (3600 * 1000 * 24)), 'Cannot select a date in the past.')
        .max(formValue.endDate , 'Cannot select a date After End Date.')
        // .min(formValue.endDate, 'Start date cannot be after end date.')
        .isRequired('This field is required.'),
    endDate: DateType()
        .isRequired('This field is required.'),
    mediaUrl: ArrayType().of(
        ObjectType().shape({
            path: StringType()
                .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
            // .isRequired('Required.'),
            mediaType: StringType()
                .isOneOf(['Image', 'Video'], 'Can only select Videos')
                .isRequired('Required.')
        })
    ),

    courseAvailability: ArrayType().of(
       DateType()
           .isRequired('Required.')

    ),
    //
    // courseAvailability: ArrayType().of(
    //    DateType()
    //        .min(formValue.startDate, `Date Should be in between ${new Date( formValue.startDate).toLocaleDateString()} and ${new Date( formValue.endDate).toLocaleDateString()} .`)
    //        .max(formValue.endDate, `Date Should be in between ${new Date( formValue.startDate).toLocaleDateString()} and ${new Date( formValue.endDate).toLocaleDateString()} .`)
    //        .isRequired('Required.')
    //
    // ),


});

export const TutorProfileFormModel = Schema.Model({
    firstName: StringType()
        .containsLetterOnly('Only accept letters')
        .maxLength(15,'First name is too long')
        .isRequired('This field is required.'),
    lastName: StringType()
        .containsLetterOnly('Only accept letters')
        .maxLength(15,'First name is too long')
        .isRequired('This field is required.'),
    email: StringType()
        .isEmail('Please enter a valid email address.')
        .isRequired('This field is required.'),
    website: StringType()
        .isURL('Please enter the correct URL address along with HTTP:// or HTTPS://'),
    phone: NumberType()
        .isRequired('Only accept numbers'),
    experience: NumberType()
        .min(0, 'Minimum 0')
        .isRequired('This field is required.'),
    qualification: StringType()
        .isRequired('This field is required.'),
    lat: NumberType()
        .min(0, 'Minimum 0')
        .isRequired('This field is required.'),
    log: NumberType()
        .min(0, 'Minimum 0')
        .isRequired('This field is required.'),
    postalCode: NumberType()
        .isRequired('This field is required.'),
    address: StringType()
        .isRequired('This field is required.'),
    city: NumberType()
        .isRequired('This field is required.'),
    country: NumberType()
        .isRequired('This field is required.'),

});


//
// export const RegisterFormModel = Schema.Model({
//     name: StringType()
//         .isRequired('This field is required.'),
//     email: StringType()
//         .isEmail('Please enter a valid email address.')
//         .isRequired('This field is required.'),
//     password: StringType()
//         .minLength(6, 'less then 6 characters')
//         .isRequired('This field is required.'),
//     verifyPassword: StringType()
//         .addRule((value, data) => {
//
//             return value === data.password;
//
//         }, 'The two passwords do not match')
//         .isRequired('This field is required.'),
//     age: NumberType().isRequired("Required Integer")
// });
