import {Button, Divider, Panel} from "rsuite";
import {useEffect, useState} from "react";
import {getAllAnnCat, getAllAnnouncements} from "../../service/announcement.service";
import {CustomSpinner} from "../common/spinner";
import {Link} from "react-router-dom";
import Carousel from "react-multi-carousel";
import React from "react";
import {timeSince} from "../../lib/utility";

const LatestNews = ({homeScreen = false, selectedCategory, setSelectedCategory}) => {


    const [resData, setResData] = useState([]);
    // const [count, setCount] = useState(0);
    // const [limit, setLimit] = React.useState(10);
    // const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false);

    const news_params = `type=NEWS&skip=0&take=5&sortField=ann_id&sortDirection=desc`;
    const blogs_params = `type=BLOG&skip=0&take=5&sortField=ann_id&sortDirection=desc`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetchData()
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const response = await getAllAnnouncements(homeScreen ? blogs_params : news_params);
        setResData(response.data);
        // setCount(response.count);
        setLoading(false);
    }


    if (loading) return <CustomSpinner/>;
    return (
        <Carousel
            additionalTransfrom={0}

            autoPlay={true}
            autoPlaySpeed={30000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={homeScreen}
            arrows={true}
            showDots={false}
            renderDotsOutside={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1080
                    },
                    items: 3,
                    partialVisibilityGutter: 30
                },
                mobile: {
                    breakpoint: {
                        max: 725,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 40
                },
                tablet: {
                    breakpoint: {
                        max: 1080,
                        min: 725
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                }
            }}

        >
            {
                resData.map((item, index) => (
                        <Panel  as={Link} to={ homeScreen ? `/blogs/${item.ann_id}` :  `/news/${item.ann_id}`} key={index} style={{display: 'block', maxWidth: "100%", margin: `0 ${homeScreen? '0': '30px'}`}}>
                            <div className={"news-img-cover"}>
                                <img src={item.img} alt={item.title} height={homeScreen ? 150 : 240}/>
                            </div>
                            <Divider style={{background: 'transparent', margin: '10px 0'}}/>
                            <p className={homeScreen? '': 'default-color'}>
                                {item.title}
                            </p>
                            {
                                !homeScreen &&
                                <>

                                    <Divider style={{background: '#0C3144', margin: '10px 0'}}/>
                                    <p style={{margin: 0, fontSize: '.75rem'}}>
                                        Posted On: {' '}
                                        {
                                            !timeSince(new Date(item.posted_on)) ?

                                                new Date(item.posted_on).toLocaleDateString('en-us', {weekday: 'short', day: '2-digit', month: 'short', year: 'numeric'})
                                                :
                                                timeSince(new Date(item.posted_on))
                                        }
                                    </p>
                                </>
                            }
                        </Panel>
                    )
                )
            }
        </Carousel>
    )

}

export default LatestNews;