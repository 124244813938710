import React, {useRef, useState} from 'react';
import {Button, ButtonToolbar, Col, Content, Form, Grid, Modal, Row, toaster} from "rsuite";
import {RegisterFormModel} from "lib/formModels";
import {TextField} from "components/textField";
import {register} from "service/auth.service";
import {NotificationCustom} from "../common/notification";
import {Welcome} from "../common/Welcome";
import {SocialLogin} from "./socialLogin";
import {CustomSpinner} from "../common/spinner";

const CompanyRegister = ({type, formValue, setFormValue}) => {
    const formRef = useRef();
    const defaultFormValue = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        verifyPassword: '',
    }
    const [formError, setFormError] = useState(defaultFormValue);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSocial, setIsSocial] = useState(false)
    const [isLoading, setIsLoading] = useState(false)



    const handleSubmit = async () => {
        toaster.clear()
        setIsSubmitted(true)
        if (!formRef.current.check()) {
            console.error('Form Error');
            setIsSubmitted(false)
            return;
        }

        const res = await register(formValue)
        if (res.status) {
            toaster.push(<NotificationCustom type="success" placement={'topEnd'}  header='Success'> A link sent to you on the mail you provided. Kindly activate your account using that link.</NotificationCustom>);
            // handleOpen()
            setFormValue(defaultFormValue)
        } else {
            toaster.push(<NotificationCustom type="error" placement={'topEnd'} header='Error'> {res.message}</NotificationCustom>);
        }
    };

    if (type==='')
      return  <Welcome/>

    if (isLoading) return <CustomSpinner/>

    return (
        <Content>
            <Grid fluid>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    disabled={isSubmitted}
                    model={RegisterFormModel}
                    fluid
                >

                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="firstName" label="First Name" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="lastName" label="Last Name" helpText='Required' type="email"/>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <TextField name="email" checkAsync={true} label="Email" helpText='Required' type="email"/>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>

                            <TextField name="password" label="Password" helpText='Required' type="password"
                                       autoComplete="off"/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="verifyPassword" label="Verify password" helpText='Required' type="password"
                                       autoComplete="off"/>
                        </Col>
                    </Row>

                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary"
                                // type="submit"
                                    className="d-w-r-btn"
                                    style={{width:'auto'}}
                                    onClick={handleSubmit}
                                    disabled={Object.keys(formError).length !== 0 || isSubmitted}
                                    loading={isSubmitted}
                            >Submit</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>

                <SocialLogin setSocialFormValue={setFormValue} type={type} setIsLoading={setIsLoading}/>
            </Grid>
        </Content>

    );
};

export default CompanyRegister;
