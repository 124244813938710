import React, { useEffect, useState } from "react";
import {
  Button,
  //   Col,
  Container,
  Content,
  Divider,
  //   Grid,
  Header,
  Input,
  List,
  Panel,
  //   Row,
  SelectPicker,
  Stack,
} from "rsuite";
import { Link } from "react-router-dom";
import { getAllCountries, getCountryCities } from "service/setting.service";
import InfiniteScroll from "react-infinite-scroll-component";
import { CustomSmallSpinner, CustomSpinner } from "components/common/spinner";
import { EndingNote } from "components/common/listDataEnd";
import Config from "config.json";
import { getAllCompanies } from "service/company.service";
import { getCourses } from "service/course.service";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import { SearchCompanyComponent } from "components/searchCompany";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const CourseListing = () => {
  const [searchData, setSearchData] = useState({
    text: "",
    country: 0,
    city: 0,
  });
  const [key, setKey] = useState("list");
  const [country, setCountry] = React.useState([]);
  const [city, setCity] = React.useState([]);
  const [totalRecords, setTotalRecord] = React.useState(0);
  const [resData, setResData] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("cou_id");
  const [sortDirection, setSortDirection] = useState("asc");

  const baseUrl = Config.BASE_API_URL;
  const getCourseUrl = baseUrl + Config.GET_COURSES_URL;
  const params = `skip=${
    page * perPage
  }&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}`;
  const searchParam = `cit_id=${searchData?.city}&searchText=${searchData?.text}`;
  const url = `${getCourseUrl}?${params}&${searchParam}`;

  useEffect(async () => {
    setLoading(true);
    setCountry(await getAllCountries());
    setCity([]);
    await fetchData();
    setPage(1);
    setLoading(false);
  }, []);

  if (loading) return <CustomSpinner />;

  const handleChange = (name, value) => {
    setSearchData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    const params = `skip=0&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}`;
    const url = `${getCourseUrl}?${params}&${searchParam}`;
    const response = await getCourses(url);
    setResData(response?.status ? response?.data?.courses : []);
    setTotalRecord(response?.status ? response?.data?.count : 0);
    setHasMore(response?.data.count > 0);
  };

  const handleSubmit = () => {
    // setSearchResult(true)

    console.log(searchData);
  };

  const fetchDataOnScroll = async () => {
    if (resData.length >= totalRecords) {
      setHasMore(false);
      return;
    }
    const response = await getCourses(url);
    setResData((prevState) =>
      prevState.concat(response?.status ? response?.data?.course : [])
    );
    console.log("fn called", response.json());
  };
  return (
    <Container className="wrapper">
      <Divider style={{ margin: "12px 0" }} />
      <Header>
        <h2 className="default-color to-uppercase">Courses</h2>
      </Header>
      <Divider style={{ margin: "12px 0" }} />
      {/* <div className="wrapper-search">
        <Grid fluid>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Input
                id="search-bar"
                placeholder="LOOKING FOR ....."
                value={searchData?.text}
                onChange={(e) => handleChange("text", e)}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <SelectPicker
                placeholder="Select Country"
                value={searchData?.country}
                data={country}
                onChange={async (cou_id) => {
                  setSearchData((prevState) => ({
                    ...prevState,
                    city: "",
                    country: cou_id,
                  }));
                  setCity(await getCountryCities(cou_id));
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <SelectPicker
                placeholder="Select City"
                data={city}
                value={searchData?.city}
                onChange={(e) => handleChange("city", e)}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={4}
              lg={4}
              style={{ backgroundColor: "#EA1F24" }}
            >
              <Button className="d-w-r-btn" onClick={fetchData}>
                SEARCH
              </Button>
            </Col>
          </Row>
        </Grid>
      </div> */}

      <SearchCompanyComponent
        searchDataFromHome={
          window.location.state
            ? window.location.state.searchData
            : {
                text: "",
                type: 0,
                country: 0,
                city: 0,
              }
        }
      />

      <Divider />
      <Content>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab
            eventKey="list"
            title={
              <>
                <h4 style={{ color: "#EA1F24" }}>List View</h4>
              </>
            }
          >
            <List bordered size={"sm"}>
              <InfiniteScroll
                dataLength={resData.length} //This is important field to render the next data
                next={fetchDataOnScroll}
                hasMore={hasMore}
                loader={<CustomSmallSpinner />}
                endMessage={<EndingNote />}
              >
                {resData?.map((d, index) => (
                  <List.Item key={index}>
                    <Panel
                      header={
                        <Stack spacing={20} className="stack-last-item-right">
                          {/*<Avatar*/}
                          {/*    size="lg"*/}
                          {/*    src={`${baseUrl}/${d.logo}`}*/}
                          {/*    alt={d?.company?.name}*/}
                          {/*/>*/}
                          {/* <Link to={`/courses/detail/${d.cou_id}`}> */}
                            <h5>{d?.name}</h5>
                            <h6>
                              {d?.language}{" "}
                              <small style={{ fontSize: "13px" }}>
                                ({d?.price})
                              </small>
                            </h6>
                          {/* </Link> */}
                          <div>
                            <Button
                              as={Link}
                              to={`/courses/detail/${d.cou_id}`}
                              // state={{job_id: d.job_id}}
                              className="d-w-r-btn"
                              style={{ color: "white" }}
                            >
                              {" "}
                              See Detail
                            </Button>
                          </div>
                        </Stack>
                      }
                    >
                      <div className="text-start ">
                        <div style={{ marginBottom: "8px" }}>
                          <div className="icon_div" style={{ width: 110 }}>
                            <span>Duration:</span>
                          </div>
                          <div
                            className="text_div"
                            style={{ width: "calc(100% - 150px)" }}
                          >
                            <span>{d.duration} Days</span>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </List.Item>
                ))}
              </InfiniteScroll>
            </List>
          </Tab>
          <Tab
            eventKey="grid"
            title={
              <>
                <h4 style={{ color: "#EA1F24" }}>Grid View</h4>
              </>
            }
          >
            {" "}
            <Container>
            <Box sx={{ flexGrow: 1}}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                {resData?.map((d, index) => (
                  <Grid  item xs={2} sm={4} md={4} key={index}>
                    {/* <Panel
                      style={{
                        maxWidth: "450px",
                        display: "inline-block",
                        padding: "10px",
                        border: "3px solid #eee",
                        margin: "10px",
                      }}
                      header={
                        <Stack spacing={20} className="stack-last-item-right">
                            <h5>{d?.name}</h5>
                            <h6>
                              {d?.language}{" "}
                              <small style={{ fontSize: "13px" }}>
                                ({d?.price})
                              </small>
                            </h6>
                          <div>
                            <Button
                              as={Link}
                              to={`/courses/detail/${d.cou_id}`}
                              className="d-w-r-btn"
                              style={{ color: "white" }}
                            >
                              {" "}
                              See Detail
                            </Button>
                          </div>
                        </Stack>
                      }
                    >
                      <div className="text-start ">
                        <div style={{ marginBottom: "8px" }}>
                          <div className="icon_div" style={{ width: 110 }}>
                            <span>Duration:</span>
                          </div>
                          <div
                            className="text_div"
                            style={{ width: "calc(100% - 150px)" }}
                          >
                            <span>{d.duration} Days</span>
                          </div>
                        </div>
                      </div>
                    </Panel> */}



                    {/* <br />
                    <Panel
                      shaded
                      bordered
                      bodyFill
                      style={{
                        display: "inline-block",
                        width: 340,
                        marginLeft: "3em",
                      }}
                    >
                      <Panel
                        header={
                          <div>
                            <h5>{d?.name}</h5>
                            <h6>
                              {d?.language}{" "}
                              <small style={{ fontSize: "13px" }}>
                                ({d?.price})
                              </small>
                            </h6>
                          </div>
                        }
                      >
                          <div className="text-start ">
                        <div style={{ marginBottom: "8px" }}>
                          <div className="icon_div" style={{ width: 110 }}>
                            <span>Duration:</span>
                          </div>
                          <div
                            className="text_div"
                            style={{ width: "calc(100% - 150px)" }}
                          >
                            <span>{d.duration} Days</span>
                          </div>
                        </div>
                      </div>
                        <div>
                          <Button
                            as={Link}
                            to={`/courses/detail/${d.cou_id}`}
                            className="d-w-r-btn"
                            style={{ color: "white" }}
                          >
                            {" "}
                            See Detail
                          </Button>
                        </div>
                      </Panel>
                    </Panel>
                    <br />
                    <br /> */}
                    <Card
                        style={{
                          width: 280,
                          height: 280,
                          marginLeft:'auto',
                          marginRight:'auto'
                        }}
                      >
                      
                        <CardContent>
                          <div style={{height:180}}>
                          <Typography gutterBottom variant="h5" component="div">
                          <div>
                            <h5>{d?.name}</h5>
                         
                          </div>
                           
                         
                          </Typography>
                          <p>
                          <h6>
                              {d?.language}{" "}
                              <small style={{ fontSize: "13px" }}>
                                ({d?.price})
                              </small>
                            </h6>
                        </p>
                        <div className="text-start ">
                        <div style={{ marginBottom: "8px" }}>
                          <div className="icon_div" style={{ width: 110 }}>
                            <span>Duration:</span>
                          </div>
                          <div
                            className="text_div"
                            style={{ width: "calc(100% - 150px)" }}
                          >
                            <span>{d.duration} Days</span>
                          </div>
                        </div>
                      </div>
                          </div>
                       
                        </CardContent>
                        <CardActions>
                        <Button
                            as={Link}
                            to={`/courses/detail/${d.cou_id}`}
                            className="d-w-r-btn"
                            style={{ color: "white" }}
                          >
                            {" "}
                            See Detail
                          </Button>
                        </CardActions>
                      </Card>
                      <br />
                    <br />

                  </Grid>
                ))}
              </Grid>
              </Box>
            </Container>
          </Tab>
        </Tabs>
      </Content>
      <Divider />
    </Container>
  );
};

export default CourseListing;
