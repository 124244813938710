import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    ButtonToolbar,
    Col,
    Content,
    Divider,
    Form,
    Grid,
    Message,
    Row,
    SelectPicker,
    toaster,
    Uploader
} from "rsuite";
import {TextField} from "components/textField";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {formats, modules} from "components/profile/company/reactQuilConfig";
import 'react-phone-number-input/style.css'
import {MediaUrlInputControl} from "components/profile/company/inputs/addMediaURLInput";
import {createJob, getJobDetail, updateJob} from "service/job.service";
import {getCurrentUser} from "service/auth.service";
import {getBase64, toTitle} from "service/until.service";
import {v4 as uuidv4} from "uuid";
import Config from "../../../../config.json";
import {CustomSpinner} from "../../../common/spinner";
import {CompanyJobFormModel} from "lib/formModels";
import {handleMediaDocUpload, isImage} from "../../../../lib/utility";
import {ErrorMessageImages} from "../../../common/errorMessageImages";


const CompanyJob = ({com_id, job_id, editOrNew = false}) => {
    const formRef = useRef();
    const [user, setUser] = useState(getCurrentUser());
    const defaultFormValue = {
        job_id,
        com_id,
        title: '',
        employmentType: '',
        description: '',
        minExperience: '',
        apply_url:'',
        mediaUrl: [{path: '', mediaType: 'Video'}],
        mediaDoc: [],
        use_id: user.use_id,
    }
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState(defaultFormValue);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isMediaDocLoading, setIsMediaDocLoading] = useState(false);
    const [isMediaDocUploaded, setIsMediaDocUploaded] = useState(editOrNew);
const [isImageType, setIsImageType] = useState(true);
    const [mediaDocs, setMediaDocs] = useState([]);

    const baseUrl = Config.BASE_API_URL;
    const getJobsUrl = baseUrl + Config.GET_JOBS_URL;


    const employmentTypeList = [
        {label: 'Full Time', value: 'full_time'},
        {label: 'Part Time', value: 'part_time'},
        {label: 'Contract', value: 'contract'},
        {label: 'Internship', value: 'internship'},
        {label: 'Temporary', value: 'temporary'},
        {label: 'Volunteer', value: 'volunteer'},
    ]

    useEffect(async () => {
        setLoading(true)

        if (editOrNew) {
            await handleJobDataSet()
        }

        setLoading(false)

    }, [])


    const handleJobDataSet = async () => {

        const response = await getJobDetail(`${getJobsUrl}/${job_id}`)
        if (response.status) {
            const data = response.data

            const mediaDoc = data.job_mediadoc.map((media, index) => {
                return {
                    url: media.mediadoc.path,
                    type: media.mediadoc.mediaType,
                    fileKey: index, name: media.mediadoc.path.split('/').pop(),
                    path: media.mediadoc.path,
                    mediaType: media.mediadoc.mediaType,
                    med_id: media.med_id
                }
            })

            const s3BaseURl = Config.S3_BUCKET_BASE_URL

            const filterMediaDoc = mediaDoc.filter((media) => media.url.includes(s3BaseURl))

            setFileList(filterMediaDoc.map((media) => {
                return {
                    url: media.url,
                    type: media.type,
                    fileKey: media.fileKey,
                    name: media.name,
                    med_id: media.med_id
                }
            }))

            const job = {
                job_id,
                com_id,
                title: data.title,
                employmentType: data.employmentType,
                description: data.description,
                minExperience: data.minExperience,
                apply_url: data.apply_url,
                use_id: user.use_id,
                mediaUrl: mediaDoc.filter((media) => media.type === 'Video'),
                mediaDoc: mediaDoc.filter((media) => media.type !== 'Video')
            }
            // console.log(job)
            setFormValue(job)

        }
    }

    const handleSubmit = async () => {
        setIsSubmitted(true)
        if (!formRef.current.check()) {
            console.error('Form Error');
            setIsSubmitted(false)
            return;
        }

        if (!editOrNew) {
            const response = await createJob(formValue, user.access_token)

            if (response.status) {
                toaster.push(<Message type="success">Updated successfully</Message>);
                setFormValue(defaultFormValue)
                setFileList([]);
            } else {
                toaster.push(<Message type="error">{response.message}</Message>);
            }
        } else {
            const response = await updateJob(formValue, user.access_token)

            if (response.status) {
                toaster.push(<Message type="success">Job Updated successfully</Message>);
            // setFormValue(defaultFormValue)
            } else {
                toaster.push(<Message type="error">{response.message}</Message>);
            }
            // console.log(formValue, 'Update Job');
        }

        setIsSubmitted(false)
        // console.log(formValue, 'Form Value');
    };

    const handleChange = (name, value) => {
        setFormValue(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    if (loading) return <CustomSpinner/>

    return (
        <Content>
            <Grid fluid className='create-item-form' style={{marginLeft: 15}}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={CompanyJobFormModel}
                    disabled={isSubmitted}
                    fluid
                >

                    <Row>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="title" label="Job Title" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select Employment Type:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="employmentType" data={employmentTypeList}
                                                  accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>Employment Type Required</Form.HelpText>
                                </div>
                            </Form.Group>
                            {/*<TextField name="employmentType" label="Employment Type" helpText='Required'/>*/}
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="minExperience" label="Minimum Experience" helpText='Required' type='number' min={0}
                                       step='any'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="apply_url" label="Apply URL" helpText='Required'/>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Group controlId="textarea">
                                <Form.ControlLabel>Description</Form.ControlLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={formValue?.description}
                                    onChange={(e) => handleChange('description', e)}
                                    modules={modules}
                                    formats={formats}
                                    style={{marginRight: 25}}
                                />
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>

                        <Divider style={{backgroundColor: '#0C3144', margin: ' 12px 0'}}/>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Images and Videos</h6>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Control
                                style={{width: 'inherit'}}
                                name="mediaUrl"
                                accepter={MediaUrlInputControl}
                                fieldError={formError.mediaUrl}
                            />

                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            {/*todo: check both options*/}
                            {/*<TextField name="images" label="Upload Images" helpText='Only Upload Images' accepter={Uploader} draggable={true}/>*/}
                            <Form.Group controlId="uploader" style={{marginRight: '25px'}}
                            >
                                <Form.ControlLabel>Images</Form.ControlLabel>
                                <Uploader
                                    action=""
                                    fileList={fileList}
                                    listType="picture"
                                    draggable
                                    autoUpload={false}
                                    disabled={fileList.length >= 5}
                                    multiple={false}
                                    accept={'image/*'}
                                    name="mediaDoc"
                                    onRemove={(file) => {
                                                                                setIsImageType(isImage(file));
                                        setFileList(fileList.filter(f => f.fileKey !== file.fileKey));
                                        setFormValue({...formValue, mediaDoc: fileList.filter(f => f.fileKey !== file.fileKey)})
                                    }}
                                    onChange={async (files) => {
                                        console.log(files, 'files')
                                        setFileList(files);
                                        let mediaDocData = []
                                        for (const f of files) {
                                            if (isImage(f)) {
                                                if (f.blobFile) {
                                                    setIsMediaDocUploaded(false);
                                                    mediaDocData.push({file: f, isNew: true})
                                                } else {
                                                    mediaDocData.push({...f, isNew: false})
                                                }
                                                setIsImageType(true);

                                            } else {

                                                toaster.push(<Message type="error">`${f.name} filetype is not Allowed` </Message>, {
                                                    duration: 7000,
                                                    position: 'top-right',
                                                    type: 'danger'
                                                })
                                                setIsImageType(false);
                                            }
                                        }
                                        setMediaDocs(mediaDocData)
                                        // setFormValue({...formValue, mediaDoc: mediaDocData})
                                    }}
                                    renderFileInfo={(file, fileElement) => {
                                        return (
                                            <div>
                                                <span>File Name: {file.name}</span>
                                            </div>
                                        );
                                    }}
                                >
                                    <div style={{lineHeight: '200px'}}>Click or Drag images to this area to upload</div>
                                </Uploader>
                                <br/>
                                <Button
                                    style={{marginTop: 10, width: 'auto'}}
                                    className={'d-w-r-btn'}
                                    disabled={isMediaDocUploaded || !mediaDocs.length}
                                    onClick={async () => handleMediaDocUpload(setIsMediaDocLoading, setIsMediaDocUploaded, setFormValue, mediaDocs, user.access_token)}
                                    loading={isMediaDocLoading}
                                >
                                    Start Upload
                                </Button>
                                {
                                    !isImageType &&
                                   <ErrorMessageImages/>
                                }
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary"
                                // type="submit"
                                    className='d-w-r-btn'
                                    onClick={handleSubmit}
                                    style={{width: 'auto'}}
                                    disabled={Object.keys(formError).length !== 0 || isSubmitted || !isMediaDocUploaded}
                                    loading={isSubmitted }
                            >Submit</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Grid>
        </Content>

    );
};

export default CompanyJob;