import React from 'react';
import Carousel from "react-multi-carousel";
import {IconButton, Panel} from "rsuite";
import Config from "config.json";
import EmailIcon from "@rsuite/icons/Email";
import {GrFacebook, GrInstagram, GrTwitter, GrYoutube} from "react-icons/gr";


const baseUrl = Config.BASE_API_URL;

function Speakers({data}) {

    if (data.length === 0) return "No Speaker Found"
    return (
        <div>
            <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1080
                        },
                        items: 2,
                        partialVisibilityGutter: 50
                    },
                    mobile: {
                        breakpoint: {
                            max: 725,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1080,
                            min: 725
                        },
                        items: 2,
                        partialVisibilityGutter: 30

                    }
                }}
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >

                {data.map((data, index) =>

                    <Panel key={index} shaded bordered bodyFill
                           style={{margin:'30px 10px' , maxWidth: "100%"}}>

                        <div className={'image-cover'} style={{height:'300px'}}>

                        <img alt={data?.name} key={index}  src={`${data?.profileImage}`}
                             height="300px"
                             />
                        </div>
                        <Panel header={<h6>{data?.name} <small style={{fontSize:'12px'}}>({data?.designation})</small></h6>}>

                            <div className="text-start ">
                                <div style={{marginBottom: "8px"}}>

                                    <div className="icon_div">
                                        <EmailIcon/>
                                    </div>
                                    <div className="text_div" >
                                        <a style={{overflowWrap:'break-word'} } href={`mailto:${data?.email}`}>{data?.email}</a>
                                    </div>

                                </div>

                                <p>{data.description}</p>

                                {
                                    data.fbURL &&
                                    <IconButton target={'_blank'} href={data.fbURL}
                                                icon={<GrFacebook size={25}/>} appearance="subtle" circle/>
                                }
                                {
                                    data.youtubeURL &&

                                    <IconButton target={'_blank'} href={data.youtubeURL}
                                                icon={<GrYoutube size={25}/>} appearance="subtle" circle/>
                                }
                                {
                                    data.twitterURL &&

                                    <IconButton target={'_blank'} href={data.twitterURL}
                                                icon={<GrTwitter size={25}/>} appearance="subtle" circle/>
                                }
                                {
                                    data.instaURL &&

                                    <IconButton target={'_blank'} href={data.instaURL}
                                                icon={<GrInstagram size={25}/>} appearance="subtle" circle/>
                                }

                            </div>
                        </Panel>
                    </Panel>
                )}
            </Carousel>

        </div>
    );
}

export default Speakers;
