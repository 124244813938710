import {Button} from "rsuite";

export const LinkButtonSm = ({ children, ...props }) => {
    return (
        <Button
            appearance="link"
            size="sm"
            {...props}
        >
            {children}
        </Button>
    );
}

export const PrimaryButtonSm = ({ children, ...props }) => {
    return (


        <Button
            appearance="primary"
            size="sm"
            as={'button'}
            {...props}
        >
            {children}
        </Button>
    );
}    