import {Message} from "rsuite";

export const ErrorMessageImages = () => {
    return (

        <Message closable showIcon type="error" header="Error">
            <span>
                Only jpeg,jpg,png and webp formats are allowed.
            </span>
        </Message>)
}
