import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonToolbar,
    Col,
    Content,
    DatePicker,
    Divider,
    Form,
    Grid,
    Message,
    Row,
    toaster,
    Uploader
} from "rsuite";
import {TextField} from "components/textField";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {formats, modules} from "components/profile/company/reactQuilConfig";
import 'react-phone-number-input/style.css'
import {MediaUrlInputControl} from "components/profile/company/inputs/addMediaURLInput";
import {CourseAvailability} from "components/profile/company/inputs/courseAvailability";
import {getBase64, toTitle} from "service/until.service";
import {v4 as uuidv4} from "uuid";
import {createCourse, getCourses, updateCourse} from "service/course.service";
import Config from "../../../../config.json";
import {CustomSpinner} from "../../../common/spinner";
import {getCurrentUser} from "service/auth.service";
import {CompanyCourseFormModel} from "lib/formModels";
import {handleMediaDocUpload} from "../../../../lib/utility";
import {isBefore} from "rsuite/cjs/utils/dateUtils";


const CompanyCourse = ({editOrNew = false, com_id, cou_id}) => {
    const [user, setUser] = useState(getCurrentUser());
    const defaultFormValue = {
        com_id,
        cou_id,
        use_id: user.use_id,
        name: '',
        price: 0,
        description: '',
        language: '',
        website: '',
        duration: '',
        offeredAt: '',
        startDate: new Date(),
        endDate: new Date(),
        mediaUrl: [{path: '', mediaType: 'Video'}],
        courseAvailability: [new Date()],
        mediaDoc: []
    }
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const [formValue, setFormValue] = React.useState(defaultFormValue);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [mediaDocs, setMediaDocs] = useState([]);
    const [isMediaDocLoading, setIsMediaDocLoading] = useState(false);
    const [isMediaDocUploaded, setIsMediaDocUploaded] = useState(editOrNew);

    const baseUrl = Config.BASE_API_URL;
    const getCourseUrl = baseUrl + Config.GET_COURSES_URL

    // console.log(editOrNew)

    useEffect(async () => {
        setLoading(true)

        if (editOrNew) {
            await handleJobDataSet()
        }

        setLoading(false)

    }, [])

    const handleJobDataSet = async () => {

        const response = await getCourses(`${getCourseUrl}/${cou_id}`)
        if (response.status) {
            const data = response.data

            const mediaDoc = data.course_mediadoc.map((media, index) => {
                return {
                    url: media.mediadoc.path,
                    type: media.mediadoc.mediaType,
                    fileKey: index, name: media.mediadoc.path?.split('/').pop(),
                    path: media.mediadoc.path,
                    mediaType: media.mediadoc.mediaType,
                    med_id: media.med_id
                }
            })

            const s3BaseURl = Config.S3_BUCKET_BASE_URL

            const filterMediaDoc = mediaDoc.filter((media) => media.url.includes(s3BaseURl))

            setFileList(filterMediaDoc.map((media) => {
                return {
                    url: media.url,
                    type: media.type,
                    fileKey: media.fileKey,
                    name: media.name,
                    med_id: media.med_id
                }
            }))

            const course = {
                com_id,
                cou_id,
                use_id: user.use_id,
                name: data.name,
                price: data.price,
                description: data.description,
                language: data.language,
                website: data.url,
                duration: data.duration,
                offeredAt: data.offeredAt,
                startDate: new Date(data.startDate),
                endDate: new Date(data.endDate),
                courseAvailability: data.availability.map(ava => ava.time),
                mediaDoc: mediaDoc.filter((media) => media.type !== 'Video'),
                mediaUrl: mediaDoc.filter((media) => media.type === 'Video')
            }
            // console.log(course)
            setFormValue(course)

        }
    }

    // console.log(formValue);
    // console.log(formError);

    const handleSubmit = async () => {
        setIsSubmitted(true)
        if (!formRef.current.check()) {
            console.error('Form Error');
            setIsSubmitted(false)
            return;
        }

        if (!editOrNew) {
            const response = await createCourse(formValue, user.access_token)

            if (response.status) {
                toaster.push(<Message type="success">Course Created successfully</Message>);
                setFormValue(defaultFormValue)
                setFileList([])
            } else {
                toaster.push(<Message type="error">{response.message}</Message>);
            }
        } else {
            const response = await updateCourse(formValue, user.access_token)

            if (response.status) {
                // setFormValue(defaultFormValue)
                toaster.push(<Message type="success">Course Updated successfully</Message>);
            } else {
                toaster.push(<Message type="error">{response.message}</Message>);
            }
        }


        // console.log(formValue, 'Form Value');
        setIsSubmitted(false)

    }

    const handleChange = (name, value) => {
        setFormValue(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    if (loading) return <CustomSpinner/>

    return (
        <Content>
            <Grid fluid className='create-item-form' style={{marginLeft: 15}}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    disabled={isSubmitted}
                    model={CompanyCourseFormModel}
                    fluid
                >

                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="name" label="Course Title" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="language" label="Language" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="price" label="Price" type={'number'} helpText='Required'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="website" label="URL" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="duration" min={1} label="Duration" type={'number'} helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="offeredAt" label="Offered At" helpText='Required'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Group controlId="textarea">
                                <Form.ControlLabel>Description</Form.ControlLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={formValue?.description}
                                    onChange={(e) => handleChange('description', e)}
                                    modules={modules}
                                    formats={formats}
                                    style={{marginRight: 25}}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '12px 0'}}/>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name='startDate' format="yyyy-MM-dd"  disabledDate={date => isBefore(date, new Date(Date.now() - (3600 * 1000 * 24)))} label="Start Date" accepter={DatePicker}/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name='endDate' format="yyyy-MM-dd" disabledDate={date => isBefore(date, formValue.startDate)} label="End Date" accepter={DatePicker}/>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Images and Videos</h6>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Control
                                style={{width: 'inherit'}}
                                name="mediaUrl"
                                accepter={MediaUrlInputControl}
                                fieldError={formError.mediaUrl}
                            />

                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Group controlId="uploader" style={{marginRight: '25px'}}
                            >
                                <Form.ControlLabel>Uploader:</Form.ControlLabel>
                                <Uploader
                                    action=""
                                    fileList={fileList}
                                    listType="picture"
                                    draggable
                                    disabled={fileList.length >= 5}
                                    autoUpload={false}
                                    multiple
                                    accept={'image/*'}
                                    name="mediaDoc"
                                    onRemove={(file) => {
                                        setFileList(fileList.filter(f => f.fileKey !== file.fileKey));
                                        setFormValue({...formValue, mediaDoc: fileList.filter(f => f.fileKey !== file.fileKey)})
                                    }}
                                    onChange={async (files) => {
                                        // console.log(files, 'files')
                                        setFileList(files);
                                        let mediaDocData = []
                                        for (const f of files) {
                                            if (f.blobFile){
                                                setIsMediaDocUploaded(false);
                                                mediaDocData.push({file: f, isNew: true})
                                            }else{
                                                mediaDocData.push({...f, isNew: false})
                                            }

                                        }

                                        setMediaDocs(mediaDocData)
                                        // setFormValue({...formValue, mediaDoc: mediaDocData})
                                    }}
                                    renderFileInfo={(file, fileElement) => {
                                        return (
                                            <div>
                                                <span>File Name: {file.name}</span>
                                            </div>
                                        );
                                    }}
                                >
                                    <div style={{lineHeight: '200px'}}>Click or Drag images to this area to upload
                                    </div>
                                </Uploader>
                                <br/>
                                <Button
                                    style={{marginTop: 10, width: 'auto'}}
                                    className={'d-w-r-btn'}
                                    disabled={isMediaDocUploaded || !mediaDocs.length}
                                    onClick={async () => handleMediaDocUpload(setIsMediaDocLoading, setIsMediaDocUploaded, setFormValue, mediaDocs, user.access_token)}
                                    loading={isMediaDocLoading}
                                >
                                    Start Upload
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Availability</h6>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Control
                                name="courseAvailability"
                                accepter={CourseAvailability}
                                fieldError={formError.courseAvailability}
                            />

                        </Col>

                    </Row>

                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary"
                                // type="submit"
                                    className='d-w-r-btn'
                                    onClick={handleSubmit}
                                    style={{width: 'auto'}}
                                    disabled={Object.keys(formError).length !== 0 || isSubmitted || !isMediaDocUploaded}
                                    loading={isSubmitted}
                            >Submit</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Grid>
        </Content>

    );
};

export default CompanyCourse;