import {Pagination} from "rsuite";
import React from "react";

export const PaginationCustom = ({limit, setLimit, page, setPage, count}) => {
    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
    };

    return (
        <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['-','limit', '|', 'pager']}
            total={count}
            limitOptions={[10, 20, 30]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
        />
    )

}