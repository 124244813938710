import React, {useState} from 'react';
import {Button, Content, Divider, Grid, Nav, Stack} from "rsuite";
import 'react-quill/dist/quill.snow.css';
import 'styles/profile/company/createItem.css'
// import 'react-phone-number-input/style.css'
import 'styles/profile/company/handler.css'
import EventList from "components/profile/company/listing/eventList";
import CompanyList from "components/profile/company/listing/companyList";
import Handler from "components/profile/company/CompanyJobEventsCourses/handler";

import JobsList from "components/profile/company/listing/jobsListing";
import CoursesList from "components/profile/company/listing/coursesListing";
import {useLocation} from "react-router-dom";
import humanizeString from "../../../../lib/utility";



const HandlerListing = () => {
    const location = useLocation()
    const addListingBtnState = location.state?.addNew
    console.log(addListingBtnState);
    const [handlerListingNav, setHandlerListingNav] = React.useState(addListingBtnState ? addListingBtnState :'company');
    const [com_id, setCom_id] = useState(null)
    const [editOrNew, setEditOrNew] = useState(false)

    // console.log(editOrNew,'editOrNew');

    function SwitchCase({value}) {
        switch (value) {
            case 'addNew':
                return <Handler editOrNew={editOrNew} com_id={com_id}/>;
            case 'company':
                return <CompanyList setValueNav={setHandlerListingNav} setCom_id={setCom_id} setEditOrNew={setEditOrNew} />;
            case 'event':
                return <EventList/>;
            case 'job':
                return <JobsList />;
            case 'courses':
                return <CoursesList />;
            case 'editCompany':
                return <Handler com_id={com_id} editOrNew={editOrNew}/>
            default:
                return <CompanyList />;
        }
    }


    return (
        <Content>
            <Grid fluid className='create-item-form' style={{marginLeft: 15}}>

                        <Nav justified className='handler-nav' onSelect={setHandlerListingNav} activeKey={handlerListingNav}>
                            <Nav.Item eventKey='company'>
                                Company
                            </Nav.Item>
                            <Nav.Item eventKey='event'>Events</Nav.Item>
                            <Nav.Item eventKey='job'>Jobs</Nav.Item>
                            <Nav.Item eventKey='courses'>Courses</Nav.Item>
                        </Nav>
                        <Divider style={{backgroundColor: '#0C3144', margin: '12px'}}/>
                <Stack className='stack-last-item-right'>

                        <h1 className='default-color'
                            style={{margin: '15px', textTransform: 'uppercase', fontSize: '30px'}}>
                            {humanizeString(handlerListingNav)}
                        </h1>
                    {
                        handlerListingNav === 'company' &&
                        <Button onClick={() => {
                            setHandlerListingNav('addNew')
                            setEditOrNew(false)
                        }} className='d-w-r-btn add-new-btn'>ADD
                            NEW COMPANY</Button>
                    }
                </Stack>
                <SwitchCase value={handlerListingNav}/>
            </Grid>
        </Content>

    );
};

export default HandlerListing;