import React, {useEffect, useState} from "react";
import {Button, Container, Content, Divider, Header, List, Panel, Stack,} from "rsuite";
import {Link} from "react-router-dom";
import {getAllCountries} from "service/setting.service";
import InfiniteScroll from "react-infinite-scroll-component";
import {CustomSmallSpinner, CustomSpinner} from "components/common/spinner";
import {EndingNote} from "components/common/listDataEnd";
import {getAllEvents} from "service/event.service";
import Config from "../config.json";
import {SearchCompanyComponent} from "components/searchCompany";
import {Tab, Tabs} from "react-bootstrap";
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";


const Event = () => {
    const [searchData, setSearchData] = useState({
        text: "",
        country: 0,
        city: 0,
    });
    const [key, setKey] = useState("list");
    const [country, setCountry] = React.useState([]);
    const [city, setCity] = React.useState([]);
    const [totalRecords, setTotalRecord] = React.useState(0);
    const [resData, setResData] = React.useState([]);
    const [hasMore, setHasMore] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sortField, setSortField] = useState("startDate");
    const [sortDirection, setSortDirection] = useState("desc");
    const [couId, setCouId] = useState(null);

    const baseUrl = Config.BASE_API_URL;
    const getEventsUrl = baseUrl + Config.GET_EVENTS_URL;
    const searchParam = `cit_id=${searchData?.city}&searchText=${searchData?.text}`;

    const params = `skip=${
        page * perPage
    }&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}`;
    const url = `${getEventsUrl}?${params}&${searchParam}`;

    useEffect(async () => {
        setLoading(true);
        setCountry(await getAllCountries());
        setCity([]);
        await fetchData();
        setPage(1);
        setLoading(false);
    }, []);

    const fetchData = async () => {
        const params = `skip=0&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}`;
        const url = `${getEventsUrl}?${params}&${searchParam}`;
        const response = await getAllEvents(url);
        setResData(response?.status ? response?.data?.events : []);
        setTotalRecord(response?.status ? response?.data?.count : 0);
        setHasMore(response?.data.count > 0);
    };

    if (loading) return <CustomSpinner/>;

    const handleChange = (name, value) => {
        setSearchData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const fetchDataOnScroll = async () => {
        if (resData.length >= totalRecords) {
            setHasMore(false);
            return;
        }

        setPage((prevState) => prevState + 1);
        const response = await getAllEvents(url);
        setResData((prevState) =>
            prevState.concat(response?.status ? response?.data?.events : [])
        );
        // setResData(prevState => prevState.concat(resData))

        // console.log('fn called')
    };
    return (
        <Container className="wrapper">
            <Divider style={{margin: "12px 0"}}/>
            <Header>
                <h2 className="default-color to-uppercase">EVENTS</h2>
            </Header>
            <Divider style={{margin: "12px 0"}}/>
            {/* <div className="wrapper-search">
        <Grid fluid>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Input
                id="search-bar"
                placeholder="LOOKING FOR ....."
                value={searchData?.text}
                onChange={(e) => handleChange("text", e)}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <SelectPicker
                placeholder="Select Country"
                value={searchData?.country}
                data={country}
                onChange={async (cou_id) => {
                  setSearchData((prevState) => ({
                    ...prevState,
                    city: "",
                    country: cou_id,
                  }));
                  setCity(await getCountryCities(cou_id));
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <SelectPicker
                placeholder="Select City"
                data={city}
                value={searchData?.city}
                onChange={(e) => handleChange("city", e)}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={4}
              lg={4}
              style={{ backgroundColor: "#EA1F24" }}
            >
              <Button className="d-w-r-btn" onClick={fetchData}>
                SEARCH
              </Button>
            </Col>
          </Row>
        </Grid>
      </div> */}

            <SearchCompanyComponent
                searchDataFromHome={
                    window.location.state
                        ? window.location.state.searchData
                        : {
                            text: "",
                            type: 0,
                            country: 0,
                            city: 0,
                        }
                }
            />

            <Divider/>
            <Content>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab
                        eventKey="list"
                        title={
                            <>
                                <h4 style={{color: "#EA1F24"}}>List View</h4>
                            </>
                        }
                    >
                        <List bordered size={"sm"}>
                            <InfiniteScroll
                                dataLength={resData.length} //This is important field to render the next data
                                next={fetchDataOnScroll}
                                hasMore={hasMore}
                                loader={<CustomSmallSpinner/>}
                                endMessage={<EndingNote/>}
                            >
                                {resData?.map((d, index) => (
                                    <List.Item key={index}>
                                        <Panel
                                            header={
                                                <Stack spacing={20} className="stack-last-item-right">
                                                    {/*<Avatar*/}
                                                    {/*    size="lg"*/}
                                                    {/*    src={`${baseUrl}/${d.logo}`}*/}
                                                    {/*    alt={d?.company?.name}*/}
                                                    {/*/>*/}
                                                    <div>
                                                        {/*<h5>{d?.company?.name}</h5>*/}
                                                        <h5>
                                                            <Link to={`/event/detail/${d?.eve_id}`}>
                                                                {d?.title}{" "}
                                                            </Link>
                                                            <small style={{fontSize: "13px"}}>
                                                                (
                                                                <Link
                                                                    to={`/company/detail/${d?.company?.com_id}`}
                                                                >
                                                                    {d?.company?.name})
                                                                </Link>
                                                            </small>
                                                        </h5>
                                                        <h6>
                                                            {d?.city?.name}, {d?.city?.country?.name}
                                                        </h6>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            as={Link}
                                                            to={`/event/detail/${d?.eve_id}`}
                                                            // state={{eve_id: d.eve_id}}
                                                            className="d-w-r-btn"
                                                            style={{color: "white !important"}}
                                                        >
                                                            {" "}
                                                            See Detail
                                                        </Button>
                                                    </div>
                                                </Stack>
                                            }
                                        >
                                            <div className="text-start ">
                                                <div style={{marginBottom: "8px"}}>
                                                    <div className="icon_div" style={{width: 110}}>
                                                        <span>Category:</span>
                                                    </div>
                                                    <div
                                                        className="text_div"
                                                        style={{width: "calc(100% - 150px)"}}
                                                    >
                            <span>
                              {d?.eventsubcategory?.name},{" "}
                                {d?.eventsubcategory?.eventcategory?.name}
                            </span>
                                                    </div>
                                                </div>
                                                <div style={{marginBottom: "8px"}}>
                                                    <div className="icon_div" style={{width: 110}}>
                                                        <span>Type:</span>
                                                    </div>
                                                    <div
                                                        className="text_div"
                                                        style={{width: "calc(100% - 150px)"}}
                                                    >
                                                        <span>{d?.eventtype?.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </List.Item>
                                ))}
                            </InfiniteScroll>
                        </List>
                    </Tab>
                    <Tab
                        eventKey="grid"
                        title={
                            <>
                                <h4 style={{color: "#EA1F24"}}>Grid View</h4>
                            </>
                        }
                    >
                        <Container>
                            <Box sx={{flexGrow: 1, marginLeft: 'auto', marginRight: 'auto'}}>
                                <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 2, sm: 8, md: 12}}>
                                    {resData?.map((d, index) => (
                                        <Grid item xs={2} sm={4} md={4} key={index}>
                                            {/* <Panel
                      style={{
                        maxWidth: "450px",
                        display: "inline-block",
                        padding: "10px",
                        border: "3px solid #eee",
                        margin: "10px",
                      }}
                      header={
                        <Stack spacing={20} className="stack-last-item-right">
                          <div>
                            <h5>
                              {d?.title}{" "}
                              <small style={{ fontSize: "13px" }}>
                                (
                                  {d?.company?.name})
                              </small>
                            </h5>
                            <h6>
                              {d?.city?.name}, {d?.city?.country?.name}
                            </h6>
                          </div>
                          <div>
                            <Button
                              as={Link}
                              to={`/event/detail/${d?.eve_id}`}
                              className="d-w-r-btn"
                              style={{ color: "white" }}
                            >
                              {" "}
                              See Detail
                            </Button>
                          </div>
                        </Stack>
                      }
                    >
                      <div className="text-start ">
                        <div style={{ marginBottom: "8px" }}>
                          <div className="icon_div" style={{ width: 110 }}>
                            <span>Category:</span>
                          </div>
                          <div
                            className="text_div"
                            style={{ width: "calc(100% - 150px)" }}
                          >
                            <span>
                              {d?.eventsubcategory?.name},{" "}
                              {d?.eventsubcategory?.eventcategory?.name}
                            </span>
                          </div>
                        </div>
                        <div style={{ marginBottom: "8px" }}>
                          <div className="icon_div" style={{ width: 110 }}>
                            <span>Type:</span>
                          </div>
                          <div
                            className="text_div"
                            style={{ width: "calc(100% - 150px)" }}
                          >
                            <span>{d?.eventtype?.name}</span>
                          </div>
                        </div>
                      </div>
                    </Panel> */}

                                            {/* <br />
                    <Panel
                      shaded
                      bordered
                      bodyFill
                      style={{
                        display: "inline-block",
                        width: 280,
                        height:360
                      }}
                    >
                      <Panel
                        header={
                          <div>
                            <h5>
                              {d?.title}{" "}
                              <small style={{ fontSize: "13px" }}>
                                ({d?.company?.name})
                              </small>
                            </h5>
                            <h6>
                              {d?.city?.name}, {d?.city?.country?.name}
                            </h6>
                          </div>
                        }
                      >
                        <div className="text-start ">
                          <div style={{ marginBottom: "8px" }}>
                            <div className="icon_div" style={{ width: 110 }}>
                              <span>Category:</span>
                            </div>
                            <div
                              className="text_div"
                              style={{ width: "calc(100% - 150px)" }}
                            >
                              <span>
                                {d?.eventsubcategory?.name},{" "}
                                {d?.eventsubcategory?.eventcategory?.name}
                              </span>
                            </div>
                          </div>
                          <div style={{ marginBottom: "8px" }}>
                            <div className="icon_div" style={{ width: 110 }}>
                              <span>Type:</span>
                            </div>
                            <div
                              className="text_div"
                              style={{ width: "calc(100% - 150px)" }}
                            >
                              <span>{d?.eventtype?.name}</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Button
                            as={Link}
                            to={`/event/detail/${d?.eve_id}`}
                            className="d-w-r-btn"
                            style={{ color: "white" }}
                          >
                            {" "}
                            See Detail
                          </Button>
                        </div>
                      </Panel>
                    </Panel> */}

                                            <Card
                                                style={{
                                                    width: 280,
                                                    height: 330,
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <CardContent>
                                                    <div style={{height: 240}}>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            <h5>
                                                                {d?.title}{" "}
                                                                <small style={{fontSize: "13px"}}>
                                                                    ({d?.company?.name})
                                                                </small>
                                                            </h5>


                                                        </Typography>
                                                        <p>
                                                            <h6>
                                                                {d?.city?.name}, {d?.city?.country?.name}
                                                            </h6>
                                                        </p>
                                                        <div className="text-start ">
                                                            <div style={{marginBottom: "8px"}}>
                                                                <div className="icon_div" style={{width: 110}}>
                                                                    <span>Category:</span>
                                                                </div>
                                                                <div
                                                                    className="text_div"
                                                                    style={{width: "calc(100% - 150px)"}}
                                                                >
                              <span>
                                {d?.eventsubcategory?.name},{" "}
                                  {d?.eventsubcategory?.eventcategory?.name}
                              </span>
                                                                </div>
                                                            </div>
                                                            <div style={{marginBottom: "8px"}}>
                                                                <div className="icon_div" style={{width: 110}}>
                                                                    <span>Type:</span>
                                                                </div>
                                                                <div
                                                                    className="text_div"
                                                                    style={{width: "calc(100% - 150px)"}}
                                                                >
                                                                    <span>{d?.eventtype?.name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        as={Link}
                                                        to={`/event/detail/${d?.eve_id}`}
                                                        className="d-w-r-btn"
                                                        style={{color: "white"}}
                                                    >
                                                        {" "}
                                                        See Detail
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                            <br/>
                                            <br/>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Container>
                    </Tab>
                </Tabs>
            </Content>
            <Divider/>
        </Container>
    );
};

export default Event;
