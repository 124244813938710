import React, {useEffect, useState} from "react";
import {Col, Container, Divider, Panel, Row,} from "rsuite";
import "react-multi-carousel/lib/styles.css";
import {Link} from "react-router-dom";
import "../../styles/newsfeed.css";
import {getAllAnnouncements} from "../../service/announcement.service";
import {CustomSpinner} from "../common/spinner";

const Card = ({img, ann_id, title}) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    return (

        <Panel className={'newsfeed-container'}>
            <Link className={"newsfeed-header"} to={`/news/${ann_id}`}>
                <div className={'image-cover'} style={{height: "180px",}}>
                    <img
                        alt={title}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        // className={'border-default'}
                        style={{borderWidth: "3px"}}
                        src={img}
                    />
                </div>

                {/*{isHovering && (*/}
                {/*    <p className={'default-color hover-news-feed'}>{title}</p>*/}
                    <p style={{marginTop:"12px"}} >{title}</p>
                {/*)}*/}
            </Link>
        </Panel>
    );
};

const NewsFeed = () => {
    // const [value, setValue] = useState(4);
    // const [index, setIndex] = useState(1);
    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // };


    const [resData, setResData] = useState([]);
    // const [count, setCount] = useState(0);
    // const [limit, setLimit] = React.useState(10);
    // const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false);

    const params = `type=NEWS&skip=0&take=8&sortField=ann_id&sortDirection=desc`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetchData()
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const response = await getAllAnnouncements(params);
        setResData(response.data);
        // setCount(response.count);
        setLoading(false);
    }


    if (loading) return <CustomSpinner/>;

    return (
        <Container>
            <div className="heading">
                <h2 className="default-color">LATEST NEWS</h2>
            </div>
            <Row>
                {
                    resData?.map(({img, ann_id, title}, index) => (
                        <Col key={index} lg={6} md={6} sm={12} xs={12}>
                            <Card img={img} ann_id={ann_id} title={title}/>
                        </Col>
                    ))
                }
            </Row>
            <h6
                className="default-color"
                style={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: "14pt",
                }}
            >
                <Link to={"/news"}>See More</Link>
            </h6>
            {/*<Divider style={{margin: '3px 0'}}>*/}
            {/*    <Link to={"/news"}>View More News</Link>*/}
            {/*</Divider>*/}
        </Container>
    );
};

export default NewsFeed;
