import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import ReactPlayer from 'react-player/lazy';
import RatingCarousel from "react-bootstrap/Carousel";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ReactHtmlParser from 'react-html-parser';
import {AiOutlineLink} from "react-icons/ai";
import {BsTelephone} from "react-icons/bs";
import {GoLocation} from "react-icons/go";
import GoogleMapReact from 'google-map-react';
import {HiOutlineLocationMarker, HiOutlineMail} from "react-icons/hi";
import {CustomSpinner} from "components/common/spinner";
import {Carousel, Col, Container, Grid, IconButton, Panel, Row,List} from "rsuite";
import {useLocation, useParams} from "react-router-dom";
import Config from "config.json";
import {getCompanyDetail} from "service/company.service";
import '../../styles/detailViews.css'
import {GrFacebook, GrInstagram, GrTwitter, GrYoutube} from "react-icons/gr";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Hidden from "@mui/material/Hidden";
import TextField from "@mui/material/TextField";
import {CommunityRating, YourReviews} from "../REVIEWS/reviews";
import {ReadMore} from "../../lib/utility";
// import {isBlank, isEmpty} from "@/lib/utility";
// import CustomSpinner from "@/components/common/spinner";



export default function DetailView() {
    const location = useLocation()
    let {com_id} = useParams();

    // console.log(com_id)
    // const com_id = location.state?.com_id;


    const GOOGLE_MAP_API_KEY = Config.GOOGLE_MAP_API_KEY;

    const [resData, setResData] = useState({});
    const [loading, setLoading] = useState(false)
    const baseUrl = Config.BASE_API_URL;


    useEffect(async () => {

        setLoading(true)

        const res = await getCompanyDetail(com_id)
        setResData(res.status ? res.data : {})
        setLoading(false)

    }, [])

    if (loading) return <CustomSpinner/>


    // console.log(resData);
    const handleChange = (name, value) => {
        setResData(prevState => ({
            ...prevState, [name]: value
        }));
    };


    const fetchData = async () => {
        return new Promise(resolve => setTimeout(resolve, 1000));
    }


    const CurrentPin = ({text}) => {
        return (<div>
            <HiOutlineLocationMarker color={'red'} size={28}/>
            <p style={{color: 'red'}}>{text}</p>
        </div>)
    }

    function createMapOptions(maps) {
        // next props are exposed at maps
        // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
        // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
        // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
        // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
        // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
        return {
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL
            },
            mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT
            },
            mapTypeControl: true
        };
    }


    return (

        <Container className='wrapper'>

            <h1 style={{color: 'red', textAlign: 'center'}}> {resData?.name}</h1>
            {/*<Divider/>*/}
            <Panel>

                <Grid fluid style={{marginLeft: 15, marginRight: 15}}>
                    <Row gutter={20}>


                        <Col lg={8} md={8} sm={24} xs={24} style={{backgroundColor: 'white'}}>
                            <Panel shaded>


                                {/* <h1>INFO</h1>*/}
                                <div>&nbsp;</div>

                                <div className={'panel-margin'}>
                                    {/*Logo*/}
                                    <div
                                        // className='image-cover'
                                        style={{
                                            width: 'fit-content',
                                            height: '150px',
                                            border: "2px solid #E91F20",
                                            borderRadius: "50%",
                                            overflow: 'hidden',
                                            margin: '0 auto'
                                        }}
                                    >
                                        <img
                                            src={`${resData.logo}`}
                                            alt="home"
                                            width={150}
                                        />
                                    </div>

                                    <div>&nbsp;</div>

                                    {/*Details*/}
                                    <Panel shaded className="text-start ">
                                        <div style={{marginBottom: "8px"}}>

                                            <div className="icon_div">
                                                <GoLocation/>
                                            </div>
                                            <div className="text_div">
                                                <span>{resData.address}</span>
                                            </div>
                                        </div>
                                        <div style={{marginBottom: "8px"}}>

                                            <div className="icon_div">
                                                <BsTelephone/>
                                            </div>
                                            <div className="text_div">
                                                <span>{resData.phone}</span>
                                            </div>
                                        </div>
                                        <div style={{marginBottom: "8px"}}>

                                            <div className="icon_div">
                                                <AiOutlineLink/>
                                            </div>
                                            <div className="text_div link-default">
                                                {resData.url ?

                                                    <a href={resData?.url} target='_blank'>{resData?.url}</a>

                                                    : ''}
                                            </div>
                                        </div>
                                        <div style={{marginBottom: "8px"}}>

                                            <div className="icon_div">
                                                <HiOutlineMail/>
                                            </div>
                                            <div className="text_div link-default">
                                                <a href={`mailto:${resData.email}`}>{resData?.email}</a>
                                            </div>
                                        </div>


                                        {
                                            resData.fbURL &&
                                            <IconButton target={'_blank'} href={resData.fbURL}
                                                        icon={<GrFacebook size={25}/>} appearance="subtle" circle/>
                                        }
                                        {
                                            resData.youtubeURL &&

                                            <IconButton target={'_blank'} href={resData.youtubeURL}
                                                        icon={<GrYoutube size={25}/>} appearance="subtle" circle/>
                                        }
                                        {
                                            resData.twitterURL &&

                                            <IconButton target={'_blank'} href={resData.twitterURL}
                                                        icon={<GrTwitter size={25}/>} appearance="subtle" circle/>
                                        }
                                        {
                                            resData.instaURL &&

                                            <IconButton target={'_blank'} href={resData.instaURL}
                                                        icon={<GrInstagram size={25}/>} appearance="subtle" circle/>
                                        }

                                    </Panel>

                                    {/* Google Map Block*/}
                                    <Panel shaded className={'map_panel'}>


                                        <div style={{height: '50vh', width: '100%'}}>
                                            <GoogleMapReact

                                                bootstrapURLKeys={{
                                                    'key': GOOGLE_MAP_API_KEY, language: 'en'
                                                }}
                                                // onClick={(e) => console.log(e.lat)}
                                                // center={{'lat': resData.lat, 'lng': resData.log}}
                                                center={[resData?.lat, resData?.log]}
                                                defaultCenter={[resData?.lat, resData?.log]}
                                                options={createMapOptions}
                                                // defaultZoom={11}
                                                zoom={14}
                                                layerTypes={['TrafficLayer', 'TransitLayer']}
                                            >

                                                <CurrentPin
                                                    lat={resData?.lat}
                                                    lng={resData?.log}
                                                    text={resData?.name}
                                                />

                                            </GoogleMapReact>
                                        </div>
                                    </Panel>
                                </div>

                            </Panel>
                        </Col>

                        <Col lg={16} md={16} sm={24} xs={24} style={{backgroundColor: 'white', paddingBottom: '20px'}}>

                            <Panel shaded>


                                <Carousel autoplay shape={'bar'}>
                                    {resData?.media?.images?.map((src, index) =>


                                        <img
                                            key={index}
                                            // src={`${src}?random=${index}`}
                                            src={`${src}`}
                                            alt="Company Picture"


                                        />)}
                                </Carousel>

                                <h3 style={{textAlign: 'left'}}>About</h3>
                                <Panel style={{textAlign: 'justify'}}>
                                    <ReadMore text={resData?.description} words={400}/>

                                </Panel>
                                <div className='mb-5 '>
                                    <h3 className="my-3"
                                        style={{textAlign: 'left'}}>Video</h3>

                                    <Carousel>
                                        {resData?.media?.videos.map((src, index) =>


                                            <div key={index} className=" player-wrapper">

                                                <ReactPlayer
                                                    className=' react-player'
                                                    width='100%'
                                                    // height='100%'
                                                    controls={true}
                                                    light
                                                    url={`${src}`}
                                                    // style={{position:'absolute',top:0,left:0}}
                                                />

                                            </div>)}
                                    </Carousel>


                                </div>

                            </Panel>

                        </Col>
                    </Row>

                </Grid>
                <br />
              <CommunityRating id={resData?.com_id} type={'COMPANY'} />
              <br />
              <YourReviews id={resData?.com_id} type={'COMPANY'} />
              <br />
            </Panel>

        </Container>


    )
}


DetailView.auth = true;
