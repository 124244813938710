import React, {useState} from "react";

import {Button, ButtonGroup, Col, Divider, Form, IconButton, Input, Message, Row, toaster, Uploader} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";
import {getCurrentUser} from "service/auth.service";
import Avatar from "@rsuite/icons/legacy/Avatar";
import ReactQuill from "react-quill";
import {formats, modules} from "components/profile/company/reactQuilConfig";
import Config from "../../../../config.json";
import {uploadFileTOS3Bucket} from "lib/utility";
import {generateS3BucketUrl} from "service/s3";


function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result);
    };
    reader.readAsDataURL(file);
}

const styles = {
    width: 150,
    height: 150
};


export const Speakers = ({value = [], onChange, fieldError}) => {
    const errors = fieldError ? fieldError.array : [];
    const [speakers, setSpeakers] = useState(value);

    const handleChangeSpeaker = nextSpeaker => {
        setSpeakers(nextSpeaker);
        onChange(nextSpeaker);
    };

    // console.log(speakers)
    const handleInputChange = (rowIndex, value) => {
        const nextSpeaker = [...speakers];
        nextSpeaker[rowIndex] = value;
        handleChangeSpeaker(nextSpeaker);
    };

    const handleMinus = () => {
        handleChangeSpeaker(speakers.slice(0, -1));
    };
    const handleAdd = () => {
        handleChangeSpeaker(speakers.concat([{
            name: '',
            designation: '',
            description: '',
            email: '',
            fbURL: '',
            instaURL: '',
            phone: '',
            twitterURL: '',
            youtubeURL: '',
            profileImage: ''
        }]));
    };
    return (
        <table style={{width: 'inherit'}}>
            {/*            <thead>
            <tr>
                <Cell>Name</Cell>
                <Cell>Designation</Cell>
                <Cell>Description</Cell>
                <Cell>URL</Cell>
            </tr>
            </thead>*/}
            <tbody>
            {speakers.map((rowValue, index) => (
                <SpeakersDetail
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index].object : null}
                    onChange={handleInputChange}
                />
            ))}
            </tbody>
            <tfoot>
            <tr>
                <Cell colSpan={2} style={{paddingTop: 10}}>
                    <ButtonGroup size="xs">
                        <IconButton onClick={handleAdd} icon={<PlusIcon/>}/>
                        <IconButton disabled={value.length === 1} onClick={handleMinus} icon={<MinusIcon/>}/>
                    </ButtonGroup>
                </Cell>
            </tr>
            </tfoot>
        </table>
    );
};

const SpeakersDetail = ({rowValue = {}, onChange, rowIndex, rowError}) => {
    const [fileInfo, setFileInfo] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [user, setUser] = useState(getCurrentUser());
    const [isLogoUploaded, setIsLogoUploaded] = useState(rowValue.profileImage !== undefined);

    const handleChangeProfile = value => {
        onChange(rowIndex, {...rowValue, profileImage: value});
    };

    const handleChangeName = value => {
        onChange(rowIndex, {...rowValue, name: value});
    };
    const handleChangeDesignation = value => {
        onChange(rowIndex, {...rowValue, designation: value});
    };
    const handleChangeEmail = value => {
        onChange(rowIndex, {...rowValue, email: value});
    };
    const handleChangeURL = value => {
        onChange(rowIndex, {...rowValue, phone: value});
    };
    const handleChangeDescription = value => {
        onChange(rowIndex, {...rowValue, description: value});
    };
    const handleChangeFbURL = value => {
        onChange(rowIndex, {...rowValue, fbURL: value});
    };
    const handleChangeInstaURL = value => {
        onChange(rowIndex, {...rowValue, instaURL: value});
    };
    const handleChangeTwitterURl = value => {
        onChange(rowIndex, {...rowValue, twitterURL: value});
    };
    const handleChangeYoutubeURL = value => {
        onChange(rowIndex, {...rowValue, youtubeURL: value});
    };

    const baseUrl = Config.BASE_API_URL;


    return (
        <tr>
            <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
            <h6 style={{marginBottom: '5px'}}> Speaker {rowIndex + 1}</h6>
            <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.ControlLabel>Speaker's Profile Picture </Form.ControlLabel>
                    <Uploader
                        fileListVisible={false}
                        listType="picture"
                        accept={'image/*'}
                        action=""
                        autoUpload={false}
                        onChange={async (file) => {
                            setIsLogoUploaded(false);
                            const logo_file = file.pop()
                            setLogoFile(logo_file);
                            previewFile(logo_file.blobFile, value => setFileInfo(value))
                        }}
                    >
                        <button style={styles}>

                            {fileInfo ?
                                (<img src={fileInfo} width="100%" height="100%" alt='...'/>)
                                : rowValue.profileImage ?
                                    (<img src={rowValue.profileImage} width="70%" alt='...'/>)
                                    :
                                    (<Avatar style={{fontSize: 80}}/>)
                            }
                        </button>
                    </Uploader>
                    {/*<br/>*/}
                    <Button
                        disabled={isLogoUploaded || !logoFile}
                        loading={isLogoLoading}
                        style={{marginTop: 10, width: 'auto', display: 'block'}}
                        className={'d-w-r-btn'}
                        onClick={async () => {

                            setIsLogoLoading(true);

                            const responseGenerate = await generateS3BucketUrl(1, user.access_token);

                            if (responseGenerate.status) {
                                const url = responseGenerate.data[0]
                                const response = await uploadFileTOS3Bucket(logoFile, url);

                                if (response.status) {
                                    const url = response.data
                                    console.log(url);
                                    handleChangeProfile(url);
                                    toaster.push(<Message type="success">{'Successfully uploaded'}</Message>);
                                } else {
                                    toaster.push(<Message
                                        type="error">{'Unable To Upload: Please upload different file'}</Message>);
                                }
                                setIsLogoUploaded(response.status);
                            } else {
                                setIsLogoUploaded(false);
                                toaster.push(<Message
                                    type="error">{'Unable To Upload: Please upload different file'}</Message>);
                            }

                            setIsLogoLoading(false);
                        }}

                    >
                        Start Upload
                    </Button>


                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={6} lg={6}>
                    <p>Person Name</p>
                    <Input value={rowValue.name} onChange={handleChangeName}/>
                    {rowError ? <ErrorMessage>{rowError.name.errorMessage}</ErrorMessage> : null}
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                    <p>Designation</p>
                    <Input value={rowValue.designation} onChange={handleChangeDesignation}/>
                    {rowError ? <ErrorMessage>{rowError.designation.errorMessage}</ErrorMessage> : null}
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                    <p>Email</p>
                    <Input value={rowValue.email} onChange={handleChangeEmail}/>
                    {rowError ? <ErrorMessage>{rowError.email.errorMessage}</ErrorMessage> : null}
                </Col>

            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>

                    <ReactQuill
                        theme="snow"
                        value={rowValue?.description}
                        onChange={handleChangeDescription}
                        modules={modules}
                        formats={formats}
                        style={{marginRight: 25}}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={6} lg={6}>
                    <p>Facebook social Account</p>
                    <Input value={rowValue.fbURL} onChange={handleChangeFbURL}/>
                    {rowError ? <ErrorMessage>{rowError.fbURL.errorMessage}</ErrorMessage> : null}
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                    <p>Instagram's Social Account</p>
                    <Input value={rowValue.instaURL} onChange={handleChangeInstaURL}/>
                    {rowError ? <ErrorMessage>{rowError.instaURL.errorMessage}</ErrorMessage> : null}
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                    <p>Twitters's Social Account</p>
                    <Input value={rowValue.twitterURL} onChange={handleChangeTwitterURl}/>
                    {rowError ? <ErrorMessage>{rowError.twitterURL.errorMessage}</ErrorMessage> : null}
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                    <p>Youtube's Social Account</p>

                    <Input value={rowValue.youtubeURL} onChange={handleChangeYoutubeURL}/>
                    {rowError ? <ErrorMessage>{rowError.youtubeURL.errorMessage}</ErrorMessage> : null}
                </Col>
            </Row>

        </tr>

    );
};
const ErrorMessage = ({children}) => <span style={{color: 'red'}}>{children}</span>;
const Cell = ({children, style, ...rest}) => (
    <td style={{padding: '2px 4px 2px 0', verticalAlign: 'top', ...style}} {...rest}>
        {children}
    </td>
);
