import Config from "../config.json";
import {convertMediaToArray, fetcher} from "lib/utility";


import useSWR from 'swr'

const baseUrl = Config.BASE_API_URL;
const getCompaniesUrl = baseUrl + Config.GET_COMPANIES_URL
const createCompanyUrl = baseUrl + Config.CREATE_COMPANY_URL
const updateCompanyUrl = baseUrl + Config.UPDATE_COMPANY_URL
const getCompanyTypeUrl = baseUrl + Config.GET_COMPANY_TYPE_URL


export async function getCompanies(url, token) {

    const response = await fetch(url, {
        method: 'GET',
        headers:  getHeader(token)
    })

    return await response.json();
}

/*export function useGetAllCompanies (url) {
    const { data, error } = useSWR(url, fetcher)

    return {
        data: data,
        isLoading: !error && !data,
        isError: error
    }
}*/
export async function getAllCompanies(url) {

    const response = await fetch(url)

    return await response.json();
}

export async function getCompany(com_id, token) {
    const url = getCompaniesUrl + '/' + com_id;
    const response = await fetch(url, {
        method: 'GET',
        headers:  getHeader(token)
    })

    return await response.json();
}
export async function getCompanyDetail(com_id) {
    const url = getCompaniesUrl + '/' + com_id;
    const response = await fetch(url)

    let tempData=await response.json();
    tempData.data.media =  convertMediaToArray(tempData.data.company_mediadoc)

    return  tempData



    // return await response.json();
}

export async function getCompanyType(token='') {

    const response = await fetch(getCompanyTypeUrl, {
        method: 'GET',
        headers:  getHeader(token)
    })

    const responseData = await response.json();
    if (!responseData.status)
        return []

    return  responseData.data.map(item => {return {value: item.cot_id, label: item.name}})

}
export async function getCompanyTypeConstants(token='') {

    const response = await fetch(getCompanyTypeUrl, {
        method: 'GET',
        headers:  getHeader(token)
    })

    let constants = {}
    const responseData = await response.json();
    if (!responseData.status)
        return constants

    for (const d of responseData) {
        constants[d.name] = d.cot_id
    }

    return constants

}

export async function createCompany(payload,token) {

    const response = await fetch(createCompanyUrl, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader(token)
    })

    return  await response.json();
}

export async function updateCompany(payload,token) {

    const response = await fetch(updateCompanyUrl, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader(token)
    })

    return  await response.json();
}


function getHeader(token = '') {
    return {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

function getMultipartHeader(token = '') {
    return {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
    }
}


export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
