import {LoginSocialGoogle,} from 'reactjs-social-login'

// CUSTOMIZE ANY UI BUTTON
import {GoogleLoginButton} from 'react-social-login-buttons'
import {useCallback, useRef} from "react";

import Config from "../../config";
import {toaster} from "rsuite";
import {register} from "../../service/auth.service";
import {NotificationCustom} from "../common/notification";
import {useNavigate} from "react-router-dom";


export const SocialLogin = ({setIsLoading, type}) => {

    const navigate = useNavigate();
    // const amazonRef = useRef();
    // const instagramRef = useRef();
    const googleRef = useRef();
    // const facebookRef = useRef();
    // const microsoftRef = useRef();
    // const linkedinRef = useRef();
    // const githubRef = useRef();
    // const pinterestRef = useRef();
    // const twitterRef = useRef();

    const onLoginStart = useCallback(() => {
        // alert("login start");
        setIsLoading(true)
    }, []);

    const onLogoutFailure = useCallback(() => {
        alert("logout fail");
    }, []);

    const onLogoutSuccess = useCallback(() => {
        alert("logout success");
    }, []);


    const handleCreate = async (payload) => {
        toaster.clear()
        const res = await register(payload, true)
        if (res.status) {
            toaster.push(<NotificationCustom type="success" placement={'topEnd'} header='Success'>Account Created</NotificationCustom>);
            navigate('/login');
        } else {
            toaster.push(<NotificationCustom type="error" placement={'topEnd'} header='Error'> {res.message}</NotificationCustom>);
        }
        setIsLoading(false)
    };

    return (

        <div className={'App'}>
            <h1 className='title'>OR</h1>
            {/*<LoginSocialFacebook*/}
            {/*    scope={'profile email'}*/}
            {/*    ref={facebookRef}*/}
            {/*    appId={Config.REACT_APP_FB_APP_ID}*/}
            {/*    onLoginStart={onLoginStart}*/}
            {/*    onLogoutSuccess={onLogoutSuccess}*/}
            {/*    onResolve={({ provider, data }) => {*/}
            {/*        setIsSocial(true)*/}
            {/*        setSocialFormValue({*/}
            {/*            firstName: data.given_name,*/}
            {/*            lastName: data.family_name,*/}
            {/*            email: data.email,*/}
            {/*            image: data.picture,*/}
            {/*            isSocial: true*/}
            {/*        })*/}
            {/*    }}*/}
            {/*    onReject={(err) => {*/}
            {/*        console.log(err);*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <FacebookLoginButton />*/}
            {/*</LoginSocialFacebook>*/}

            <LoginSocialGoogle
                scope={'profile email'}
                ref={googleRef}
                client_id={Config.REACT_APP_GG_APP_ID}
                onLogoutFailure={onLogoutFailure}
                onLoginStart={onLoginStart}
                redirect_uri={Config.REDIRECT_URI}
                onLogoutSuccess={onLogoutSuccess}
                onResolve={async ({provider, data}) => {

                    const payload = {
                        firstName: data.given_name,
                        lastName: data.family_name,
                        email: data.email,
                        image: data.picture,
                        isSocial: true,
                        type: type,
                    }
                    await handleCreate(payload)

                }}
                onReject={(err) => {
                    console.log("hbhbdhd", err);
                }}
            >
                <GoogleLoginButton>
                    <span>Register With Google</span>
                </GoogleLoginButton>
            </LoginSocialGoogle>


            {/*<LoginSocialAmazon*/}
            {/*    client_id={process.env.REACT_APP_AMAZON_APP_ID || ''}*/}
            {/*    redirect_uri={REDIRECT_URI}*/}
            {/*    onResolve={({ provider, data }) => {*/}
            {/*        setProvider(provider)*/}
            {/*        setProfile(data)*/}
            {/*    }}*/}
            {/*    onReject={(err: any) => {*/}
            {/*        console.log(err)*/}
            {/*    }}*/}
            {/*    onLoginStart={onLoginStart}*/}
            {/*>*/}
            {/*    <AmazonLoginButton />*/}
            {/*</LoginSocialAmazon>*/}

            {/*<LoginSocialInstagram*/}
            {/*    client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || ''}*/}
            {/*    client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || ''}*/}
            {/*    redirect_uri={REDIRECT_URI}*/}
            {/*    onLoginStart={onLoginStart}*/}
            {/*    onResolve={({ provider, data }) => {*/}
            {/*        setProvider(provider)*/}
            {/*        setProfile(data)*/}
            {/*    }}*/}
            {/*    onReject={(err: any) => {*/}
            {/*        console.log(err)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <InstagramLoginButton />*/}
            {/*</LoginSocialInstagram>*/}

            {/*<LoginSocialMicrosoft*/}
            {/*    client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ''}*/}
            {/*    redirect_uri={REDIRECT_URI}*/}
            {/*    onLoginStart={onLoginStart}*/}
            {/*    onResolve={({ provider, data }) => {*/}
            {/*        setProvider(provider)*/}
            {/*        setProfile(data)*/}
            {/*    }}*/}
            {/*    onReject={(err: any) => {*/}
            {/*        console.log(err)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <MicrosoftLoginButton />*/}
            {/*</LoginSocialMicrosoft>*/}

            {/*<LoginSocialLinkedin*/}
            {/*    client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}*/}
            {/*    client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}*/}
            {/*    redirect_uri={REDIRECT_URI}*/}
            {/*    onLoginStart={onLoginStart}*/}
            {/*    onResolve={({ provider, data }) => {*/}
            {/*        setProvider(provider)*/}
            {/*        setProfile(data)*/}
            {/*    }}*/}
            {/*    onReject={(err: any) => {*/}
            {/*        console.log(err)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <LinkedInLoginButton />*/}
            {/*</LoginSocialLinkedin>*/}

            {/*<LoginSocialGithub*/}
            {/*    client_id={process.env.REACT_APP_GITHUB_APP_ID || ''}*/}
            {/*    client_secret={process.env.REACT_APP_GITHUB_APP_SECRET || ''}*/}
            {/*    redirect_uri={REDIRECT_URI}*/}
            {/*    onLoginStart={onLoginStart}*/}
            {/*    onResolve={({ provider, data }) => {*/}
            {/*        setProvider(provider)*/}
            {/*        setProfile(data)*/}
            {/*    }}*/}
            {/*    onReject={(err: any) => {*/}
            {/*        console.log(err)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <GithubLoginButton />*/}
            {/*</LoginSocialGithub>*/}
            {/*<LoginSocialPinterest*/}
            {/*    client_id={process.env.REACT_APP_PINTEREST_APP_ID || ''}*/}
            {/*    client_secret={process.env.REACT_APP_PINTEREST_APP_SECRET || ''}*/}
            {/*    redirect_uri={REDIRECT_URI}*/}
            {/*    onLoginStart={onLoginStart}*/}
            {/*    onResolve={({ provider, data }) => {*/}
            {/*        setProvider(provider)*/}
            {/*        setProfile(data)*/}
            {/*    }}*/}
            {/*    onReject={(err: any) => {*/}
            {/*        console.log(err)*/}
            {/*    }}*/}
            {/*    className='pinterest-btn'*/}
            {/*>*/}
            {/*    <div className='content'>*/}
            {/*        <div className='icon'>*/}
            {/*            <PinterestLogo />*/}
            {/*        </div>*/}
            {/*        <span className='txt'>Login With Pinterest</span>*/}
            {/*    </div>*/}
            {/*</LoginSocialPinterest>*/}

            {/*<LoginSocialTwitter*/}
            {/*    client_id={process.env.REACT_APP_TWITTER_API_KEY || ''}*/}
            {/*    client_secret={process.env.REACT_APP_TWITTER_APP_SECRET || ''}*/}
            {/*    redirect_uri={REDIRECT_URI}*/}
            {/*    onLoginStart={onLoginStart}*/}
            {/*    onResolve={({ provider, data }) => {*/}
            {/*        setProvider(provider)*/}
            {/*        setProfile(data)*/}
            {/*    }}*/}
            {/*    onReject={(err: any) => {*/}
            {/*        console.log(err)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <TwitterLoginButton />*/}
            {/*</LoginSocialTwitter>*/}
        </div>
    )
}

