import React, {useState} from 'react';
import {Content, Divider, Grid, Nav} from "rsuite";
import 'react-quill/dist/quill.snow.css';
import 'styles/profile/company/createItem.css'
import 'react-phone-number-input/style.css'
import CreateItem from "components/profile/company/createItem";
import 'styles/profile/company/handler.css'
import CompanyJob from "components/profile/company/CompanyJobEventsCourses/companyJob";
import CompanyEvent from "components/profile/company/CompanyJobEventsCourses/companyEvent";
import CompanyCourse from "components/profile/company/CompanyJobEventsCourses/companyCourse";
import {getCurrentUser} from "service/auth.service";
import {useLocation, useParams} from "react-router-dom";


const Handler = ({editOrNew = false, com_id}) => {

    const [handlerNav, setHandlerNav] = useState(editOrNew ? 'companyProfileEdit' : 'addNew');
    const [user,setUser] = useState(getCurrentUser());
    const [company,setCompany] = useState(user.company ? user.company.filter(company=>company.com_id===com_id)[0] : {});


    function SwitchCase({value}) {
        switch (value) {
            case 'companyProfileEdit':
                return <CreateItem editOrNew={editOrNew} com_id={com_id}/>;
            case 'addNew':
                return <CreateItem/>;
            case 'addEvent':
                return <CompanyEvent com_id={com_id}/>;
            case 'addJob':
                return <CompanyJob com_id={com_id}/>;
            case 'addCourses':
                return <CompanyCourse com_id={com_id}/>;
            default:
                return <CreateItem editOrNew={editOrNew} com_id={com_id}/>;
        }
    }


    return (
        <Content>
            {!company?.isActive && <p style={{color:'red', fontSize:'10px'}}> You can only add Event, Job and Courses When Company is Approved</p>}
            <Grid fluid className='create-item-form' style={{marginLeft: 15}}>
                {
                    editOrNew &&
                    <>
                        <Nav justified className='handler-nav' onSelect={setHandlerNav} activeKey={handlerNav}>
                            <Nav.Item eventKey='companyProfileEdit'>
                                Company Profile Edit
                            </Nav.Item>
                            <Nav.Item disabled={!company?.isActive} eventKey='addEvent'>Add Event</Nav.Item>
                            <Nav.Item disabled={!company?.isActive} eventKey='addJob'>Add Job</Nav.Item>
                            <Nav.Item disabled={!company?.isActive} eventKey='addCourses'>Add Courses</Nav.Item>
                        </Nav>
                        <Divider style={{backgroundColor: '#0C3144', margin: '12px'}}/>
                    </>
                }

                <SwitchCase value={handlerNav}/>
            </Grid>
        </Content>

    );
};

export default Handler;