import React from "react";
import {Container, Content, Header} from "rsuite";
import {SearchComponent} from "components/body/searchComponent";
import {EventsHomePage} from "components/body/eventsHomePage";
import "../styles/body.css";
import {TopUniversities} from "components/body/topUniversities";
import JobsFeed from "components/body/jobsfeed";
import BlogsFeed from "components/body/blogsfeed";
import NewsFeed from "components/body/newsfeed";
import Config from "../config.json";
import {Categories} from "../components/body/categories";

export default function Home() {
    return (
        <Container className="wrapper">
            <div>&nbsp;</div>
            <Header>
                <p className="to-uppercase " style={{padding: "0px 0px 5px 0px"}}>
                    YOUR #1 EDUCATION GUIDE
                </p>
                <h2 className="default-color to-uppercase">
                    CONNECT WITH
                    <span
                        style={{
                            color: "white",
                            backgroundColor: "#EA1F24",
                            padding: "0 10px",
                            borderRadius: "10px",
                        }}
                    >
            TOP EDUCATORS
          </span>
                </h2>
                <div>&nbsp;</div>
                <SearchComponent/>
                <div>&nbsp;</div>
            </Header>
            <Content>
                <div className="py-50">
                    <EventsHomePage/>
                </div>
                <div className="py-50">
                    <Categories/>
                </div>
                {/*<div className='py-50'>*/}
                {/*    <JobsHomePage/>*/}
                {/*</div>*/}
                {/* Jobs Feed Component */}
                <div>
                    <JobsFeed/>
                </div>

                {/* Jobs Feed Component */}
                <TopUniversities
                    type={Config.COMPANY_TYPE.University}
                    name={"Universities"}
                />
                <TopUniversities type={Config.COMPANY_TYPE.Schools} name={"Schools"}/>

                <div className="py-50">
                    <NewsFeed/>
                </div>
                <div className="py-50">
                    <BlogsFeed/>
                </div>
            </Content>
        </Container>
    );
}
