 export  const EndingNote = () => {
    return (

        <div style={{backgroundColor: 'white', textAlign: 'center'}}>
            <p style={{fontSize: '1rem'}}>
               End Of List
            </p>
        </div>
    )
}

export const NoDataFoundNote = () => {
    return (

        <div style={{backgroundColor: 'white', textAlign: 'center'}}>
            <p style={{fontSize: '1rem'}}>
              No Records Found
            </p>
        </div>
    )
}

