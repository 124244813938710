import React from "react";
import {ButtonGroup, Checkbox, Col, DatePicker, Divider, IconButton, Input, Row} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";


export const Education = ({value = [], onChange, fieldError}) => {
    const errors = fieldError ? fieldError.array : [];
    const [education, setEducation] = React.useState(value);
    const handleChangeSpeaker = nextEducation => {
        setEducation(nextEducation);
        onChange(nextEducation);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextEducation = [...education];
        nextEducation[rowIndex] = value;
        handleChangeSpeaker(nextEducation);
    };

    const handleMinus = () => {
        handleChangeSpeaker(education.slice(0, -1));
    };
    const handleAdd = () => {
        handleChangeSpeaker(education.concat([
            {
                instituteName: '',
                fieldOfStudy: '',
                grade: '',
                fromDate: new Date(),
                toDate: new Date(),
                isCompleted: false,
                description: '',
                degree: '',
            }]));
    };
    return (
        <table style={{width: 'inherit'}}>

            <tbody>
            {education.map((rowValue, index) => (
                <EducationDetail
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index].object : null}
                    onChange={handleInputChange}
                />
            ))}
            </tbody>
            <tfoot>
            <tr>
                <Cell colSpan={2} style={{paddingTop: 10}}>
                    <ButtonGroup size="xs">
                        <IconButton onClick={handleAdd} icon={<PlusIcon/>}/>
                        <IconButton disabled={value.length === 1} onClick={handleMinus} icon={<MinusIcon/>}/>
                    </ButtonGroup>
                </Cell>
            </tr>
            </tfoot>
        </table>
    );
};

const EducationDetail = ({rowValue = {}, onChange, rowIndex, rowError}) => {
    const handleChangeInstituteName = value => {
        onChange(rowIndex, {...rowValue, instituteName: value});
    };
    const handleChangeFieldOfStudy = value => {
        onChange(rowIndex, {...rowValue, fieldOfStudy: value});
    };
    const handleChangeGrade = value => {
        onChange(rowIndex, {...rowValue, grade: value});
    };
    const handleChangeFromDate = value => {
        onChange(rowIndex, {...rowValue, fromDate: value});
    };
    const handleChangetoDate = value => {
        onChange(rowIndex, {...rowValue, toDate: value});
    };
    const handleIsCompleted = (value, data) => {
        onChange(rowIndex, {...rowValue, isCompleted: data});
        // console.log(data);
    };
    const handleChangeDescription = value => {
        onChange(rowIndex, {...rowValue, description: value});
    };
    const handleChangeDegree = value => {
        onChange(rowIndex, {...rowValue, degree: value});
    };

    return (
        <tr>
            <>
                <Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <h6 style={{marginBottom: '5px'}}>Education</h6>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>Institute Name</p>
                        <Input value={rowValue.instituteName} onChange={handleChangeInstituteName}/>
                        {rowError ? <ErrorMessage>{rowError.instituteName.errorMessage}</ErrorMessage> : null}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>Field Of Study</p>
                        <Input value={rowValue.fieldOfStudy} onChange={handleChangeFieldOfStudy}/>
                        {rowError ? <ErrorMessage>{rowError.fieldOfStudy.errorMessage}</ErrorMessage> : null}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>Degree Name</p>
                        <Input value={rowValue.degree} onChange={handleChangeDegree}/>
                        {rowError ? <ErrorMessage>{rowError.degree.errorMessage}</ErrorMessage> : null}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>Grade</p>
                        <Input value={rowValue.grade} onChange={handleChangeGrade}/>
                        {rowError ? <ErrorMessage>{rowError.grade.errorMessage}</ErrorMessage> : null}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>From Date</p>
                        <DatePicker oneTap value={new Date(rowValue.fromDate)} onChange={handleChangeFromDate}/>
                        {/*{rowError ? <ErrorMessage>{rowError.fromDate.errorMessage}</ErrorMessage> : null}*/}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>End Date</p>
                        <DatePicker oneTap value={new Date(rowValue.toDate)} onChange={handleChangetoDate}/>
                        {/*{rowError ? <ErrorMessage>{rowError.toDate.errorMessage}</ErrorMessage> : null}*/}
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <p>Description</p>

                        <Input as="textarea" rows={3} placeholder="Description Here" value={rowValue.description}
                               onChange={handleChangeDescription}/>
                        {rowError ? <ErrorMessage>{rowError.description.errorMessage}</ErrorMessage> : null}

                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <Checkbox checked={rowValue.isCompleted} onChange={handleIsCompleted}> Is Completed</Checkbox>
                        {/*{rowError ? <ErrorMessage>{rowError.isCompleted.errorMessage}</ErrorMessage> : null}*/}
                    </Col>

                </Row>
            </>

        </tr>

    );
};
const ErrorMessage = ({children}) => <span style={{color: 'red'}}>{children}</span>;
const Cell = ({children, style, ...rest}) => (
    <td style={{padding: '2px 4px 2px 0', verticalAlign: 'top', ...style}} {...rest}>
        {children}
    </td>
);
