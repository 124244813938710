import {useContext} from "react";
import {AppContext} from "lib/contextLib";
import {Navigate} from 'react-router-dom';

export function Logout() {

    const {user,setUser} = useContext(AppContext);
    // console.log(user);
    localStorage.removeItem("user");
    setUser(null)
    // window.location.reload();

    return (
        <Navigate  push to="/"/>
    )
}