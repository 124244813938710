import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import {CustomSpinner} from "components/common/spinner";
import {CompanyColumn} from "lib/dtc/companyListing.column";
import Config from "config.json";
import {getCurrentUser} from "service/auth.service";
import {getCompanies} from "service/company.service";


function CompanyList({setValueNav, setCom_id, setEditOrNew}) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sortField, setSortField] = useState('com_id')
    const [sortDirection, setSortDirection] = useState('desc')
    const [searchText, setSearchText] = useState("")
    const [editComId, setEditComId] = useState(null);
    const [user,setUser] = useState(getCurrentUser())

    const baseUrl = Config.BASE_API_URL;
    const getCompaniesUrl = baseUrl + Config.GET_COMPANIES_URL



    useEffect(async () => {

        setLoading(true)
        await fetchData()
        setLoading(false)

    }, [perPage, page, sortField, sortDirection, searchText])



    const fetchData = async () => {
        const params = `type=profile&skip=${page * perPage}&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&searchText=${searchText}`
        const url = `${getCompaniesUrl}?${params}`
        setLoading(true);

        const response = await getCompanies(url,user.access_token)

        setData(response?.status ? response?.data?.company : []);
        setTotalRows(response?.status ? response?.data?.count : 0);
        setLoading(false);
    }


    const handleEdit = (com_id) => {
        setCom_id(com_id)
        setEditOrNew(true)
    }
    const handlePerRowsChange = async (newPerPage, page_) => {
        setPerPage(newPerPage);
        setPage(page_ - 1)
    };

    const handlePageChange = (page_) => {
        setPage(page_ - 1);
    };

    const handleSort = (column, sortDirection) => {
        setSortDirection(sortDirection);
        setSortField(column.sortField);
    };

    const columns = CompanyColumn(handleEdit, setValueNav)

    return (
        <div className='company-list-wrapper'>
            <DataTable
                // title="Products"
                keyField={'com_id'}
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions={[10, 30, 50, 100, 200]}
                paginationComponentOptions={{selectAllRowsItem: true, selectAllRowsItemText: 'All'}}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                striped={true}
                fixedHeader
                fixedHeaderScrollHeight={'70vh'}
                responsive
                persistTableHead

                progressComponent={<CustomSpinner/>}

            />
        </div>
    );
}

export default CompanyList;