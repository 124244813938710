import Config from "../config.json";

const baseUrl = Config.BASE_API_URL;
const getUrl = baseUrl + Config.GET_TUTOR_URL
const updateUrl = baseUrl + Config.UPDATE_TUTOR_URL



export async function getTutor(tou_id, token) {

    const response = await fetch(getUrl + '/' + tou_id , {
        method: 'GET',
        headers: getHeader(token)
    })
    let response_josn = await response.json()
    if (response_josn.status) {
        const cit_id = response_josn?.data?.city?.cit_id
        const cou_id = response_josn?.data?.city?.country?.cou_id
        response_josn.data.city = cit_id
        response_josn.data.country = cou_id

        return response_josn;
    } else {
        return response_josn;
    }
}

export async function updateTutor(payload, token) {

    const response = await fetch(updateUrl, {
        body: JSON.stringify(payload),
        method: 'PUT',
        headers: getHeader(token)
    })

    let response_josn = await response.json()
    if (response_josn.status) {
        const data = response_josn.data
        const cit_id = response_josn?.data?.city?.cit_id
        const cou_id = response_josn?.data?.city?.country?.cou_id
        response_josn.data.city = cit_id
        response_josn.data.country = cou_id

        return response_josn;
    } else {
        return response_josn;
    }

}



function getHeader(token = '') {
    return {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
