import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Content,
  Divider,
  Header,
  List,
  Panel,
  Stack,
} from "rsuite";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import EmailFillIcon from "@rsuite/icons/EmailFill";
import { MdLocationPin } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { SearchCompanyComponent } from "components/searchCompany";
import InfiniteScroll from "react-infinite-scroll-component";
import { CustomSmallSpinner } from "components/common/spinner";
import { EndingNote, NoDataFoundNote } from "components/common/listDataEnd";
import Config from "config.json";
import { getAllCompanies } from "service/company.service";
import { useStateWithDeps } from "use-state-with-deps";
// import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Col, Row, Tab, Tabs, Image } from "react-bootstrap";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { experimentalStyled as styled } from "@mui/material/styles";
// import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";

const University = ({ type, name }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const location = useLocation();
  const [key, setKey] = useState("list");
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setKey("grid");
    // setKey('list')
  };
  console.log(location.state?.searchData);

  const [searchData, setSearchData] = useState(location.state?.searchData);
  const [totalRecords, setTotalRecord] = useStateWithDeps(0, [type]);
  const [resData, setResData] = useStateWithDeps([], [type]);
  const [hasMore, setHasMore] = useStateWithDeps(true, [type]);
  const [loading, setLoading] = useStateWithDeps(false, [type]);
  const [perPage, setPerPage] = useStateWithDeps(10, [type]);
  const [page, setPage] = useStateWithDeps(0, [type]);
  const [sortField, setSortField] = useState("com_id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchText, setSearchText] = useState("");

  // console.log(searchData);
  const baseUrl = Config.BASE_API_URL;
  const getCompaniesUrl = baseUrl + Config.GET_COMPANIES_URL;

  type = searchData?.type ? searchData.type : type;
  const text = searchData?.text ? searchData?.text : "";
  const city = searchData?.city;
  const searchParam = `cit_id=${city}&searchText=${text}&cot_id=${type}`;
  const params = `skip=${
    page * perPage
  }&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}`;
  const url = `${getCompaniesUrl}?${params}&${searchParam}`;

  useEffect(async () => {
    setLoading(true);
    const params = `skip=0&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}`;
    const url = `${getCompaniesUrl}?${params}&${searchParam}`;
    const response = await getAllCompanies(url);
    setResData(response?.status ? response?.data?.company : []);
    setTotalRecord(response?.status ? response?.data?.count : 0);
    setHasMore(response?.data.count > 0);

    setPage(1);
    setLoading(false);
  }, [type]);

  const fetchData = async () => {
    if (resData.length >= totalRecords) {
      setHasMore(false);
      return;
    }
    setPage((prevState) => prevState + 1);
    const response = await getAllCompanies(url);
    setResData((prevState) =>
      prevState.concat(response?.status ? response?.data?.company : [])
    );
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const tabCount = 3;

  return (
    <Container className="wrapper">
      <Divider style={{ margin: "12px 0" }} />
      <Header>
        <h2 className="default-color to-uppercase">{name}</h2>
      </Header>
      <Divider style={{ margin: "12px 0" }} />
      <SearchCompanyComponent
        searchDataFromHome={
          location.state
            ? location.state.searchData
            : {
                text: "",
                type: 0,
                country: 0,
                city: 0,
              }
        }
      />
      <Divider />
      <Content>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab
            eventKey="list"
            title={
              <>
                <h4 style={{ color: "#EA1F24" }}>List View</h4>
              </>
            }
          >
            <List bordered size={"xs"}>
              <InfiniteScroll
                dataLength={resData.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<CustomSmallSpinner />}
                endMessage={<EndingNote />}
              >
                <Hidden only={["xs", "sm"]}>
                  {resData?.map((d, index) => (
                    <>
                      <List.Item key={index}>
                        <Panel
                          header={
                            <Stack
                              spacing={20}
                              className="stack-last-item-right"
                            >
                              <Link to={`/company/detail/${d.com_id}`}>
                                <img
                                  size="lg"
                                  className="list-logo"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    background: "transparent",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                  src={`${d.logo}`}
                                  alt={d.name}
                                />
                              </Link>
                              <Link to={`/company/detail/${d.com_id}`}>
                                <h5>
                                  {d?.name}{" "}
                                  <small style={{ fontSize: "13px" }}>
                                    ({d.companytype.name})
                                  </small>
                                </h5>
                                <h6>
                                  {d.city.name}, {d.city.country.name}
                                </h6>
                              </Link>

                              <div style={{ display: "flex" }}>
                                <Button
                                  as={Link}
                                  to={`/company/detail/${d.com_id}`}
                                  // state={{com_id: d.com_id}}
                                  className="d-w-r-btn"
                                  style={{ color: "white" }}
                                >
                                  {" "}
                                  See Detail
                                </Button>
                              </div>
                            </Stack>
                          }
                        >
                          <div className="text-start ">
                            <div style={{ marginBottom: "8px" }}>
                              <div className="icon_div">
                                <EmailFillIcon />
                              </div>
                              <div className="text_div">
                                <span>{d.email}</span>
                              </div>
                            </div>
                            <div style={{ marginBottom: "8px" }}>
                              <div className="icon_div">
                                <PhoneFillIcon />
                              </div>
                              <div className="text_div">
                                <span>{d.phone}</span>
                              </div>
                            </div>
                            <div style={{ marginBottom: "8px" }}>
                              <div className="icon_div">
                                <MdLocationPin />
                              </div>
                              <div className="text_div">
                                <span>
                                  {d.city.name}, {d.city.country.name}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </List.Item>
                      <br />
                    </>
                  ))}
                </Hidden>
                <Hidden only={["xl", "lg", "md"]}>
                  {resData?.map((d, index) => (
                    <>
                      <List.Item key={index}>
                        <Panel
                          key={index}
                          shaded
                          bordered
                          // bodyFill
                          // style={{
                          //   display: "block",
                          //   maxWidth: "100%",
                          //   margin: "30px 30px 60px 30px",
                          //   // width:'25em'
                          // }}
                        >
                          <div
                            className="image-cover"
                            style={{
                              height: "220px",
                              padding: "0px",
                              alignItems: "center",
                              justifyContent: "center",
                              maxHeigth: "220px",
                              marginLeft:'auto',
                              marginRight:'auto'
                            }}
                          >
                            <Link to={`/company/detail/${d.com_id}`}>
                              <img
                                key={index}
                                src={`${d.logo}`}
                                alt={d?.name}
                                style={{
                                  objectFit: "contain",
                                  background: "transparent",
                                  margin: 0,
                                  padding: 0,
                                  width: "12em",
                                  maxHeight: "12em",
                                  minHeight: "5em",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                // height={"240px"}
                                // width={"240px"}
                              />
                            </Link>
                          </div>

                          <Panel
                            header={
                              <>
                                <Link to={`/company/detail/${d.com_id}`}>
                                  <h5>
                                    {d?.name}{" "}
                                    <small style={{ fontSize: "13px" }}>
                                      ({d.companytype.name})
                                    </small>
                                  </h5>
                                  <h6>
                                    {d.city.name}, {d.city.country.name}
                                  </h6>
                                </Link>
                              </>
                            }
                          >
                            <div style={{ height: 120 }}>
                              <div className="text-start ">
                                <div style={{ marginBottom: "8px" }}>
                                  <div className="icon_div">
                                    <EmailFillIcon />
                                  </div>
                                  <div className="text_div">
                                    <span>{d.email}</span>
                                  </div>
                                </div>
                                <div style={{ marginBottom: "8px" }}>
                                  <div className="icon_div">
                                    <PhoneFillIcon />
                                  </div>
                                  <div className="text_div">
                                    <span>{d.phone}</span>
                                  </div>
                                </div>
                                <div style={{ marginBottom: "8px" }}>
                                  <div className="icon_div">
                                    <MdLocationPin />
                                  </div>
                                  <div className="text_div">
                                    <span>
                                      {d.city.name}, {d.city.country.name}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Button
                                as={Link}
                                to={`/company/detail/${d.com_id}`}
                                className="d-w-r-btn"
                                style={{ color: "white" }}
                              >
                                {" "}
                                See Detail
                              </Button>
                            </div>
                          </Panel>
                        </Panel>
                      </List.Item>
                      <br />
                    </>
                  ))}
                </Hidden>
              </InfiniteScroll>
            </List>
          </Tab>
          <Tab
            eventKey="grid"
            title={
              <>
                <h4 style={{ color: "#EA1F24" }}>Grid View</h4>
              </>
            }
          >
            <Container
              style={{
                backgroundColor: "#fff",
              }}
            >
              <Box
                sx={{ flexGrow: 1, marginLeft: "auto", marginRight: "auto" }}
              >
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 2, sm: 8, md: 12 }}
                >
                  {resData?.map((d, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <br />

                      <Card
                        style={{
                          width: 280,
                          height: 410,
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={`${d.logo}`}
                          key={index}
                          alt={d?.name}
                          style={{
                            objectFit: "contain",
                            background: "transparent",
                            margin: 0,
                            padding: 0,
                            width: "10em",
                            maxHeight: "10em",
                            minHeight: "5em",
                            marginLeft: "4rem",
                          }}
                        />
                        <CardContent>
                          <div style={{ height: 180 }}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              <h5>
                                {d?.name}{" "}
                                <small style={{ fontSize: "13px" }}>
                                  ({d.companytype.name})
                                </small>
                              </h5>
                            </Typography>
                            <p>
                              <h6>
                                {d.city.name}, {d.city.country.name}
                              </h6>
                            </p>
                            <Typography variant="body2" color="text.secondary">
                              <div className="text-start ">
                                <div style={{ marginBottom: "8px" }}>
                                  <div className="icon_div">
                                    <EmailFillIcon />
                                  </div>
                                  <div className="text_div">
                                    <span>{d.email}</span>
                                  </div>
                                </div>
                                <div style={{ marginBottom: "8px" }}>
                                  <div className="icon_div">
                                    <PhoneFillIcon />
                                  </div>
                                  <div className="text_div">
                                    <span>{d.phone}</span>
                                  </div>
                                </div>
                                <div style={{ marginBottom: "8px" }}>
                                  <div className="icon_div">
                                    <MdLocationPin />
                                  </div>
                                  <div className="text_div">
                                    <span>
                                      {d.city.name}, {d.city.country.name}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </div>
                        </CardContent>
                        <CardActions>
                          <Button
                            as={Link}
                            to={`/company/detail/${d.com_id}`}
                            // state={{com_id: d.com_id}}
                            className="d-w-r-btn"
                            style={{ color: "white" }}
                          >
                            {" "}
                            See Detail
                          </Button>
                        </CardActions>
                      </Card>
                      <br />
                      <br />
                      <br />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
          </Tab>
        </Tabs>
      </Content>
      <Divider />
    </Container>
  );
};

export default University;
