import React from "react";
import {Loader} from "rsuite";

export function CustomSpinner() {

    return (
        <Loader size="lg"
                style={{top: '48%', margin: '20% 45%'}} className='default-color'
        />
    )
}

export function CustomSmallSpinner({sm=false}) {

    return (
        <div style={{textAlign: 'center', backgroundColor: 'white'}}>

            <Loader
                size={sm ? "sm" : 'lg'}
                style={{margin: '2%'}}
                className='default-color'
            />
        </div>
    )
}

export function ActivateAccountWaitingSpinner() {

    return (
        <div style={{textAlign: 'center', backgroundColor: 'white',paddingTop:'150px',paddingBottom:'100px'}}>
            <p style={{fontSize:'1.5rem'}}>Kindly bear with us while your account is being Activated</p>

            <Loader
                size="lg"
                style={{margin: '2%'}}
                className='default-color'
            />
        </div>
    )
}

