// noinspection JSValidateTypes

import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonToolbar,
    Col,
    Content,
    DatePicker,
    Divider,
    Form,
    Grid,
    Message,
    Radio,
    RadioGroup,
    Row,
    SelectPicker,
    toaster,
    Uploader
} from "rsuite";
import {TextField} from "components/textField";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {formats, modules} from "components/profile/company/reactQuilConfig";
import 'react-phone-number-input/style.css'
import {MediaUrlInputControl} from "components/profile/company/inputs/addMediaURLInput";
import {Speakers} from "components/profile/company/inputs/speakers";
import {TicketPrice} from "components/profile/company/inputs/ticketPrice";
import {getCurrentUser} from "service/auth.service";
import {getAllCountries, getCountryCities} from "service/setting.service";
import {
    createEvent,
    getEventCategory,
    getEventDetail_edt,
    getEventSubCategory,
    getEventType,
    updateEvent
} from "service/event.service";
import {getBase64, toTitle} from "service/until.service";
import {v4 as uuidv4} from "uuid";
import {CustomSpinner} from "components/common/spinner";
import Config from "../../../../config.json"
import {CreateCompanyEventFormModel} from "lib/formModels";
import {handleMediaDocUpload} from "../../../../lib/utility";


const CompanyEvent = ({editOrNew = false, com_id, eve_id}) => {
    const formRef = React.useRef();
    const [user, setUser] = React.useState(getCurrentUser());
    const defaultFormValue = {
        title: '',
        venue: 'online',
        startDate: new Date(),
        description: '',
        endDate: new Date(),
        website: '',
        address: '',
        lat: 0,
        log: 0,
        country: '',
        city: '',
        com_id,
        evt_id: '',
        evs_id: '',
        mediaUrl: [{path: '', mediaType: 'Video'}],
        mediaDoc: [],
        speakers: [{
            name: '',
            designation: '',
            description: '',
            profileImage: '',
            email: '',
            fbURL: '',
            instaURL: '',
            phone: '',
            twitterURL: '',
            youtubeURL: ''
        }],
        ticketPrice: [{name: '', price: '', description: ''}],
    }
    const [formError, setFormError] = React.useState({});
    const [formValue, setFormValue] = React.useState(defaultFormValue);
    const [country, setCountry] = React.useState([]);
    const [city, setCity] = React.useState([]);
    const [eventType, setEventType] = React.useState([]);
    const [eventCategory, setEventCategory] = React.useState([]);
    const [eventSubCategory, setEventSubCategory] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [fileList, setFileList] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isMediaDocLoading, setIsMediaDocLoading] = useState(false);
    const [isMediaDocUploaded, setIsMediaDocUploaded] = useState(editOrNew);
    const [mediaDocs, setMediaDocs] = useState([]);

    const baseUrl = Config.BASE_API_URL;
    const getEventsUrl = baseUrl + Config.GET_EVENTS_URL;


    useEffect(async () => {
        setLoading(true);

        setEventType(await getEventType())
        setEventCategory(await getEventCategory())
        setCountry(await getAllCountries())

        if (editOrNew) {
            await handleJobDataSet()
        }
        setLoading(false)
    }, [])

    const handleJobDataSet = async () => {

        const response = await getEventDetail_edt(eve_id)
        if (response.status) {
            const data = response.data

            const mediaDoc = data.event_mediadoc.map((media, index) => {
                return {
                    url: media.mediadoc.path,
                    type: media.mediadoc.mediaType,
                    fileKey: index, name: media.mediadoc.path.split('/').pop(),
                    path: media.mediadoc.path,
                    mediaType: media.mediadoc.mediaType,
                    med_id: media.med_id
                }
            })


            const s3BaseURl = Config.S3_BUCKET_BASE_URL

            const filterMediaDoc = mediaDoc.filter((media) => media.url.includes(s3BaseURl))

            setFileList(filterMediaDoc.map((media) => {
                return {
                    url: media.url,
                    type: media.type,
                    fileKey: media.fileKey,
                    name: media.name,
                    med_id: media.med_id
                }
            }))




            if (data.venue !== "online"){
                setCountry(await getAllCountries())
                setCity(await getCountryCities(data.country))
            }

            setEventSubCategory(await getEventSubCategory(data.evc_id))


            const event = {
                com_id,
                eve_id,
                use_id: user.use_id,
                title: data.title,
                venue: data.venue,
                startDate: new Date(data.startDate),
                description: data.description,
                endDate: new Date(data.endDate),
                website: data.url,
                address: data.address,
                lat: data.lat,
                log: data.log,
                country: data.country,
                city: data.city,
                evt_id: data.evt_id,
                evs_id: data.evs_id,
                evc_id: data.evc_id,
                mediaUrl: mediaDoc.filter((media) => media.type === 'Video'),
                mediaDoc:  mediaDoc.filter((media) => media.type !== 'Video'),
                speakers: data.speakers,
                ticketPrice: data.ticketprice,
            }

            // console.log(event)
            setFormValue(event)

        }
    }

    // console.log(formError);

    const handleSubmit = async () => {
        setIsSubmitted(true)
        if (!formRef.current.check()) {
            console.error('Form Error');
            setIsSubmitted(false)
            return;
        }

        if(!editOrNew){
            console.log(formValue);
            const response = await createEvent(formValue, user.access_token)

            if (response.status) {
                toaster.push(<Message type="success">Company Created successfully</Message>);
                setFormValue(defaultFormValue)
            } else {
                toaster.push(<Message type="error">{response.message}</Message>);
            }
        }else{
            const response = await updateEvent(formValue, user.access_token)

            if (response.status) {
                toaster.push(<Message type="success">Event Updated successfully</Message>);
                // setFormValue(defaultFormValue)
            } else {
                toaster.push(<Message type="error">{response.message}</Message>);
            }
        }

        setIsSubmitted(false)

        // console.log(formValue, 'Form Value');
    };

    const handleChange = (name, value) => {
        if(value === 'online') {
            setFormValue(prevState => ({...prevState, [name]: value, city: '', address: '', lat: '', log: ''}))
        } else {
            setFormValue(prevState => ({...prevState, [name]: value,}));
        }


    };

    console.table(formValue.speakers)

    if (loading) return <CustomSpinner/>

    return (
        <Content>
            <Grid fluid className='create-item-form' style={{marginLeft: 15}}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    disabled={isSubmitted}
                    model={CreateCompanyEventFormModel}
                    fluid
                >

                    <Row>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="title" label="Title" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Event Type:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="evt_id" data={eventType} accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>Event Type Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select Category:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control
                                        name="evc_id"
                                        data={eventCategory}
                                        accepter={SelectPicker}
                                        onChange={async (evc_id) => {
                                            setFormValue(prevState => ({
                                                ...prevState,
                                                evs_id: '', evc_id
                                            }))
                                            setEventSubCategory(await getEventSubCategory(evc_id))
                                        }}
                                    />
                                    <Form.HelpText tooltip>Category Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select Sub Category:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="evs_id" data={eventSubCategory} accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>Country Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Group controlId="textarea">
                                <Form.ControlLabel>Description</Form.ControlLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={formValue?.description}
                                    onChange={(e) => handleChange('description', e)}
                                    modules={modules}
                                    formats={formats}
                                    style={{marginRight: 25}}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Divider style={{backgroundColor: '#0C3144', margin: '12px 0'}}/>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <RadioGroup value={formValue.venue} onChange={(e) => handleChange('venue', e)}>
                                <p>Venue: </p>
                                <Radio value="online">Online</Radio>
                                <Radio value="location">location</Radio>
                            </RadioGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <TextField name="website" label="Event's Registration URL" helpText='Required'/>
                        </Col>
                    </Row>
                    {formValue.venue === 'location' &&
                        <>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <TextField name="address" label="Address" helpText='Required'/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={6} lg={6}>
                                    <TextField name="lat" label="Latitude" helpText='Required' type='number'
                                               step='any'/>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6}>
                                    <TextField name="log" label="Longitude" helpText='Required' type='number'/>

                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6}>

                                    <Form.Group controlId="selectPicker">
                                        <Form.ControlLabel>Select Country:</Form.ControlLabel>
                                        <div style={{display: "flex"}}>
                                            <Form.Control
                                                name="country"
                                                data={country}
                                                accepter={SelectPicker}
                                                onChange={async (cou_id) => {
                                                    setFormValue(prevState => ({
                                                        ...prevState,
                                                        city: '',
                                                        country: cou_id
                                                    }))
                                                    setCity(await getCountryCities(cou_id))
                                                }}
                                            />
                                            <Form.HelpText tooltip>Category Required</Form.HelpText>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6}>

                                    <Form.Group controlId="selectPicker">
                                        <Form.ControlLabel>Select City:</Form.ControlLabel>
                                        <div style={{display: "flex"}}>
                                            <Form.Control name="city" data={city} accepter={SelectPicker}/>
                                            <Form.HelpText tooltip>Category Required</Form.HelpText>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>

                        // <Row>
                        //     <Col xs={24} sm={24} md={24} lg={24}>
                        //         <TextField name="website" label="Event's URL" helpText='Required'/>
                        //     </Col>
                        // </Row>
                    }

                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>

                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name='startDate' format="yyyy-MM-dd HH:mm:ss" label="Start Date"
                                       accepter={DatePicker}/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name='endDate' format="yyyy-MM-dd HH:mm:ss" label="End Date"
                                       accepter={DatePicker}/>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>

                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Images and Videos</h6>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Control
                                style={{width: 'inherit'}}
                                name="mediaUrl"
                                accepter={MediaUrlInputControl}
                                fieldError={formError.mediaUrl}
                            />

                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Group controlId="uploader" style={{marginRight: '25px'}}
                            >
                                <Form.ControlLabel>Uploader:</Form.ControlLabel>
                                <Uploader
                                    fileList={fileList}
                                    listType="picture"
                                    action=""
                                    draggable
                                    disabled={fileList.length >= 5}
                                    name="mediaDoc"
                                    autoUpload={false}
                                    multiple
                                    accept={'image/*,video/*'}
                                    onRemove={(file) => {
                                        setFileList(fileList.filter(f => f.fileKey !== file.fileKey));
                                        setFormValue({...formValue, mediaDoc: fileList.filter(f => f.fileKey !== file.fileKey)})
                                    }}
                                    onChange={async (files) => {

                                        console.log(files, 'files')
                                        setFileList(files);
                                        let mediaDocData = []
                                        for (const f of files) {
                                            if (f.blobFile){
                                                setIsMediaDocUploaded(false);
                                                mediaDocData.push({file: f, isNew: true})
                                            }else{
                                                mediaDocData.push({...f, isNew: false})
                                            }

                                        }

                                        setMediaDocs(mediaDocData)
                                        // setFormValue({...formValue, mediaDoc: mediaDocData})
                                    }}
                                    renderFileInfo={(file, fileElement) => {
                                        return (
                                            <div>
                                                <span>File Name: {file.name}</span>
                                            </div>
                                        );
                                    }}
                                >
                                    <div style={{lineHeight: '200px'}}>Click or Drag images to this area to upload</div>
                                </Uploader>
                                <br/>
                                <Button
                                    style={{marginTop: 10, width: 'auto'}}
                                    className={'d-w-r-btn'}
                                    disabled={isMediaDocUploaded || !mediaDocs.length}
                                    onClick={async () => handleMediaDocUpload(setIsMediaDocLoading, setIsMediaDocUploaded, setFormValue, mediaDocs, user.access_token)}
                                    loading={isMediaDocLoading}
                                >
                                    Start Upload
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>

                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Speakers</h6>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Control
                                name="speakers"
                                accepter={Speakers}
                                fieldError={formError.speakers}
                            />

                        </Col>

                    </Row>

                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Tickets Price</h6>

                        <Form.Control
                            name="ticketPrice"
                            accepter={TicketPrice}
                            fieldError={formError.ticketPrice}
                        />

                    </Row>
                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary"
                                // type="submit"
                                    className='d-w-r-btn'
                                    onClick={handleSubmit}
                                    style={{width: 'auto'}}
                                    disabled={Object.keys(formError).length !== 0 || isSubmitted || !isMediaDocUploaded}
                                    loading={isSubmitted}
                            >Submit</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Grid>
        </Content>

    );
};

export default CompanyEvent;