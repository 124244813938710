import React, {useEffect, useState} from "react";
import {Button, Container, Content, Divider, Input, List, Panel, Timeline,} from "rsuite";
import {Col, Row} from "react-bootstrap";
import Hidden from "@mui/material/Hidden";
import {PaginationCustom} from "../components/common/paginationCustom";
import {CustomSpinner} from "../components/common/spinner";
import {getAllNotifications} from "../service/notification.service";
import {timeSince} from "../lib/utility";
import {getCurrentUser} from "../service/auth.service";

const TimelineWithTime = ({align, time, title}) => (
    <div>
        <Hidden only={["sm", "xs"]}>
            <Timeline align={align}>
                <List size={"sm"}>
                    <List.Item
                        style={{
                            overflow: "hidden",
                            padding: "10px 20px",
                            background: "transparent",
                            border: "1px solid #f5f5f5",
                            boxShadow: "none",
                            outline: "none",
                        }}
                    >
                        <div style={{maxWidth: "40em", marginLeft: "2em"}}>
                            <Row>
                                <Col xs={2}>
                                    <div style={{width: "5em"}}>
                                        {new Date(time).toLocaleDateString('en-us', {month: 'short', day: '2-digit'})}{" "}
                                        <span style={{color: "red", fontWeight: "bolder"}}>
                      {new Date(time).toLocaleDateString('en-us', {year: 'numeric'})}
                    </span>{" "}
                                        &nbsp;
                                    </div>
                                </Col>
                                <Col xs={1}>
                                    <div style={{width: "1em", marginTop: "1em"}}>
                    <span
                        style={{height: "50px", border: "3px solid red"}}
                    ></span>
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <div style={{width: "35em"}}>
                    <span
                        style={{
                            fontSize: "14pt",
                            color: "red",
                            fontWeight: "bolder",
                        }}
                    >
                      {title}
                    </span>
                                        <br/>
                                        <span>{
                                            !timeSince(new Date(time)) ?

                                                new Date(time).toLocaleDateString('en-us', {weekday: 'short', day: '2-digit', month: 'short', year: 'numeric'})
                                                :
                                                timeSince(new Date(time))
                                        }</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </List.Item>
                </List>
            </Timeline>
        </Hidden>
        <Hidden only={["lg", "xl", "md"]}>
            <Timeline align={align}>
                <List size={"sm"}>
                    <List.Item
                        style={{
                            overflow: "hidden",
                            padding: "10px 20px",
                            background: "transparent",
                            border: "1px solid #f5f5f5",
                            boxShadow: "none",
                            outline: "none",
                        }}
                    >
                        <div
                            style={{
                                maxWidth: "20em",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        >
                            <Row>
                                <Col xs={2}>
                                    <div style={{width: "5em", fontSize: "8pt"}}>
                                        {new Date(time).toLocaleDateString('en-us', {month: 'short', day: '2-digit'})}{" "}{" "}
                                        <span style={{color: "red", fontWeight: "bolder"}}>
                      {new Date(time).toLocaleDateString('en-us', {year: 'numeric'})}
                    </span>{" "}
                                        &nbsp;
                                    </div>
                                </Col>
                                <Col xs={1}>
                                    <div style={{width: "1em", marginTop: "1em"}}>
                    <span
                        style={{height: "50px", border: "3px solid red"}}
                    ></span>
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <div style={{width: "5em", whiteSpace: "nowrap"}}>
                    <span
                        style={{
                            fontSize: "12pt",
                            color: "red",
                            fontWeight: "bolder",
                            textOverflow: "ellipsis",
                            resize: "horizontal",
                            overflow: "hidden",
                        }}
                    >
                      {title}
                    </span>
                                        <br/>
                                        <span>{
                                            !timeSince(new Date(time)) ?

                                                new Date(time).toLocaleDateString('en-us', {weekday: 'short', day: '2-digit', month: 'short', year: 'numeric'})
                                                :
                                                timeSince(new Date(time))
                                        }</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </List.Item>
                </List>
            </Timeline>
        </Hidden>
    </div>
);

const Notification = () => {

    const [user, setUser] = useState(getCurrentUser())
    const [resData, setResData] = useState([]);
    const [count, setCount] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false);

    const params = `skip=${limit * (page - 1)}&take=${(limit * (page - 1)) + limit}&sortField=not_id&sortDirection=desc&searchText=${searchText}&use_id=${user?.use_id}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetchData()
    }, [limit, page, searchText]);

    const fetchData = async () => {
        setLoading(true);
        if (user){

        const response = await getAllNotifications(params);
        setResData(response.data);
        setCount(response.count);
        }
        setLoading(false);
    }


    // if (!user) return <div style={{textAlign:'center'}}>
    //     Sign in To view Notifications
    // </div>

    return (
        <Container className="wrapper">
            <Divider style={{background: "transparent", margin: "6px"}}/>
            {/*<Panel
        bordered
        style={{
          background: "#fff",
          border: "2px solid #ccc",
          padding: "10px 40px",
        }}
      >
        <div style={{ display: "inline", width: 200 }}>
          <img src={notif} style={{ width: "100%", height: "100%" }} />
        </div>
      </Panel>*/}
            <div className={"border-default"} style={{background: 'white', textAlign: 'center', alignContent: 'center'}}>
                <h1 className={'default-color'} style={{margin: '20px 0'}}>NOTIFICATIONS</h1>
            </div>
            <Divider style={{margin: "12px 0"}}/>
            <Panel
                style={{
                    background: "#EA1F24",
                    fontWeight: "bold",
                    border: "2px solid #ccc",
                    padding: "0px 10px",
                    color: "#fff",
                }}
            >
                {/* <Container> */}
                <p style={{marginBottom: 0}}>News & Events</p>
                {/*<Row>*/}
                {/*    <Col xs={2}>News & Eventes</Col>*/}
                {/*</Row>*/}
                {/* </Container> */}
            </Panel>
            <Panel style={{backgroundColor: "#fff"}} shaded>
                <Panel
                    style={{
                        background: "transparent",
                        border: "none",
                        fontWeight: "bold",
                        // border: "2px solid #ccc",
                        padding: "0px 5px",
                        color: "#fff",
                    }}
                >
                    {/* <Container> */}
                    <Hidden only={["sm", "xs"]}>
                        <Row>
                            <Col xs={14}>
                                <Input onChange={(e) => setSearchText(e)} placeholder="Searching for..."/>
                            </Col>
                            {/*<Col xs={2}>*/}
                            {/*    <Button*/}
                            {/*        style={{*/}
                            {/*            background: "#EA1F24",*/}
                            {/*            color: "white",*/}
                            {/*            fontWeight: "bold",*/}
                            {/*            padding: "5px 10px",*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        Refresh*/}
                            {/*    </Button>*/}
                            {/*</Col>*/}
                        </Row>
                    </Hidden>
                    <Hidden only={["xl", "md", "lg"]}>
                        <Row>
                            <Col xs={8}>
                                <Input onChange={(e) => setSearchText(e)} placeholder="Searching for..."/>
                            </Col>
{/*                            <Col xs={2}>
                                <Button
                                    style={{
                                        background: "#EA1F24",
                                        color: "white",
                                        fontWeight: "bold",
                                        padding: "5px 10px",
                                    }}

                                >
                                    Refresh
                                </Button>
                            </Col>*/}
                        </Row>
                    </Hidden>
                    {/* </Container> */}
                </Panel>
                <Divider/>
                <Content>
                    {
                        !user ?
                            <div className={'text-center'}>
                                Sign In to View This Page
                            </div>:
                        loading ?
                            <CustomSpinner/>
                            :

                            resData.map(({title, message, createdAt}) =>

                                <Container style={{alignItems: "left", justifyContent: "left"}}>
                                    <TimelineWithTime
                                        align="left"
                                        title={title}
                                        time={createdAt}
                                    />
                                </Container>
                            )
                    }

                    <br/>
                    {/*<Button*/}
                    {/*  style={{*/}
                    {/*    background: "transparent",*/}
                    {/*    color: "#999",*/}
                    {/*    fontWeight: "bold",*/}
                    {/*    padding: "5px 10px",*/}
                    {/*    border: "1px solid #ccc",*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  {"Read More"}*/}
                    {/*</Button>*/}

                    {
                        count > 0 ?
                            <>
                                <Divider/>
                                <PaginationCustom count={count} limit={limit} page={page} setPage={setPage} setLimit={setLimit}/>
                            </>
                            :
                            user ?

                            <div className={'text-center'}>
                                No Notifications found
                            </div>
                                : ''
                    }
                    <Divider/>
                </Content>
            </Panel>

            <Divider/>

        </Container>
    );
};

export default Notification;
