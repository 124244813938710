import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getCurrentUser} from "service/auth.service";
import JobSeekerRegister from "components/register/jobSeeker";
import CompanyHeader from "components/profile/company/companyHeader";
import TutorHeader from "components/profile/tutor/TutorHeader";
import JobSeekerHeader from "components/profile/jobSeeker/jobSeekerHeader";

export default function Profile() {

    const [user, setUser] = useState(getCurrentUser());
    let navigate = useNavigate();
    const [isActive, setIsActive] = useState(false)

    useEffect(async () => {
        if (!localStorage.getItem('user')) {
            navigate('/login');
        }
    }, []);


    function SwitchCase({value}) {
        switch (value) {
            case 'COMPANY':
                return <CompanyHeader data={user}/>;
            case 'ADMIN':
                return <JobSeekerRegister data={user}/>;
            case 'TUTOR':
                return <TutorHeader data={user}/>;
            case 'JOBSEEKER':
                return <JobSeekerHeader data={user}/>;
            default:
                return 'You are not authorised ';
        }
    }

    return (
        <div className="wrapper">
            {!!user ?
                <SwitchCase value={user?.type}/>
                : null}

        </div>
    );
}