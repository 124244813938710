import React from "react";
import {ButtonGroup, Checkbox, Col, DatePicker, Divider, IconButton, Input, Row} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";


export const  Experience = ({value = [], onChange, fieldError}) => {
    const errors = fieldError ? fieldError.array : [];
    const [experience, setExperience] = React.useState(value);

    const handleChangeSpeaker = nextExperience => {
        setExperience(nextExperience);
        onChange(nextExperience);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextExperience = [...experience];
        nextExperience[rowIndex] = value;
        handleChangeSpeaker(nextExperience);
    };

    const handleMinus = () => {
        handleChangeSpeaker(experience.slice(0, -1));
    };
    const handleAdd = () => {
        handleChangeSpeaker(experience.concat([
            {
                title: '',
                companyName: '',
                fromDate: new Date(),
                toDate: new Date(),
                isCompleted: false,
                description: '',
            }
            ]));
    };
    return (
        <table style={{width: 'inherit'}}>

            <tbody>
            {experience.map((rowValue, index) => (
                <EducationDetail
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index].object : null}
                    onChange={handleInputChange}
                />
            ))}
            </tbody>
            <tfoot>
            <tr>
                <Cell colSpan={2} style={{paddingTop: 10}}>
                    <ButtonGroup size="xs">
                        <IconButton onClick={handleAdd} icon={<PlusIcon/>}/>
                        <IconButton disabled={value.length === 1} onClick={handleMinus} icon={<MinusIcon/>}/>
                    </ButtonGroup>
                </Cell>
            </tr>
            </tfoot>
        </table>
    );
};

const EducationDetail = ({rowValue = {}, onChange, rowIndex, rowError}) => {
    const handleChangeTitle = value => {
        onChange(rowIndex, {...rowValue, title: value});
    };
    const handleChangeCompanyName = value => {
        onChange(rowIndex, {...rowValue, companyName: value});
    };

    const handleChangeFromDate = value => {
        onChange(rowIndex, {...rowValue, fromDate: value});
    };
    const handleChangetoDate = value => {
        onChange(rowIndex, {...rowValue, toDate: value});
    };
    const handleIsCompleted = (value, data) => {
        onChange(rowIndex, {...rowValue, isCompleted: data});
        // console.log(data);
    };
    const handleChangeDescription = value => {
        onChange(rowIndex, {...rowValue, description: value});
    };
    return (
        <tr>
            <>
                <Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <h6 style={{marginBottom: '5px'}}>Experience</h6>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>Title</p>
                        <Input value={rowValue.title} onChange={handleChangeTitle}/>
                        {rowError ? <ErrorMessage>{rowError.title.errorMessage}</ErrorMessage> : null}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>Company Name</p>
                        <Input value={rowValue.companyName} onChange={handleChangeCompanyName}/>
                        {rowError ? <ErrorMessage>{rowError.companyName.errorMessage}</ErrorMessage> : null}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>From Date</p>
                        <DatePicker oneTap value={new Date(rowValue.fromDate)} onChange={handleChangeFromDate}/>
                        {/*{rowError ? <ErrorMessage>{rowError.fromDate.errorMessage}</ErrorMessage> : null}*/}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>End Date</p>
                        <DatePicker oneTap value={new Date(rowValue.toDate)} onChange={handleChangetoDate}/>
                        {/*{rowError ? <ErrorMessage>{rowError.toDate.errorMessage}</ErrorMessage> : null}*/}
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <p>Description</p>

                        <Input as="textarea" rows={3} placeholder="Description Here" value={rowValue.description}
                               onChange={handleChangeDescription}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <Checkbox checked={rowValue.isCompleted} onChange={handleIsCompleted}> Is Completed</Checkbox>
                        {/*{rowError ? <ErrorMessage>{rowError.isCompleted.errorMessage}</ErrorMessage> : null}*/}
                    </Col>

                </Row>
            </>

        </tr>

    );
};
const ErrorMessage = ({children}) => <span style={{color: 'red'}}>{children}</span>;
const Cell = ({children, style, ...rest}) => (
    <td style={{padding: '2px 4px 2px 0', verticalAlign: 'top', ...style}} {...rest}>
        {children}
    </td>
);
