import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import {CustomSpinner} from "components/common/spinner";
import {Column} from "lib/dtc/coursesListing.column";
import {getCurrentUser} from "service/auth.service";
import Config from "../../../config.json";
import {getCourses} from "service/course.service";
import Modal from "rsuite/Modal";
import CompanyCourse from "components/profile/company/CompanyJobEventsCourses/companyCourse";
import {Button} from "rsuite";

function CoursesList() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sortField, setSortField] = useState('cou_id')
    const [sortDirection, setSortDirection] = useState('desc')
    const [couId,setCouId] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [user,setUser] = useState(getCurrentUser())
    const [isProfileActive,setIsProfileActive] = useState(false)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const baseUrl = Config.BASE_API_URL;
    const getCoursesUrl = baseUrl + Config.GET_COURSES_URL;

    useEffect(async () => {

        setLoading(true)
        await fetchData()
        setLoading(false)

    }, [perPage, page, sortField, sortDirection, searchText])


    const fetchData = async () => {
        const params = `type=profile&skip=${page * perPage}&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&searchText=${searchText}`
        const url = `${getCoursesUrl}?${params}`
        setLoading(true);

        const response = await getCourses(url,user?.access_token)

        setData(response?.status ? response?.data?.courses : []);
        setIsProfileActive(user?.tutor[0]?.isActive)
        setTotalRows(response?.status ? response?.data?.count : 0);
        setLoading(false);
    }

    const handlePerRowsChange = async (newPerPage, page_) => {
        setPerPage(newPerPage);
        setPage(page_ - 1)
    };

    const handlePageChange = (page_) => {
        setPage(page_ - 1);
    };

    const handleSort = (column, sortDirection) => {
        setSortDirection(sortDirection);
        setSortField(column.sortField);
    };
    const handleEdit = (cou_id) => {
        setCouId(cou_id)
        // console.log(cou_id);
        handleOpen()
        // setEditOrNew(true)
    }
    const columns = Column( handleEdit);
    return (
        <div className='company-list-wrapper'>
            <DataTable
                // title="Products"
                // keyField={'com_id'}
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions={[10, 30, 50, 100, 200]}
                paginationComponentOptions={{selectAllRowsItem: true, selectAllRowsItemText: 'All'}}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                striped={true}
                fixedHeader
                fixedHeaderScrollHeight={'70vh'}
                responsive
                progressComponent={<CustomSpinner/>}

            />

            <Modal backdrop={'static'} keyboard={false} open={open} onClose={handleClose} full>
                <Modal.Header>
                    <Modal.Title>Edit Course</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <CompanyCourse cou_id={couId} editOrNew={true}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default CoursesList;