import React, {useEffect, useState} from "react";

import ReactHtmlParser from "react-html-parser";
import GoogleMapReact from "google-map-react";
import PinIcon from "@rsuite/icons/Pin";
import {CustomSpinner} from "components/common/spinner";
import {Carousel, Col, Container, Divider, Grid, List, Nav, Panel, Row,} from "rsuite";
import ReactPlayer from "react-player/lazy";
import "../../styles/events.css";
import {useLocation, useParams} from "react-router-dom";
import Config from "../../config.json";
import {getEventDetail} from "service/event.service";
import Speakers from "components/event/speakers";
import Ticket from "components/event/tickets";
import Button from "@mui/material/Button";
import {ReadMore} from "../../lib/utility";

export default function EventDetailView() {
    const location = useLocation();

    // const eve_id = location.state?.eve_id;
    let {eve_id} = useParams();

    const baseUrl = Config.BASE_API_URL;
    const getEventDetailUrl = baseUrl + Config.GET_EVENT_DETAIL_URL;
    const getEventDetailIdUrl = `${getEventDetailUrl}${eve_id}`;
    const GOOGLE_MAP_API_KEY = Config.GOOGLE_MAP_API_KEY;

    const [resData, setResData] = useState({});
    const [loading, setLoading] = useState(true);
    const [cases, setCases] = useState("speakers");

    useEffect(async () => {
        setLoading(true);

        const resDetail = await getEventDetail(getEventDetailIdUrl);
        setResData(resDetail?.data);

        setLoading(false);
    }, []);

    const CustomPinIcon = ({size, color}) => (
        <PinIcon style={{color, fontSize: size, marginRight: 10}}/>
    );

    const AnyReactComponent = ({text}) => (
        <div>
            <CustomPinIcon color="red" size="3em"/> {text}
        </div>
    );

    function SwitchCase({value}) {
        switch (value) {
            case "speakers":
                return <Speakers data={resData.speakers}/>;

            case "tickets":
                return <Ticket data={resData.ticketprice}/>;
            default:
                return "speakers";
        }
    }

    // function SwitchCase({value, data}) {
    //     switch (value) {
    //         case 'speakers':
    //             return <Speakers data={data?.speakers}/>;
    //         case 'tickets':
    //             return <Tickets data={data.ticketprice}/>;
    //         case 'contacts':
    //             return <Contact/>;
    //         default:
    //             return 'You are a User';
    //     }
    // }

    if (loading) return <CustomSpinner/>;

    return (
        <Container className="wrapper">
            <h1 style={{color: "red", textAlign: "center"}}> {resData?.title}</h1>

            <Grid fluid role="temp-grid">
                <Row gutter={10} className=" flex-custom-reverse">
                    <Col
                        lg={16}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{backgroundColor: "white", paddingBottom: "20px"}}
                    >
                        <Panel shaded>
                            <div className="panel-margin">
                                <Carousel>
                                    {resData?.media?.images?.map((src, index) => (
                                        <img
                                            // src='/images/download.jpg'
                                            src={`${src}`}
                                            alt="Event Picture"
                                            height={400}
                                        />
                                    ))}
                                </Carousel>

                                <Panel shaded header={<h3>About</h3>}>
                                    {/*<h1 style={{textAlign: 'left'}}>About</h1>*/}

                                    <ReadMore text={resData?.description}/>
                                    {/*{ReactHtmlParser(resData?.description)}*/}
                                </Panel>

                                <Panel shaded header={<h2>Video</h2>}>
                                    {/*<h2 className="my-3" style={{textAlign: 'left', paddingLeft: '20px'}}>Video</h2>*/}

                                    {resData?.media?.videos.length !== 0 ? (
                                        <Carousel>
                                            {resData?.media?.videos.map((src, index) => (
                                                <div className=" player-wrapper">
                                                    <ReactPlayer
                                                        className="bg-black react-player"
                                                        width="100%"
                                                        // height='100%'
                                                        controls={true}
                                                        light
                                                        url={src}
                                                    />
                                                </div>
                                            ))}
                                        </Carousel>
                                    ) : (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: "red",
                                                fontSize: "2rem",
                                            }}
                                        >
                                            {" "}
                                            No Videos Found
                                        </p>
                                    )}
                                </Panel>

                                {/*<div style={{width: '70%', margin: '0px 15%'}}>*/}

                                {/*    <Nav*/}
                                {/*        justify*/}
                                {/*        variant="tabs"*/}
                                {/*        defaultActiveKey="speakers"*/}

                                {/*        onSelect={(eventKey) => setCases(eventKey)}*/}
                                {/*    >*/}
                                {/*        <Nav.Item>*/}
                                {/*            <Nav.Link as={'button'} eventKey={'speakers'}>Speakers</Nav.Link>*/}
                                {/*        </Nav.Item>*/}
                                {/*        <Nav.Item>*/}
                                {/*            <Nav.Link as={'button'} eventKey="tickets">Tickets</Nav.Link>*/}
                                {/*        </Nav.Item>*/}
                                {/*        /!* <Nav.Item>*/}
                                {/*            <Nav.Link as={'button'} eventKey="contacts">*/}
                                {/*                Contacts*/}
                                {/*            </Nav.Link>*/}
                                {/*        </Nav.Item>*!/*/}
                                {/*    </Nav>*/}

                                {/*    /!*<SwitchCase value={cases} data={resData}/>*!/*/}

                                {/*</div>*/}

                                <Panel shaded>
                                    <Nav
                                        justified
                                        className="handler-nav"
                                        style={{
                                            boxShadow:
                                                "0 4px 4px rgba(0,0,0,.12),0 0 10px rgba(0,0,0,.06)",
                                            marginBottom: "0",
                                        }}
                                        onSelect={setCases}
                                        activeKey={cases}
                                    >
                                        <Nav.Item eventKey="speakers">Speakers</Nav.Item>
                                        <Nav.Item eventKey="tickets">Tickets</Nav.Item>
                                        {/*<Nav.Item eventKey='addJob'>Add Job</Nav.Item>*/}
                                        {/*<Nav.Item eventKey='addCourses'>Add Courses</Nav.Item>*/}
                                    </Nav>

                                    <Divider/>
                                    <SwitchCase value={cases}/>
                                </Panel>
                            </div>
                        </Panel>
                    </Col>
                    <Col
                        lg={8}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{backgroundColor: "white", paddingBottom: "20px"}}
                    >
                        <Panel shaded>
                            {/* <h1>INFO</h1>*/}
                            {/*<div className='mb-3'>&nbsp;</div>*/}

                            {/*  <button
                                className='btn-register-now'
                            >REGISTER NOW
                            </button>*/}

                            {/*<div>&nbsp;</div>*/}

                            <div className="panel-margin">
                                <Panel
                                    shaded
                                    header={
                                        <h5 className="default-color">
                                            EVENT DETAIL
                                            {resData?.url && (
                                                <Button
                                                    style={{margin: "10", color: "white"}}
                                                    // appearance="link"
                                                    className="d-w-r-btn"
                                                    as="a"
                                                    target="_blank"
                                                    href={`${resData?.url}`}
                                                >
                                                    Register Here
                                                </Button>
                                            )}
                                        </h5>
                                    }
                                    className="text-start "
                                >
                                    <List className="event-list" size="sm">
                                        <List.Item>
                                          <span style={{fontSize: "15px"}} className="icon_div">
                                            Start Date:
                                          </span>
                                            <div className="text_div">
                                                <span style={{fontSize: "13px", color: "grey"}}>
                                                  {new Date(resData?.startDate).toLocaleDateString("en-US")}
                                                </span>
                                            </div>
                                    </List.Item>
                                    <List.Item>
                                      <span style={{fontSize: "15px"}} className="icon_div">
                                        End Date:
                                      </span>
                                                            <div className="text_div">
                                        <span style={{fontSize: "13px", color: "grey"}}>
                                          {new Date(resData?.endDate).toLocaleDateString("en-US")}
                                        </span>
                                                            </div>
                                    </List.Item>

                                    <List.Item>
                                              <span style={{fontSize: "15px"}} className="icon_div">
                                                Venue:
                                              </span>
                                                                    <div className="text_div">
                                                <span style={{fontSize: "13px", color: "grey"}}>
                                                  {resData.venue}
                                                </span>
                                                                    </div>
                                    </List.Item>
                                                                {
                                                                    resData.venue === "location" && (
                                    <List.Item>
                                                  <span
                                                      style={{fontSize: "15px"}}
                                                      className="icon_div"
                                                  >
                                                    Address:
                                                  </span>
                                                                            <div className="text_div">
                                                    <span style={{fontSize: "13px", color: "grey"}}>
                                                      {resData.address}
                                                    </span>
                                                                            </div>
                                            </List.Item>
                                            )

                                            // <List.Item>
                                            //     <span style={{fontSize: '15px'}} className="icon_div">URL:</span>
                                            //     <div className="text_div">
                                            //         <span style={{fontSize: '13px', color: 'grey'}}>
                                            //             <a target={'_blank'} href={resData.url}>Event Link</a>
                                            //         </span>
                                            //     </div>
                                            // </List.Item>
                                        }
                                        <List.Item>
                      <span style={{fontSize: "15px"}} className="icon_div">
                        Event Type:
                      </span>
                                            <div className="text_div">
                        <span style={{fontSize: "13px", color: "grey"}}>
                          {" "}
                            {resData?.eventtype?.name}
                        </span>
                                            </div>
                                        </List.Item>
                                        <List.Item>
                      <span style={{fontSize: "15px"}} className="icon_div">
                        Category:
                      </span>
                                            <div className="text_div">
                        <span style={{fontSize: "13px", color: "grey"}}>
                          {" "}
                            {resData?.eventsubcategory?.eventcategory?.name}
                        </span>
                                            </div>
                                        </List.Item>
                                        <List.Item>
                      <span style={{fontSize: "15px"}} className="icon_div">
                        Sub Category:
                      </span>
                                            <div className="text_div">
                        <span style={{fontSize: "13px", color: "grey"}}>
                          {" "}
                            {resData?.eventsubcategory?.name}
                        </span>
                                            </div>
                                        </List.Item>
                                    </List>
                                </Panel>

                                {/* Google Map Block*/}

                                {/* <div style={{height: '50vh', width: '100%'}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{'key': GOOGLE_MAP_API_KEY}}
                                    center={{'lat': resData.lat, 'lng': resData.log}}
                                    defaultZoom={11}
                                >
                                    <AnyReactComponent
                                        lat={resData.lat}
                                        lng={resData.log}
                                        text={resData?.title}
                                    />

                                </GoogleMapReact>
                            </div>*/}

                                {resData.venue === "location" && (
                                    <Panel shaded className={"map_panel"}>
                                        <div style={{height: "50vh", width: "100%"}}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{
                                                    key: GOOGLE_MAP_API_KEY,
                                                    language: "en",
                                                }}
                                                // onClick={(e) => console.log(e.lat)}
                                                // center={{'lat': resData.lat, 'lng': resData.log}}
                                                center={[resData?.lat, resData?.log]}
                                                defaultCenter={[resData?.lat, resData?.log]}
                                                // defaultZoom={11}
                                                zoom={14}
                                            >
                                                <AnyReactComponent
                                                    lat={resData?.lat}
                                                    lng={resData?.log}
                                                    text={resData?.title}
                                                />
                                            </GoogleMapReact>
                                        </div>
                                    </Panel>
                                )}
                            </div>
                        </Panel>
                    </Col>
                </Row>

                {/* <br />
        <CommunityRating />
        <br />
        <YourReviews />
        <br /> */}
            </Grid>
        </Container>
    );
}

EventDetailView.auth = true;
