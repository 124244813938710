import {Button, Col, Grid, Input, Row, SelectPicker} from "rsuite";
import React, {useEffect, useState} from "react";
import '../styles/searchBox.css'
import {useNavigate} from "react-router-dom";
import {getAllCountries, getCountryCities} from "service/setting.service";
import {CustomSpinner} from "components/common/spinner";

export const SearchCompanyComponent = ({ data,searchDataFromHome }) => {

    console.log("Company",searchDataFromHome)
    const [country, setCountry] = React.useState([]);
    const [city, setCity] = React.useState([]);
    const [searchData , setSearchData] = useState(searchDataFromHome);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (name, value) => {
        setSearchData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    useEffect(async () => {
        setLoading(true)
        setCountry(await getAllCountries())
        setCity([])
        setLoading(false)
        if (searchData.city!== 0){
            setCity(await getCountryCities(searchData.country))
        }
    }, [])
    const handleSubmit = ()=>{
        // setSearchResult(true)

    console.log(searchData);
        // console.log(searchData);
        navigate('/company', {state: {searchData} ,replace: true})
        window.location.reload();
    }


    if (loading) return <CustomSpinner/>

    return (


            <div className="wrapper-search">


                <Grid fluid>
                    <Row >
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Input id='search-bar' placeholder="LOOKING FOR ....." value={searchData?.text} onChange={(e)=>handleChange('text',e)}/>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} >
                            <SelectPicker placeholder="Select Country" value={searchData?.country} data={country}
                                          onChange={async (cou_id) => {
                                              setSearchData(prevState => ({
                                                  ...prevState,
                                                  city: '',
                                                  country: cou_id
                                              }))
                                              setCity(await getCountryCities(cou_id))
                                          }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} >
                            <SelectPicker placeholder="Select City" data={city} value={searchData?.city} onChange={(e)=>handleChange('city',e)} />
                        </Col>
                        <Col xs={24} sm={24} md={4} lg={4} style={{ backgroundColor: '#EA1F24' }}>
                            <Button


                                className='d-w-r-btn'
                                onClick={handleSubmit}

                            >
                                SEARCH
                            </Button>
                        </Col>

                    </Row>

                </Grid>

            </div>

    )
}