import React from "react";
import {Form, InputGroup} from "rsuite";
import Avatar from "@rsuite/icons/legacy/Avatar";

export const TextField = React.forwardRef((props, ref) => {
    const {name, label, helpText = 'field Required', accepter, ...rest} = props;
    return (
        <Form.Group controlId={`${name}-4`} ref={ref}>
            <Form.ControlLabel>{label} </Form.ControlLabel>
            <div style={{display: "flex"}}>
                <Form.Control name={name} accepter={accepter} {...rest} />
                <Form.HelpText tooltip>{helpText}</Form.HelpText>
            </div>
        </Form.Group>);
});
export const TextFieldWithIcon = React.forwardRef((props, ref) => {
    const {name, label, helpText = 'field Required', accepter, ...rest} = props;
    return (
        <Form.Group controlId={`${name}-4`} ref={ref}>
            <Form.ControlLabel>{label} </Form.ControlLabel>
            <div style={{display: "flex"}}>
                <InputGroup style={{width:'100%'}} inside>
                    <Form.Control name={name} accepter={accepter} {...rest} />
                    <InputGroup.Addon>
                        <Avatar />
                    </InputGroup.Addon>
                </InputGroup>
                <Form.HelpText tooltip>{helpText}</Form.HelpText>
            </div>
        </Form.Group>);
});