import Config from "../config.json";
import { convertMediaToArray } from "lib/utility";

const baseUrl = Config.BASE_API_URL;
const getCoursesUrl = baseUrl + Config.GET_COURSES_URL;
const createCourseUrl = baseUrl + Config.CREATE_COURSE_URL;
const updateCourseUrl = baseUrl + Config.UPDATE_COURSE_URL;

export async function getCourses(url, token) {
  const response = await fetch(url, {
    method: "GET",
    headers: getHeader(token),
  });

  return await response.json();
}

export async function createCourse(payload, token) {
  const response = await fetch(createCourseUrl, {
    body: JSON.stringify(payload),
    method: "POST",
    headers: getHeader(token),
  });

  return await response.json();
}

export async function updateCourse(payload, token) {
  const response = await fetch(updateCourseUrl, {
    body: JSON.stringify(payload),
    method: "POST",
    headers: getHeader(token),
  });

  return await response.json();
}

export async function getCoursesDetail(url) {
  const response = await fetch(url);

  let tempData = await response.json();
  tempData.data.media = convertMediaToArray(tempData.data.course_mediadoc);

  return tempData;
}

function getHeader(token = "") {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
}

export function getHeaderToken(token = "") {
  return {
    Authorization: `Bearer ${token}`,
  };
}
