import "styles/App.css";
import "rsuite/dist/rsuite.min.css";
import "./styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "components/header/header";
import ContactUs from "./pages/contactUs";
import University from "./pages/university";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {AppContext} from "lib/contextLib";
import Home from "./pages";
import {useState} from "react";
import {Container} from "rsuite";
import {getCurrentUser} from "service/auth.service";
import {Logout} from "components/logout.component";
import Register from "pages/register";
import Profile from "pages/profile";
import {Login} from "pages/login";
import DetailView from "components/company/companyDetail";
import JobDetailView from "components/jobs/detailView";
import CourseDetailView from "components/courselisting/new";
import Jobs from "pages/job";
import CourseListing from "pages/courselisting";
import Event from "pages/events";
import EventDetailView from "components/event/detailView";
import Config from "config.json";
import ActivateAccount from "pages/activateAccount";
import Notification from "pages/notification";
import CustomFooter from "components/Footer";
import {ForgotPassword} from "pages/fogotPassword";
import ResetPassword from "pages/resetPassword";
import About from "./pages/about";
import Blogs from "./pages/blogs";
import BlogsDetailView from "./components/blogs/detailView";
import News from "./pages/news";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ScrollToTop from "./components/common/scrollToTop";

function App() {
    const [user, setUser] = useState(getCurrentUser());

    return (
        <AppContext.Provider value={{user, setUser}}>
            <Router>
                <ScrollToTop/>
                <Container>
                    <Header/>

                    <div className="custom-container">
                        <Routes>
                            <Route exact path="/" element={<Home/>}/>
                            <Route path="*" element={<Home/>}/>
                            <Route exact path="/contactus" element={<ContactUs/>}/>

                            <Route
                                exact
                                path="/university"
                                element={
                                    <University
                                        type={Config.COMPANY_TYPE.University}
                                        name={"Universities"}
                                    />
                                }
                            />
                            {/*<Route exact path="/company" element={<University/>}/>*/}
                            <Route
                                exact
                                path="/schools"
                                element={
                                    <University
                                        type={Config.COMPANY_TYPE.Schools}
                                        name={"Schools"}
                                    />
                                }
                            />
                            <Route exact path="/company" element={<University/>}/>
                            <Route
                                exact
                                path="/company/detail/:com_id"
                                element={<DetailView/>}
                            />

                            <Route exact path="/jobs" element={<Jobs/>}/>
                            <Route
                                exact
                                path="/job/detail/:job_id"
                                element={<JobDetailView/>}
                            />
                            <Route exact path="/courses" element={<CourseListing/>}/>
                            <Route
                                exact
                                path="/courses/detail/:cou_id"
                                element={<CourseDetailView/>}
                            />

                            <Route exact path="/events" element={<Event/>}/>
                            <Route
                                exact
                                path="/event/detail/:eve_id"
                                element={<EventDetailView/>}
                            />

                            <Route
                                exact
                                path="/blogs"
                                element={<Blogs DetailPage={false}/>}
                            />
                            <Route
                                exact
                                path="/blogs/:ann_id"
                                element={<Blogs DetailPage={true}/>}
                            />
                            <Route exact path="/news" element={<News/>}/>

                            <Route exact path="/notification" element={<Notification/>}/>

                            <Route
                                exact
                                path="/news/:ann_id"
                                element={<BlogsDetailView NewsOrBlog={true}/>}
                            />

                            <Route exact path="/register" element={<Register/>}/>
                            <Route
                                exact
                                path="/activate_account/:token"
                                element={<ActivateAccount/>}
                            />
                            <Route exact path="/profile" element={<Profile/>}/>
                            <Route exact path="/aboutus" element={<About/>}/>
                            <Route exact path="/contactus" element={<ContactUs/>}/>

                            <Route exact path="/login" element={<Login/>}/>
                            <Route exact path="/logout" element={<Logout/>}/>
                            <Route
                                exact
                                path="/forget_password"
                                element={<ForgotPassword/>}
                            />
                            <Route
                                exact
                                path="/reset_password/:token"
                                element={<ResetPassword/>}
                            />
                        </Routes>
                    </div>

                    <CustomFooter/>
                </Container>
            </Router>
        </AppContext.Provider>
    );
}

export default App;
