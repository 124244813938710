import React from "react";
import {ButtonGroup, IconButton, Input} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";

export const ContactInformation = ({value = [], onChange, fieldError}) => {
    const errors = fieldError ? fieldError.array : [];
    const [media, setMedia] = React.useState(value.length===0 ? [{personName: '', designation: '', email: '', phone: ''}] : value);
    const handleChangeProducts = nextProducts => {
        setMedia(nextProducts);
        onChange(nextProducts);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextProducts = [...media];
        nextProducts[rowIndex] = value;
        handleChangeProducts(nextProducts);
    };

    const handleMinus = () => {
        handleChangeProducts(media.slice(0, -1));
    };
    const handleAdd = () => {
        handleChangeProducts(media.concat([{personName: '', designation: '', email: '', phone: ''}]));
    };
    return (
        <table style={{width: 'inherit'}}>
            <thead>
            <tr>
                <Cell>Person Name</Cell>
                <Cell>Designation</Cell>
                <Cell>Email</Cell>
                <Cell>Phone</Cell>
            </tr>
            </thead>
            <tbody>
            {media.map((rowValue, index) => (
                <ContactInformationDetail
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index].object : null}
                    onChange={handleInputChange}
                />
            ))}
            </tbody>
            <tfoot>
            <tr>
                <Cell colSpan={2} style={{paddingTop: 10}}>
                    <ButtonGroup size="xs">
                        <IconButton onClick={handleAdd} icon={<PlusIcon/>}/>
                        <IconButton disabled={value.length === 1} onClick={handleMinus} icon={<MinusIcon/>}/>
                    </ButtonGroup>
                </Cell>
            </tr>
            </tfoot>
        </table>
    );
};

const ContactInformationDetail = ({rowValue = {}, onChange, rowIndex, rowError}) => {
    const handleChangePersonName = value => {
        onChange(rowIndex, {...rowValue, personName: value});
    };
    const handleChangeDesignation = value => {
        onChange(rowIndex, {...rowValue, designation: value});
    };
    const handleChangeEmail = value => {
        onChange(rowIndex, {...rowValue, email: value});
    };
    const handleChangePhone = value => {
        onChange(rowIndex, {...rowValue, phone: value});
    };

    return (
        <tr>
            <Cell>
                <Input value={rowValue.personName} onChange={handleChangePersonName}/>
                {rowError ? <ErrorMessage>{rowError.personName.errorMessage}</ErrorMessage> : null}
            </Cell>
            <Cell>
                <Input value={rowValue.designation} onChange={handleChangeDesignation}/>
                {rowError ? <ErrorMessage>{rowError.designation.errorMessage}</ErrorMessage> : null}
            </Cell>
            <Cell>
                <Input value={rowValue.email} onChange={handleChangeEmail}/>
                {rowError ? <ErrorMessage>{rowError.email.errorMessage}</ErrorMessage> : null}
            </Cell>
            <Cell>
                <Input value={rowValue.phone} onChange={handleChangePhone}/>
                {rowError ? <ErrorMessage>{rowError.phone.errorMessage}</ErrorMessage> : null}
            </Cell>
            {/*            <Cell>
                <Input value={rowValue.personName} onChange={handleChangePersonName}/>
                {rowError ? <ErrorMessage>{rowError.url.errorMessage}</ErrorMessage> : null}
            </Cell>
            <Cell>
                <InputNumber
                    min={0}
                    value={rowValue.quantity}
                    onChange={handleChangeAmount}
                    style={{ width: 100 }}
                />
                {rowError ? <ErrorMessage>{rowError.type.errorMessage}</ErrorMessage> : null}

                <RadioGroup appearance="picker" inline value={rowValue.type} onChange={handleChangeType}>
                    <span >Change status: </span>
                    <Radio value="Image">Image</Radio>
                    <Radio value="Video">Video</Radio>
                </RadioGroup>
            </Cell>*/}
        </tr>
    );
};
const ErrorMessage = ({children}) => <span style={{color: 'red'}}>{children}</span>;
const Cell = ({children, style, ...rest}) => (
    <td style={{padding: '2px 4px 2px 0', verticalAlign: 'top', ...style}} {...rest}>
        {children}
    </td>
);
