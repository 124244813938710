import React, {useEffect, useRef, useState} from 'react';
import {Button, ButtonToolbar, Col, Content, DatePicker, Divider, Form, Grid, Loader, Message, Row, SelectPicker, TagInput, toaster, Uploader} from "rsuite";
import {TextField} from "components/textField";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {formats, modules} from "components/profile/company/reactQuilConfig";
import 'react-phone-number-input/style.css'
import Avatar from "@rsuite/icons/legacy/Avatar";
import Config from "config.json";
import {Education} from "components/profile/jobSeeker/inputs/education";
import {Experience} from "components/profile/jobSeeker/inputs/experience";
import {Projects} from "components/profile/jobSeeker/inputs/projects";
import {getCurrentUser} from "service/auth.service";
import {getAllCountries, getCountryCities} from "service/setting.service";
import {getJobSeeker, updateJobSeeker} from "service/jobseeker.service";
import {Constants} from "lib/constants";
import {CustomSpinner} from "../../common/spinner";
import {JobSeekerFormModel} from "lib/formModels";
import {handleCvUpload, handleLogoUpload} from "../../../lib/utility";


function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result);
    };
    reader.readAsDataURL(file);
}

const styles = {
    width: 150,
    height: 150
};

const JobSeekerProfileForm = ({editOrNew = false, jse_id}) => {
    const formRef = useRef();
    const [user, setUser] = useState(getCurrentUser());
    const defaultFormValue = {
        jse_id,
        firstName: '',
        lastName: '',
        gender: '',
        phone: 0,
        email: '',
        image: '',
        description: '',
        dob: new Date(),
        currentPosition: '',
        currentSalary: 0,
        expectedSalary: 0,
        postalCode: '',
        website: '',
        log: 0,
        lat: 0,
        address: '',
        cv: '',
        city: 0,
        country: 0,
        education: [],
        experience: [],
        project: [],
        media: [{url: '', type: 'Image'}],
        twitterURL: '',
        youtubeURL: '',
        instaURL: '',
        fbURL: '',
        skill: [],

    }
    const [formError, setFormError] = useState({});
    const [gender, setGender] = useState(Constants.GENDER);
    const [city, setCity] = useState([]);
    const [country, setCountry] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [fileInfo, setFileInfo] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [cvFile, setCvFile] = useState(null);
    const [formValue, setFormValue] = useState(defaultFormValue);
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [extension, setExtension] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [isLogoUploaded, setIsLogoUploaded] = useState(editOrNew);
    const [isCvLoading, setIsCvLoading] = useState(false);
    const [isCvUploaded, setIsCvUploaded] = useState(editOrNew);


    const baseUrl = Config.BASE_API_URL;
    const upload_jobseeker_img_url = baseUrl + Config.JOBSEEKER_UPLOAD_IMAGE_URL
    const upload_jobseeker_cv_url = baseUrl + Config.JOBSEEKER_UPLOAD_CV_URL

    useEffect(async () => {
        setCountry(await getAllCountries())
        setLoading(true)
        const res = await getJobSeeker(jse_id, user.access_token)
        setCity(await getCountryCities(res.data.country))
        if (res.data.cv)
            setFileList([{url: res.data.cv, fileKey: 0, name: res.data.cv.split('/').pop()}])

        // console.log(res)
        setFormValue(res.data)
        setLoading(false)
    }, [])

    // console.log(formValue);
    const handleSubmit = async () => {

        setIsSubmitted(true)
        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }
        const res = await updateJobSeeker(formValue, user.access_token);

        // console.log(formValue, 'Form Value');

        if (res.status) {
            toaster.push(<Message type="success">Profile updated successfully</Message>);
            setCity(await getCountryCities(res.data.country))
            setFormValue(res.data)
            // console.log(res.data)
            setLoading(false)
        } else {
            toaster.push(<Message type="success">Error updating profile</Message>);
        }
        setIsSubmitted(false)

    };
    if (loading) return <CustomSpinner/>

    const handleChange = (name, value) => {
        setFormValue(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // console.log(jse_id, editOrNew,)

    return (
        <Content>
            <Grid fluid className='create-item-form' style={{marginLeft: 15}}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={JobSeekerFormModel}
                    disabled={isSubmitted}
                    fluid
                >

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Group controlId={'avatar'}>
                                <Form.ControlLabel>Profile Image </Form.ControlLabel>
                                <Uploader
                                    fileListVisible={false}
                                    accept={'image/*'}
                                    listType="picture"
                                    action={''}
                                    name={'image'}
                                    autoUpload={false}
                                    onChange={async (file) => {
                                        setIsLogoUploaded(false);
                                        const logo_file = file.pop()
                                        setLogoFile(logo_file)

                                        previewFile(logo_file.blobFile, value => setFileInfo(value))
                                    }}
                                >
                                    <button style={styles}>
                                        {uploading && <Loader backdrop center/>}
                                        {fileInfo ?
                                            (<img src={fileInfo} width="100%" height="100%" alt='...'/>)
                                            : formValue.image ?
                                                (<img src={formValue.image} width="70%" alt='...'/>)
                                                :
                                                (<Avatar style={{fontSize: 80}}/>)
                                        }

                                    </button>
                                </Uploader>
                                <br/>
                                <Button
                                    disabled={isLogoUploaded || !logoFile}
                                    onClick={async () => handleLogoUpload(setIsLogoLoading, setIsLogoUploaded, setFormValue, logoFile, 'image', user.access_token)}
                                    loading={isLogoLoading}
                                    style={{marginTop: 10, width: 'auto'}}
                                    className={'d-w-r-btn'}
                                >
                                    Start Upload
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="firstName" label="First Name" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="lastName" label="Last Name" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name='dob' format="yyyy-MM-dd" label="Date Of Birth" accepter={DatePicker}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="email" label="Email" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="website" label="Website" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="phone" label="Phone" type={'number'}  min={0} helpText='Required'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select Gender:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="gender" data={gender} accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>Gender Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="currentPosition" label="Current Position" helpText='Required'/>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <TextField name="currentSalary" label="Current Salary" type={'number'}  min={0} helpText='Required'/>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <TextField name="expectedSalary" label="Expected Salary" type={'number'}  min={0}
                                       helpText='Required'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Group controlId="textarea">
                                <Form.ControlLabel>Description</Form.ControlLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={formValue?.description}
                                    onChange={(e) => handleChange('description', e)}
                                    modules={modules}
                                    formats={formats}
                                    style={{marginRight: 25}}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="lat" label="Latitude" helpText='Required' type='number' min={0} step='any'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="log" label="Longitude" helpText='Required' type='number' min={0}/>

                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="postalCode" label="Postal Code" helpText='Required' type='number' min={0}/>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <TextField name="address" label="Address" helpText='Required'/>

                        </Col>

                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={6} lg={6}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select Country:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control
                                        name="country"
                                        data={country}
                                        accepter={SelectPicker}
                                        onChange={async (cou_id) => {
                                            setFormValue(prevState => ({
                                                ...prevState,
                                                city: '',
                                                country: cou_id
                                            }))
                                            setCity(await getCountryCities(cou_id))
                                        }}
                                    />
                                    <Form.HelpText tooltip>Country Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select City:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="city" data={city} accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>City Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Skills Here</h6>
                        <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <TextField name="skill" label="Skills" helpText='Required' accepter={TagInput}/>
                        </Col>

                    </Row>

                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Education Here</h6>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Control
                                name="education"
                                accepter={Education}
                                fieldError={formError.education}
                            />

                        </Col>

                    </Row>

                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Experience Here</h6>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Control
                                name="experience"
                                accepter={Experience}
                                fieldError={formError.experience}
                            />

                        </Col>

                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Projects Here</h6>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Control
                                name="project"
                                accepter={Projects}
                                fieldError={formError.project}
                            />

                        </Col>

                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>

                    <Row>

                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Social Links</h6>

                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="fbURL" label="Facebook's Link" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="instaURL" label="Instagram's Link" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="youtubeURL" label="Youtube's Link" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="twitterURL" label="Twitter's Link" helpText='Required'/>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>

                    <Row>

                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Curriculum Vitae</h6>

                        <Col xs={24} sm={24} md={6} lg={6}>

                            <Uploader
                                accept={'.pdf,.docx'}
                                // fileListVisible={false}
                                fileList={fileList}
                                action={''}
                                data={{jse_id}}
                                name={'cv'}
                                autoUpload={false}
                                onChange={async (file) => {
                                    setIsCvUploaded(false);
                                    if (file.length) {
                                        const cv_file = file.pop()
                                        setFileList([cv_file]);
                                        setCvFile(cv_file)
                                        setExtension(cv_file.name.split('.').pop())
                                        // setFileList(file)
                                    }


                                }}
                                renderFileInfo={(file, fileElement) => {
                                    return (
                                        <div>
                                            <span>File Name: {file.name}</span>
                                        </div>
                                    );
                                }}
                            >
                                <Button>Upload CV</Button>
                            </Uploader>
                            <br/>
                            <Button
                                disabled={isCvUploaded || !cvFile}
                                onClick={async () => handleCvUpload(setIsCvLoading, setIsCvUploaded, setFormValue, cvFile, extension, 'cv', user.access_token)}
                                loading={isCvLoading}
                                style={{marginTop: 10, width: 'auto'}}
                                className={'d-w-r-btn'}
                            >
                                Start Upload
                            </Button>


                        </Col>
                    </Row>


                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary"
                                // type="submit"
                                    className='d-w-r-btn'
                                    onClick={handleSubmit}
                                    style={{width: 'auto'}}
                                    disabled={Object.keys(formError).length !== 0 || isSubmitted || !isCvUploaded || !isLogoUploaded}
                                    loading={isSubmitted}
                            >Submit</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Grid>
        </Content>

    );
};

export default JobSeekerProfileForm;