import {useParams} from "react-router-dom";
import {Container, Divider, Panel} from "rsuite";
import React, {useEffect, useState} from "react";
import '../../styles/blogs.css'
import {CustomSpinner} from "../common/spinner";
import {getAnnouncementsDetail} from "../../service/announcement.service";
import DOMPurify from "dompurify";
import {ReadMore} from "../../lib/utility";

const BlogsDetailView = ({setHeader, NewsOrBlog = false}) => {
    const {ann_id} = useParams()

    const [resData, setResData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [purifiedHTML, setPurifiedHTML] = useState('');
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetchData()
    }, [ann_id]);

    const fetchData = async () => {
        setLoading(true);
        const response = await getAnnouncementsDetail(ann_id);
        setResData(response);
        if (!NewsOrBlog)
            setHeader(response?.title);
        setPurifiedHTML(DOMPurify.sanitize(response.description));
        setLoading(false);
    }

    if (loading) return <CustomSpinner/>;

    return (
        <Container className={"wrapper "}>
            <Divider style={{background: 'transparent', margin: '10px 0'}}/>

            {
                NewsOrBlog &&
                <div className={"border-default"} style={{background: 'white', textAlign: 'center', alignContent: 'center'}}>
                    <h1 className={'default-color'} style={{margin: '20px 0'}}>{resData.title}</h1>
                </div>
            }
            <Divider style={{background: 'transparent', margin: '10px 0'}}/>
            <Panel bodyFill>
                <div className={'blog-img-cover'}>
                    <img alt={resData.title} src={resData.img}/>
                </div>
                <Divider style={{background: 'transparent', margin: '10px 0'}}/>

                <ReadMore className={'all-blog-img-cover'} text={resData?.description} words={250}/>
            </Panel>
        </Container>
    )

}

export default BlogsDetailView;