import React from 'react';
import {Container, Content, Divider, Panel} from "rsuite";
import '../styles/resgister.css'


const About = () => {


    return (
        <Container className='wrapper '>
            <Content>
                <Divider style={{background: 'transparent'}}/>
                <div className='image-cover'>
                    <img src={'/about-cover.png'} alt=""/>
                </div>
                <Divider style={{background: 'transparent'}}/>
                <Panel header={<h1 style={{textAlign: 'center'}}>About Us</h1>}>
                    <p>
                        Edu Info is a mid-east-based venture that guides education seekers to the best educational
                        institutions in the USA. We have a list of high-class institutes that offer on-campus and online
                        courses.
                    </p>
                    <h4>What We Do</h4>
                    <p>
                        Edu Info aims to promote higher education in the youth. We connect the educators to the students
                        and
                        make their search for a good institute easier. We provide a list of online courses to choose
                        from
                        and enroll and a list of accredited educational institutes in the USA. We also aim to include
                        independent educators to enroll with us in the future and provide our students with their
                        tutorship
                        services and personally designed unique courses.
                    </p>
                    <h4>How We Do It</h4>
                    <p>
                        Edu Info brings together a list of universities, colleges, and schools in the USA. We make sure
                        that
                        the institutes we enlist are acclaimed and accredited by the legal authorities in the respective
                        cities. We cater to the institutes in the USA and London but we aim to include other countries
                        and
                        top-rated educational institutes of the world to our database in the future for our students.
                    </p>
                </Panel>
                <Panel header={<h1 style={{textAlign: 'center'}}>Vision and Mission</h1>}>
                    <h4>Our Vision</h4>
                    <p>
                        Open Youth of Mid-East to International Standards of Education
                        Our vision is to support a progressive and highly literate generation in the country that is
                        equipped with world-class educational standards and has exposure to the international
                        educational
                        system.
                    </p>
                    <h4>Our Mission</h4>
                    <p>
                        Connecting the Educators to the Knowledge Seekers
                        We make the connection easier, smarter, and faster by sorting out the relevant information
                        necessary
                        for our students to choose the best institute.
                    </p>
                </Panel>
                <Panel header={<h1 style={{textAlign: 'center'}}>Our Education Guide</h1>}>
                    <p>
                        Extending Educational Support Everywhere
                        We provide in-depth educational support to the students in Mid-east and abroad. Please talk to
                        our
                        educational consultants for a detailed discussion regarding the choice of your program, city,
                        and
                        institute you aim to enroll in
                    </p>
                </Panel>


            </Content>
        </Container>);
};

export default About;
