import { Button, ButtonGroup, Panel } from "rsuite";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LocationIcon from "@rsuite/icons/Location";
import { CustomSpinner } from "components/common/spinner";
import { Link } from "react-router-dom";
import { getAllCompanies } from "service/company.service";
import Config from "config.json";
import GlobalIcon from "@rsuite/icons/Global";
import {ArrowBack} from "@mui/icons-material";

export const TopUniversities = ({ type, name }) => {
  const [resData, setResData] = useState([]);
  const [loading, setLoading] = useState(false);

  const baseUrl = Config.BASE_API_URL;
  const getCompaniesUrl = baseUrl + Config.GET_COMPANIES_URL;
  // const params = `skip=0&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&companyType=${type}`
  const params = `skip=0&take=10&sortField=com_id&sortDirection=desc&cot_id=${type}`;
  const url = `${getCompaniesUrl}?${params}`;

  useEffect(async () => {
    setLoading(true);
    // await fetchData()
    const response = await getAllCompanies(url);
    setResData(response.status ? response.data.company : []);
    setLoading(false);
  }, []);

  if (loading) return <CustomSpinner />;

  return (
    <div className="top-universities">
      <div className="heading">
        <h2 className="default-color">TOP {name}</h2>
      </div>

      <Carousel
        additionalTransfrom={0}
        arrows={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass="container-customer-for-dots"
        // draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1080,
            },
            items: 4,
            partialVisibilityGutter: 30,
          },
          mobile: {
            breakpoint: {
              max: 725,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 70,
          },
          tablet: {
            breakpoint: {
              max: 1080,
              min: 725,
            },
            items: 3,
            partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
        sliderClass="2"
        slidesToSlide={1}
        // swipeable
      >
        {resData.map((data, index) => (
          <Panel
            key={index}
            shaded
            bordered
            bodyFill
            style={{
              display: "block",
              maxWidth: "100%",
                borderRadius:0,
              margin: "30px 30px 30px 30px",
            }}
          >
            <div
              className="image-cover"
              style={{
                height: "180px",
                padding: "0px",
                alignItems: "center",
                justifyContent: "center",
                maxHeigth:'220px'
              }}
            >
              <img
                key={index}
                src={`${data.logo}`}
                alt={data?.name}
                style={{
                  objectFit: "contain",
                  background: "transparent",
                  margin: 0,
                  padding: 0,
                  width: "180px",
                  maxHeight: "180px",
                  // minHeight: "5em",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                // height={"240px"}
                // width={"240px"}
              />
            </div>

            <Panel
              header={
                <p
                    style={{height:'60px'}}
                >
                  {data?.name}
                </p>
              }
            >
            <div>

              <div className="text-start ">
                <div style={{ marginBottom: "0px" }}>
                  <div className="icon_div">
                    <LocationIcon />
                  </div>
                  <div className="text_div">
                    <span>
                      {data.city.country.name}
                    </span>
                  </div>
                </div>
                  {/*{data.city.name}*/}
                  <div style={{ marginBottom: "0px" }}>
                  <div className="icon_div">
                    <GlobalIcon />
                  </div>
                  {/*<div className="text_div link-default">*/}
                    <a className="text_div link-default"  href={data.website} target="_blank"  rel="noreferrer">
                      Visit Website
                    </a>
                  {/*</div>*/}
                </div>
              </div>
            </div>
              <div className="default-bgcolor " style={{ marginTop: 10 }}>
                <ButtonGroup  justified>
                  <Button
                    // as={Link}
                    href={`mailto:${data.email}`}
                    // state={{com_id: data.com_id}}
                    className="d-r-w-btn"
                  >
                    Email
                  </Button>
                  <Button
                    as={Link}
                    to={`/company/detail/${data.com_id}`}
                    // state={{com_id: data.com_id}}
                    className="d-w-r-btn"
                  >
                    View
                  </Button>
                </ButtonGroup>
              </div>
            </Panel>
          </Panel>
        ))}
      </Carousel>
        <h6
            className="default-color"
            style={{
                textAlign: "center",
                textTransform: "uppercase",
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: "14pt",
            }}
        >
            <Link to={
                type==1 ? "/university" : "/schools"
                // "/university"
            }>See More</Link>
        </h6>
    </div>
  );
};
