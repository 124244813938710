import React, {useState} from 'react';
import {Avatar, Button, Container, Content, Divider, Nav, Panel, Stack} from "rsuite";
import '../../../styles/profile/company/companyHeader.css'
import ListIcon from '@rsuite/icons/List';
import AdminIcon from '@rsuite/icons/Admin';
import EditIcon from '@rsuite/icons/Edit';
import UserProfile from "components/profile/userProfile";
import ChangePassword from "components/profile/changePassword";
import Config from "../../../config.json";
import {getCurrentUser} from "service/auth.service";
import TutorCourse from "components/profile/tutor/tutorCourse";
import CoursesList from "components/profile/tutor/coursesListing";
import TutorProfile from "components/profile/tutor/tutorProfile";
import humanizeString from "../../../lib/utility";


function TutorHeader({data}) {
    const [valueNav, setValueNav] = useState('courses')

    const [user, setUser] = useState(getCurrentUser());
    const [editOrNew, setEditOrNew] = useState(false)
    const baseUrl = Config.BASE_API_URL;


    function SwitchCase({value}) {
        switch (value) {
            case 'courses':
                return <CoursesList/>;
            case 'profile':
                return <UserProfile/>;
            case 'tutorProfile':
                return <TutorProfile tou_id={user.tutor[0].tou_id} editOrNew={editOrNew}/>;
            case 'changePassword':
                return <ChangePassword/>;
            case 'addNew':
                return <TutorCourse tou_id={user.tutor[0].tou_id} editOrNew={editOrNew}/>;
            default:
                return <CoursesList/>;
        }
    }

    return (
        <Container>
            <Content>
                <Divider/>
                <Panel className='company-header-wrapper'>
                    <Stack spacing={20} className="header-flex-col">
                        <Avatar
                            size="lg"
                            src={user.image}
                            alt="User Profile"
                        />
                        <div>
                            <h2>{data?.firstname} {data?.lastname}</h2>
                            <h4>{data?.email}</h4>
                        </div>


                    </Stack>
                </Panel>
                <Divider style={{backgroundColor: 'var(--rs-divider-border)', margin: '12px 0'}}/>
                <Container>
                    <Panel>
                        <Nav justified className='company-header-nav' onSelect={setValueNav} activeKey={valueNav}>
                            <Nav.Item eventKey='courses'>
                                Listing
                            </Nav.Item>
                            <Nav.Item eventKey='tutorProfile' >Tutor Profile</Nav.Item>
                            <Nav.Item eventKey='profile' >User Profile</Nav.Item>
                            <Nav.Item eventKey='changePassword' >Change Password</Nav.Item>
                        </Nav>
                        <Divider/>
                        <Content style={{
                            backgroundColor: 'white',
                            padding: '15px',
                            boxShadow: 'var(--rs-panel-shadow)',
                            borderRadius: '3px'
                        }}>
                            <Stack className={'stack-last-item-right'}>

                                <h3 className='default-color'
                                    style={{margin: '15px', textTransform: 'uppercase', fontSize: '30px'}}>
                                    {humanizeString(valueNav)}
                                </h3>
                                {
                                    valueNav === 'courses' &&
                                    <Button
                                        disabled={!user?.tutor[0]?.isActive}
                                        onClick={() => setValueNav('addNew') }
                                        className='d-w-r-btn add-new-btn'>ADD NEW COURSE
                                    </Button>
                                }

                            </Stack>
                            <SwitchCase value={valueNav}/>
                        </Content>

                    </Panel>
                </Container>

            </Content>

        </Container>);
}

export default TutorHeader;