import React, {useState} from 'react';
import {Button, ButtonToolbar, Content, FlexboxGrid, Form, Message, Panel, toaster} from "rsuite";
import {TextField} from "components/textField";
import {ChangePasswordFormModel} from "lib/formModels";
import {resetPassword} from "service/auth.service";
import {useNavigate, useParams} from "react-router-dom";

function ResetPassword(props) {
    const {token} = useParams();
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const [formValue, setFormValue] = React.useState({newPassword: '', verifyNewPassword: '', token});


    const [isSubmitted, setIsSubmitted] = useState(false);
    console.log(token);
    let navigate = useNavigate();


    const handleSubmit = () => {
        setIsSubmitted(true);
        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }

        resetPassword(formValue).then(res => {
            if (res.status) {
                toaster.push(<Message type="success">Password changed successfully</Message>);
                navigate('/');
            } else {
                toaster.push(<Message type="success">unable to change password</Message>);
            }

        }).catch(err => {
            // setMessage(res.message);
            setFormError(err.response);
        });

        setIsSubmitted(false)

        console.log(formValue, 'Form Value');
    };


    return (
        <div className="wrapper login-page text-start">
            <Content>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={12}>
                        <Panel
                            // style={{backgroundColor:'#0C3144'}}
                            header={
                                <h3 className='default-color'>Reset Password</h3>
                            }
                            bordered shaded
                        >

                            <Form
                                ref={formRef}
                                onChange={setFormValue}
                                onCheck={setFormError}
                                formValue={formValue}
                                model={ChangePasswordFormModel}
                                disabled={isSubmitted}
                                fluid
                                className='Change-password-form'
                            >
                                <TextField name="newPassword" label="New Password"
                                           helpText='Password Should be greater than 6 characters' type="password"
                                           autoComplete="off"/>
                                <TextField name="verifyNewPassword" label="Verify New Password"
                                           helpText='These Fields Should Match ' type="password"
                                           autoComplete="off"/>
                                <Form.Group>
                                    <ButtonToolbar>
                                        <Button appearance="primary"
                                                className='d-w-r-btn'
                                                style={{width: 'auto'}}
                                            // type="submit"
                                                onClick={handleSubmit}
                                                disabled={Object.keys(formError).length !== 0 || isSubmitted}
                                                loading={isSubmitted}
                                        >Submit</Button>
                                    </ButtonToolbar>
                                </Form.Group>


                            </Form>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </div>


    );
}

export default ResetPassword;