import {Col, Divider, Panel, Row, Stack} from "rsuite";
import React, {useEffect, useState} from "react";
import {getAllAnnouncements} from "../../service/announcement.service";
import {CustomSpinner} from "../common/spinner";
import {Link} from "react-router-dom";
import {PaginationCustom} from "../common/paginationCustom";
import {timeSince} from "../../lib/utility";

const RecentNews = ({selectedCategory, setSelectedCategory}) => {


    const [resData, setResData] = useState([]);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false);

    const params = `type=NEWS&skip=${limit * (page - 1)}&take=${(limit * (page - 1)) + limit}&sortField=ann_id&sortDirection=desc&aty_id=${selectedCategory}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetchData()
    }, [limit, page]);

    const fetchData = async () => {
        setLoading(true);
        const response = await getAllAnnouncements(params);
        setResData(response.data);
        setCount(response.count);
        setLoading(false);
    }


    if (loading) return <CustomSpinner/>;
    return (
        <>

            <Row>

                {
                    resData.map((item, index) => (
                            <Col key={index} lg={12} md={12} sm={24} xs={24}>
                                <Panel shaded style={{background: 'white', margin: '8px 5px',borderRadius:0}}>
                                    <Stack as={Link} to={`/news/${item.ann_id}`} spacing={0} alignItems={'flex-start'}>
                                        <div className={"news-avatar-img-cover border-default "} style={{borderRadius: '2px', overflow: 'hidden', marginRight: '20px'}}>
                                            <img src={item.img} alt={item.title} height={'100px'}/>
                                        </div>
                                        <div>
                                            <h6 className={'default-color'}>
                                                {item.title}
                                            </h6>
                                            <p style={{margin: 0, fontSize: '.75rem'}}>
                                                Posted On: {' '}
                                                {
                                                    !timeSince(new Date(item.posted_on)) ?

                                                        new Date(item.posted_on).toLocaleDateString('en-us', {weekday: 'short', day: '2-digit', month: 'short', year: 'numeric'})
                                                        :
                                                        timeSince(new Date(item.posted_on))
                                                }
                                            </p>
                                        </div>
                                    </Stack>
                                </Panel>
                            </Col>
                        )
                    )
                }
            </Row>
            <Divider/>
            {
                count > 0 ?
                    <PaginationCustom count={count} limit={limit} page={page} setPage={setPage} setLimit={setLimit}/>
                    :
                    <div className={'text-center'}>
                        No News found
                    </div>
            }
            <Divider/>
        </>

    )

}

export default RecentNews;