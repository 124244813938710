import React from "react";
import NavbarComponent from "./nav/navbar";
import '../../styles/header.css'
import Topbar from "./nav/topbar";

const Header = () => {

    return (
        <div className="header-wrapper">
            <Topbar />
            <NavbarComponent />
        </div>

    );
};

export default Header;
