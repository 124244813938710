import React, {useState} from "react"
import {forgetpassword} from "service/auth.service"
import {Button, ButtonToolbar, Content, FlexboxGrid, Form, Message, Panel, toaster} from "rsuite";
import {ForgotPasswordFormModel} from "lib/formModels";
import {TextField} from "components/textField";
import '../styles/login.css'


export function ForgotPassword() {
    const defaultFormValue = {email: "", isForget: true}
    const [payload, setPayload] = useState({email: "", isForget: true})
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false)
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        toaster.clear()
        // console.log(payload);
        setMessage("");
        setLoading(true);
        const response = await forgetpassword(payload);
        if (response.status) {
            setMessage(null);
            setLoading(false);
            setPayload(defaultFormValue)
            toaster.push(<Message type="success">Email Sent successfully</Message>);
        } else {
            setIsError(true);
            setMessage(response.message);
            // toaster.push(<Message type="error">Email Doesn't Exist</Message>);
            setLoading(false);
        }
    }

    return (
        <div className="wrapper login-page text-start">
            <Content>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={12}>
                        <Panel
                            // style={{backgroundColor:'#0C3144'}}
                            header={
                                <h3 className='default-color'>Forget Password</h3>
                            }
                            bordered shaded
                        >


                            <Form
                                ref={formRef}
                                onChange={setPayload}
                                onCheck={setFormError}
                                formValue={payload}
                                model={ForgotPasswordFormModel}
                                fluid
                            >
                                {/*<Form.ControlLabel>Kindly provide your Valid Email Address. You will receive a link to reset password</Form.ControlLabel>*/}

                                <TextField name="email" label="Email" helpText='Kindly provide your Valid Email Address. You will receive a link to reset password'/>
                                {/*<TextField name="password" type='password' label="Password" helpText='Required'/>*/}
                                {
                                    isError &&

                                    <Message
                                        duration={0}
                                        closable
                                        showIcon
                                        type="error"
                                        // header="Error"
                                        onClose={() => setIsError(false)}
                                    >
                                        Email Doesn't Exist
                                    </Message>
                                }

                                <Form.Group
                                    style={{marginTop: '20px'}}
                                >
                                    <ButtonToolbar>
                                        <Button
                                            appearance="primary"
                                            // disabled={loading}
                                            onClick={handleSubmit}
                                            className="default-bgcolor login-btn"
                                            disabled={Object.keys(formError).length !== 0 || loading}
                                            loading={loading}
                                        >{loading ? 'Submitting' : 'Submit'}
                                        </Button>

                                    </ButtonToolbar>
                                </Form.Group>


                            </Form>
                            {/*<div style={{display: 'none'}}>
                                {message &&
                                    toaster.push(
                                        (
                                            <NotificationCustom type='error'>
                                                {message} {setMessage(null)}
                                            </NotificationCustom>
                                        )
                                        , {placement: 'topStart'})}
                            </div>*/}

                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </div>
    );
}