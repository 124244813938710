import React from 'react';
import Carousel from "react-multi-carousel";
import {Panel} from "rsuite";
import Config from "config.json";
import ReactHtmlParser from "react-html-parser";


const baseUrl = Config.BASE_API_URL;

function Ticket({data}) {

    if (data.length === 0) return "No Tickets Found"
    return (
        <div>
            <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1080
                        },
                        items: 3,
                        partialVisibilityGutter: 50
                    },
                    mobile: {
                        breakpoint: {
                            max: 725,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1080,
                            min: 725
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >

                {data.map((data, index) =>

                    <Panel key={index} shaded bordered bodyFill
                           style={{display: 'inline-block', width: '85%', margin: "30px 0 60px 0"}}>

                        <Panel header={<h5 className='default-bgcolor' style={{
                            color: 'white',
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            padding: '10px'
                        }}>{data?.name}</h5>}
                        >
                            <p>{data.price} $</p>
                                {ReactHtmlParser(data?.description)}
                        </Panel>
                    </Panel>
                )}
            </Carousel>

        </div>
    );
}

export default Ticket;
