import Config from "../config.json";

const baseUrl = Config.BASE_API_URL;
const getBlogsUrl = baseUrl + Config.GET_BLOGS_URL;
const getAnnCatUrl = baseUrl + Config.GET_ANN_CAT_URL;

const getCompanyTypeUrl = baseUrl + Config.GET_COMPANY_TYPE_URL


export async function getAllAnnouncements(params) {
    const response = await fetch(`${getBlogsUrl}?${params}`)
    const temp = await response.json();
    if (temp.status) {
        return {data: temp.data.annData, count: temp.data.count};
    }
    return {data: [], count: 0};
}
export async function getAllAnnCat() {
    const response = await fetch(`${getAnnCatUrl}`)
    const temp = await response.json();
    if (temp.status) {
        return {data: temp.data.annData, count: temp.data.count};
    }
    return {data: [], count: 0};
}

// export async function getAnnouncements(com_id, token) {
//     const url = getBlogsUrl + '/' + com_id;
//     const response = await fetch(url, {
//         method: 'GET',
//         headers:  getHeader(token)
//     })
//
//     return await response.json();
// }
export async function getAnnouncementsDetail(ann_id) {
    const url = getBlogsUrl + '/' + ann_id;
    const response = await fetch(url)

    let tempData = await response.json();
    if (tempData.status) {
        return tempData.data;
    }

    return tempData


    // return await response.json();
}

export async function getAnnouncementsType(token = '') {

    const response = await fetch(getCompanyTypeUrl, {
        method: 'GET',
        headers: getHeader(token)
    })

    const responseData = await response.json();
    if (!responseData.status)
        return []

    return responseData.data.map(item => {
        return {value: item.cot_id, label: item.name}
    })

}


function getHeader(token = '') {
    return {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

function getMultipartHeader(token = '') {
    return {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
    }
}


export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
