import Config from "../config.json";

const baseUrl = Config.BASE_API_URL;
const verifyEmail = Config.VERIFY_EMAIL



export async function VerifyEmail(token) {

    // console.log(token);

    const url = `${baseUrl}${verifyEmail}?token=${token}`


    const response = await fetch(url)

    return  await response.json();

}