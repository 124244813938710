import { Button, Panel } from "rsuite";
import React, { useEffect, useState } from "react";
import { getAllAnnouncements } from "../../service/announcement.service";
import { CustomSpinner } from "../common/spinner";
import { Link } from "react-router-dom";

const RecentBlogs = () => {
  const [resData, setResData] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = `type=BLOG&skip=0&take=5&sortField=ann_id&sortDirection=desc`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const response = await getAllAnnouncements(params);
    setResData(response.data);
    setLoading(false);
  };
  // if (loading) return <CustomSpinner/>;
  return (
    <Panel header={<h5 className={"border-bottom"}>Recent Blogs</h5>}>
      {loading && <CustomSpinner />}
      {resData &&
        resData?.map((post, index) => {
          return (
            <Button
              as={Link}
              className={"shadow category-button"}
              to={`/blogs/${post.ann_id}`}
            >
              {post.title}
            </Button>
          );
        })}
    </Panel>
  );
};

export default RecentBlogs;
