import React, {useState} from 'react';
import {
    Button,
    ButtonToolbar,
    Col,
    Content,
    DatePicker,
    Divider,
    Form,
    Grid,
    Message,
    Row, SelectPicker,
    toaster,
    Uploader
} from "rsuite";
import {TextField} from "components/textField";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {formats, modules} from "components/profile/company/reactQuilConfig";
import 'react-phone-number-input/style.css'
import {MediaUrlInputControl} from "components/profile/company/inputs/addMediaURLInput";
import {CourseAvailability} from "components/profile/company/inputs/courseAvailability";
import {createCourse} from "service/course.service";
import {getCurrentUser} from "service/auth.service";
import {getBase64, toTitle} from "service/until.service";
import {v4 as uuidv4} from "uuid";
import {CompanyCourseFormModel} from "lib/formModels";
import {handleMediaDocUpload, isImage} from "../../../lib/utility";
import {isBefore} from "rsuite/cjs/utils/dateUtils";
import {Constants} from "../../../lib/constants";
import {ErrorMessageImages} from "../../common/errorMessageImages";
var date = new Date();

const TutorCourse = ({editOrNew = false, tou_id}) => {
    const formRef = React.useRef();
    const [user, setUser] = useState(getCurrentUser());
    const defaultFormValue = {
        tou_id,
        name: '',
        price: 0,
        description: '',
        language: '',
        website: '',
        duration: '',
        offeredAt: '',
        startDate: new Date(),
        endDate: new Date(),
        mediaUrl: [{path: '', mediaType: 'Video'}],
        courseAvailability: [new Date()],
        mediaDoc: []
    }
    const [formError, setFormError] = React.useState({});
    const [formValue, setFormValue] = React.useState(defaultFormValue);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mediaDocs, setMediaDocs] = useState([]);
    const [isMediaDocLoading, setIsMediaDocLoading] = useState(false);
    const [isMediaDocUploaded, setIsMediaDocUploaded] = useState(editOrNew);
const [isImageType, setIsImageType] = useState(true);
    const [fileList, setFileList] = useState([]);


    const handleSubmit = async () => {
        setIsSubmitted(true)
        if (!formRef.current.check()) {
            console.error('Form Error');
            setIsSubmitted(false)
            return;
        }

        const response = await createCourse(formValue, user.access_token)

        if (response.status) {
            toaster.push(<Message type="success">Course Created successfully</Message>);
            setFormValue(defaultFormValue)
        } else {
            toaster.push(<Message type="error">{response.message}</Message>);
        }

        setIsSubmitted(false)

        // console.log(formValue, 'Form Value');
    };

    const handleChange = (name, value) => {
        setFormValue(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    // console.log(formError)
    // console.log(formValue)


    return (
        <Content>
            <Grid fluid className='create-item-form' style={{marginLeft: 15}}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    disabled={isSubmitted}
                    model={CompanyCourseFormModel(formValue,editOrNew)}
                    fluid
                >

                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="name" label="Course Title" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="language" label="Language" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="price" label="Price" type={'number'}  min={0} helpText='Required'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="website" label="URL" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="duration" min={1} label="Duration" type={'number'} helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Offered At</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="offeredAt" data={Constants.OFFERED_AT} accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Group controlId="textarea">
                                <Form.ControlLabel>Description</Form.ControlLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={formValue?.description}
                                    onChange={(e) => handleChange('description', e)}
                                    modules={modules}
                                    formats={formats}
                                    style={{marginRight: 25}}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '12px 0'}}/>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name='startDate' format="yyyy-MM-dd"  disabledDate={date => isBefore(date, new Date(Date.now() - (3600 * 1000 * 24)))} label="Start Date" accepter={DatePicker}/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name='endDate' format="yyyy-MM-dd" disabledDate={date => isBefore(date, formValue.startDate)} label="End Date" accepter={DatePicker}/>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Images and Videos</h6>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Control
                                style={{width: 'inherit'}}
                                name="mediaUrl"
                                accepter={MediaUrlInputControl}
                                fieldError={formError.mediaUrl}
                            />

                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Group controlId="uploader" style={{marginRight: '25px'}}
                            >
                                <Form.ControlLabel>Images</Form.ControlLabel>
                                {/*<Uploader
                                    action=""
                                    draggable
                                    autoUpload={false}
                                    multiple
                                    accept={'image/*,video/*'}
                                    name="mediaDoc"
                                    onChange={async (file) => {
                                        let mediaDocData = []
                                        for (const f of file) {
                                            mediaDocData.push({
                                                base64: await getBase64(f.blobFile),
                                                name: uuidv4(),
                                                mediaType: toTitle(f.blobFile.type.split('/')[0]),
                                                extension: f.blobFile.type.split('/')[1],
                                                size: f.blobFile.size,
                                            })
                                        }
                                        setFormValue({...formValue, mediaDoc: mediaDocData})
                                    }}
                                >
                                    <div style={{lineHeight: '200px'}}>Click or Drag images to this area to upload</div>
                                </Uploader>*/}
                                <Uploader
                                    action=""
                                    fileList={fileList}
                                    listType="picture"
                                    draggable
                                    disabled={fileList.length >= 5}
                                    autoUpload={false}
                                    multiple={false}
                                    accept={'image/*'}
                                    name="mediaDoc"
                                    onRemove={(file) => {
                                                                                setIsImageType(isImage(file));
                                        setFileList(fileList.filter(f => f.fileKey !== file.fileKey));
                                        setFormValue({...formValue, mediaDoc: fileList.filter(f => f.fileKey !== file.fileKey)})
                                    }}
                                    onChange={async (files) => {
                                        // console.log(files, 'files')
                                        setFileList(files);
                                        let mediaDocData = []
                                        for (const f of files) {
                                            if (isImage(f)) {
                                                if (f.blobFile) {
                                                    setIsMediaDocUploaded(false);
                                                    mediaDocData.push({file: f, isNew: true})
                                                } else {
                                                    mediaDocData.push({...f, isNew: false})
                                                }
                                                setIsImageType(true);

                                            } else {

                                                toaster.push(<Message type="error">`${f.name} filetype is not Allowed` </Message>, {
                                                    duration: 7000,
                                                    position: 'top-right',
                                                    type: 'danger'
                                                })
                                                setIsImageType(false);
                                            }
                                        }
                                            setMediaDocs(mediaDocData)

                                        // setFormValue({...formValue, mediaDoc: mediaDocData})
                                    }}
                                    renderFileInfo={(file, fileElement) => {
                                        return (
                                            <div>
                                                <span>File Name: {file.name}</span>
                                            </div>
                                        );
                                    }}
                                >
                                    <div style={{lineHeight: '200px'}}>Click or Drag images to this area to upload
                                    </div>
                                </Uploader>
                                <br/>
                                <Button
                                    style={{marginTop: 10, width: 'auto'}}
                                    className={'d-w-r-btn'}
                                    disabled={isMediaDocUploaded || !mediaDocs.length}
                                    onClick={async () => handleMediaDocUpload(setIsMediaDocLoading, setIsMediaDocUploaded, setFormValue, mediaDocs, user.access_token)}
                                    loading={isMediaDocLoading}
                                >
                                    Start Upload
                                </Button>
                                {
                                    !isImageType &&
                                   <ErrorMessageImages/>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Availability</h6>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Control
                                name="courseAvailability"
                                accepter={CourseAvailability}
                                fieldError={formError.courseAvailability}
                            />

                        </Col>

                    </Row>

                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary"
                                // type="submit"
                                    className='d-w-r-btn'
                                    onClick={handleSubmit}
                                    style={{width: 'auto'}}
                                    disabled={Object.keys(formError).length !== 0 || isSubmitted || !isMediaDocUploaded}
                                    loading={isSubmitted}
                            >Submit</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Grid>
        </Content>

    );
};

export default TutorCourse;