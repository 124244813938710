import Config from "../config.json";

const baseUrl = Config.BASE_API_URL;
const getCountriesUrl = baseUrl + Config.GET_COUNTRIES_URL
const getCitiesUrl = baseUrl + Config.GET_CITIES_URL


export async function getAllCountries(token) {

    const response = await fetch(getCountriesUrl, {
        method: 'GET',
        headers:  getHeader(token)
    })

    const responseData = await response.json();

    if (!responseData.status)
        return []

    return  responseData.data.map(item => {return {value: item.cou_id, label: item.name}})
}

export async function getCountryCities(cou_id,token) {
    const url = `${getCitiesUrl}?cou_id=${cou_id}`;
    const response = await fetch(url, {
        method: 'GET',
        headers:  getHeader(token)
    })

    const responseData = await response.json();

    if (!responseData.status)
        return []

    return  responseData.data.map(item => {return {value: item.cit_id, label: item.name}})
}


export async function getAllCities(token) {

    const response = await fetch(getCitiesUrl, {
        method: 'GET',
        headers:  getHeader(token)
    })

    const responseData = await response.json();

    if (!responseData.status)
        return []

    return  responseData.data.map(item => {return {value: item.id, label: item.name}})
}

function getHeader(token = '') {
    return {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
