import {Button, Panel} from "rsuite";
import {useEffect, useState} from "react";
import {getAllAnnCat} from "../../service/announcement.service";
import {CustomSpinner} from "../common/spinner";
import {Link} from "react-router-dom";

const Categories = ({selectedCategory, setSelectedCategory}) => {


    const [resData, setResData] = useState([]);
    const [loading, setLoading] = useState(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetchData()
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const response = await getAllAnnCat();
        setResData(response.data);
        setLoading(false);
    }

    if (loading) return <CustomSpinner/>;
    return (
        <Panel header={<h5 className={'border-bottom'}>Categories</h5>}>
            <Button
                as={Link}
                to={'/blogs'}
                onClick={() => setSelectedCategory('undefined')}
                className={' shadow category-button'}>
                All Categories
            </Button>
            {
                resData && resData?.map((category, index) => {
                    return (
                        <Button
                            as={Link}
                            to={'/blogs'}
                            onClick={() => setSelectedCategory(category.act_id)}
                            className={' shadow category-button'} key={index}>
                            {category.name}
                        </Button>
                    )
                })
            }
        </Panel>
    )

}

export default Categories;