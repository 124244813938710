export const modules = {
    toolbar: [
        // [{ 'font': [] }],
        // [{ 'align': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        // [{ 'script': 'sub'}, { 'script': 'super' }],
        // [{ 'color': [] }, { 'background': [] }],
        ['clean'],
    ],
}

export const    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]