import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonToolbar,
    Col,
    Content,
    Divider,
    Form,
    Grid,
    Loader,
    Message,
    Row,
    SelectPicker,
    toaster,
    Uploader
} from "rsuite";
import {TextField} from "components/textField";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {formats, modules} from "components/profile/company/reactQuilConfig";
import 'react-phone-number-input/style.css'
import {getCurrentUser, getHeaderToken} from "service/auth.service";
import Avatar from "@rsuite/icons/legacy/Avatar";
import Config from "config.json";
import {getTutor, updateTutor} from "service/tutor.service";
import {getAllCountries, getCountryCities} from "service/setting.service";
import {Constants} from "lib/constants";
import {CustomSpinner} from "components/common/spinner";
import {TutorProfileFormModel} from "lib/formModels";
import {handleLogoUpload} from "../../../lib/utility";


function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result);
    };
    reader.readAsDataURL(file);
}

const styles = {
    width: 150,
    height: 150
};

const TutorProfile = ({editOrNew = false, tou_id}) => {
    const [user, setUser] = useState(getCurrentUser());
    const defaultFormValue = {
        tou_id,
        firstName: '',
        lastName: '',
        phone: 0,
        gender: '',
        description: '',
        email: '',
        qualification: '',
        address: '',
        postalCode: '',
        website: '',
        experience: 0,
        log: 0,
        lat: 0,
        city: '',
        country: '',
        image: '',
    }
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const [gender, setGender] = useState(Constants.GENDER);
    const [city, setCity] = useState([]);
    const [country, setCountry] = useState([]);
    const [uploading, setUploading] = React.useState(false);
    const [fileInfo, setFileInfo] = React.useState(null);
    const [formValue, setFormValue] = React.useState(defaultFormValue);
    const [loading, setLoading] = useState(false)
    const [logoFile, setLogoFile] = useState(null);
    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [isLogoUploaded, setIsLogoUploaded] = useState(editOrNew);
    const [isSubmitted,setIsSubmitted] = useState(false);


    const baseUrl = Config.BASE_API_URL;
    const upload_tutor_img_url = baseUrl + Config.TUTOR_UPLOAD_IMAGE_URL


    useEffect(async () => {
        setCountry(await getAllCountries())
        setLoading(true)
        const res = await getTutor(tou_id, user.access_token)
        setCity(await getCountryCities(res.data.country === undefined ? 0 : res.data.country))
        setFormValue(res.data)
        setLoading(false)
    }, [])

    const handleSubmit = async () => {
        setIsSubmitted(true)
        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }

        const res = await updateTutor(formValue, user.access_token);

        if (res.status) {
            toaster.push(<Message type="success">Profile updated successfully</Message>);
            setCity(await getCountryCities(res.data.country))
            setFormValue(res.data)
            setLoading(false)
        } else {
            toaster.push(<Message type="success">Error updating profile</Message>);
        }

        setIsSubmitted(false)
        // console.log(formValue, 'Form Value');
    };
        // console.log(formValue, 'Form Value');
        // console.log(formError, 'Form Error');

    const handleChange = (name, value) => {
        setFormValue(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // console.log(tou_id, editOrNew)

    if (loading) return <CustomSpinner/>

    return (
        <Content>
            <Grid fluid className='create-item-form' style={{marginLeft: 15}}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={TutorProfileFormModel}
                    fluid
                >

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Group controlId={'avatar'}>
                                <Form.ControlLabel>Profile Image </Form.ControlLabel>
                                <Uploader
                                    fileListVisible={false}
                                    accept={'image/*'}
                                    listType="picture"
                                    action={''}
                                    data={{tou_id}}
                                    name={'image'}
                                    onChange={async (file) => {
                                        setIsLogoUploaded(false);
                                        const logo_file = file.pop()
                                        setLogoFile(logo_file)

                                        previewFile(logo_file.blobFile, value => setFileInfo(value))
                                    }}

                                >
                                    <button style={styles}>
                                        {uploading && <Loader backdrop center/>}
                                        {fileInfo ?
                                            (<img src={fileInfo} width="100%" height="100%" alt='...'/>)
                                            : formValue.image ?
                                                (<img src={formValue.image} width="70%" alt='...'/>)
                                                :
                                                (<Avatar style={{fontSize: 80}}/>)
                                        }

                                    </button>
                                </Uploader>
                                <br />
                                <Button
                                    disabled={isLogoUploaded || !logoFile}
                                    onClick={async () => handleLogoUpload(setIsLogoLoading, setIsLogoUploaded, setFormValue, logoFile,'image', user.access_token)}
                                    loading={isLogoLoading}
                                    style={{marginTop: 10, width: 'auto'}}
                                    className={'d-w-r-btn'}
                                >
                                    Start Upload
                                </Button>


                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="firstName" label="First Name" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="lastName" label="Last Name" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="email" label="Email" helpText='Required'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="website" label="Website" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="experience" label="Experience" type={'number'}  min={0} helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="qualification" label="Qualification" helpText='Required'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="phone" label="Phone" type={'number'}  min={0} helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select Gender:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="gender" data={gender} accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>Gender Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Group controlId="textarea">
                                <Form.ControlLabel>Description</Form.ControlLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={formValue?.description}
                                    onChange={(e) => handleChange('description', e)}
                                    modules={modules}
                                    formats={formats}
                                    style={{marginRight: 25}}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '12px 0'}}/>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="lat" label="Latitude" helpText='Required' type='number' min={0} step='any'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="log" label="Longitude" helpText='Required' type='number' min={0}/>

                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="postalCode" label="Postal Code" helpText='Required' type='number' min={0}/>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <TextField name="address" label="Address" helpText='Required'/>

                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select Country:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control
                                        name="country"
                                        data={country}
                                        accepter={SelectPicker}
                                        onChange={async (cou_id) => {
                                            setFormValue(prevState => ({
                                                ...prevState,
                                                city: '',
                                                country: cou_id
                                            }))
                                            setCity(await getCountryCities(cou_id))
                                        }}
                                    />
                                    <Form.HelpText tooltip>Country Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select City:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="city" data={city} accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>City Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/*
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Images and Videos</h6>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Control
                                style={{width: 'inherit'}}
                                name="media"
                                accepter={MediaUrlInputControl}
                                fieldError={formError.media}
                            />

                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Group controlId="uploader" style={{marginRight: '25px'}}
                            >
                                <Form.ControlLabel>Images</Form.ControlLabel>
                                <Uploader action="//jsonplaceholder.typicode.com/posts/" draggable autoUpload={false}
                                          multiple={false} accept={'image/*'}>
                                    <div style={{lineHeight: '200px'}}>Click or Drag images to this area to upload</div>
                                </Uploader>
                            </Form.Group>
                        </Col>

                    </Row>
*/}

                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary"
                                // type="submit"
                                    className='d-w-r-btn'
                                    onClick={handleSubmit}
                                    style={{width: 'auto'}}
                                    disabled={Object.keys(formError).length !== 0 || isSubmitted || !isLogoUploaded}
                            >Submit</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Grid>
        </Content>

    );
};

export default TutorProfile;