import Config from "../config.json";
import {convertMediaToArray} from "lib/utility";

const baseUrl = Config.BASE_API_URL;
const getEventsUrl = baseUrl + Config.GET_EVENTS_URL
const createEventUrl = baseUrl + Config.CREATE_EVENT_URL
const updateEventUrl = baseUrl + Config.UPDATE_EVENT_URL
const getEventTypeUrl = baseUrl + Config.GET_EVENT_TYPE_URL
const getEventCategoryUrl = baseUrl + Config.GET_EVENT_CATEGORY_URL
const getEventSubCategoryUrl = baseUrl + Config.GET_EVENT_SUBCATEGORY_URL

export async function getEventType() {

    const response = await fetch(getEventTypeUrl, {
        method: 'GET',
        headers:  getHeader()
    })

    const responseData = await response.json();

    if (!responseData.status)
        return []

    return  responseData.data.map(item => {return {value: item.evt_id, label: item.name}})
}

export async function getEventCategory() {

    const response = await fetch(getEventCategoryUrl, {
        method: 'GET',
        headers:  getHeader()
    })

    const responseData = await response.json();

    if (!responseData.status)
        return []

    return  responseData.data.map(item => {return {value: item.evc_id, label: item.name}})
}

export async function getEventSubCategory(evc_id = null) {

    const url = evc_id ? `${getEventSubCategoryUrl}?evc_id=${evc_id}` : getEventSubCategoryUrl

    const response = await fetch(url, {
        method: 'GET',
        headers:  getHeader()
    })

    const responseData = await response.json();

    if (!responseData.status)
        return []

    return  responseData.data.map(item => {return {value: item.evs_id, label: item.name}})
}


export async function getEvents(url, token) {

    const response = await fetch(url, {
        method: 'GET',
        headers:  getHeader(token)
    })

    return await response.json();
}

export async function getAllEvents(url) {

    const response = await fetch(url)

    return await response.json();
}

export async function getEventDetail(url) {

    const response = await fetch(url)

    let tempData=await response.json();
    tempData.data.media =  convertMediaToArray(tempData.data.event_mediadoc)

    return  tempData
}

export async function getEventDetail_edt(eve_id) {

    const response = await fetch(`${getEventsUrl}/${eve_id}`)

    let response_josn = await response.json()

    if (response_josn.status) {
        const cit_id = response_josn?.data?.city?.cit_id
        const cou_id = response_josn?.data?.city?.country?.cou_id
        const evc_id = response_josn?.data?.eventsubcategory?.evc_id
        response_josn.data.city = cit_id
        response_josn.data.country = cou_id
        response_josn.data.evc_id = evc_id

        return response_josn;
    } else {
        return response_josn;
    }
}


export async function createEvent(payload,token) {

    const response = await fetch(createEventUrl, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader(token)
    })

    return  await response.json();
}

export async function updateEvent(payload,token) {

    const response = await fetch(updateEventUrl, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader(token)
    })

    return  await response.json();
}


function getHeader(token = '') {
    return {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
