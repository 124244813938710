import {Divider, Panel} from "rsuite";
import {Link} from "react-router-dom";
import DOMPurify from 'dompurify';
import ReactHtmlParser from "react-html-parser";
import {useEffect, useState} from "react";


const BlogCard = props => {

    const [purifiedHTML, setPurifiedHTML] = useState('');
    useEffect(() => {
        setPurifiedHTML(DOMPurify.sanitize(props.description));
    }, [])

    return (
        <Panel className={'blogs-card-container'}  {...props} header={<h5 className={'default-color'}>{props?.header}</h5>}>
            <Link to={`/blogs/${props.id}`}>
                <Divider style={{background: 'transparent', margin: '5px 0'}}/>
                <div className={'blog-img-cover'}>
                    <img alt={props.header} src={props?.img} height={240}/>
                </div>
                <Divider style={{background: 'transparent', margin: '10px 0'}}/>
                <p>
                    <small>
                        {ReactHtmlParser(purifiedHTML.slice(0, 200))}
                    </small>
                </p>
            </Link>
            <Divider style={{background: '#0C3144', margin: '10px 0'}}/>
            <div className={'flex-end-space-bw'} >
                <p>
                    Category: <small className={"default-color"}>
                    {props.category.name}
                </small>
                </p>
                <p>
                    Read Time: <small className={"default-color"}>
                    {props.read_time} mins
                </small>
                </p>

            </div>
            {/*<p>*/
            }
            {/*    Posted By: <small className={"default-color"}>*/
            }
            {/*    {props.postedBy}*/
            }
            {/*</small>*/
            }
            {/*</p>*/
            }

        </Panel>
    )
}

export default BlogCard;