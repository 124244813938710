import {generateS3BucketExtensionUrl, generateS3BucketUrl, uploadTOS3BucketUrl} from "../service/s3";
import {Message, toaster} from "rsuite";
import React, {useState} from "react";
import ReactHtmlParser from "react-html-parser";


export const fetcher = (...args) => fetch(...args).then(res => res.json())

export const dateOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
export const dateOptionsYYMMDD = {year: 'numeric', month: 'long', day: 'numeric'};
export const dateTimeOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
export const dateTimeNumericOptions = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};

export const ReadMore = ({text, words = 400, className = 'text'}) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => setIsReadMore(!isReadMore);

    const textSliced = isReadMore ? text?.slice(0, words) : text;
    return (
        <p className={className}>
            {ReactHtmlParser(textSliced)}
            <span onClick={toggleReadMore} className="read-or-hide">{isReadMore ? "... read more" : " show less"}</span>
        </p>
    );
};


export function convertMediaToArray(data) {
    var images = [];
    var videos = [];
    for (let i = 0; i < data.length; i++) {

        if (data[i].mediadoc.mediaType === 'Image') {
            images.push(data[i].mediadoc.path)
        } else if (data[i].mediadoc.mediaType === 'Video') {
            videos.push(data[i].mediadoc.path)
        }
    }

    if (images.length === 0) {
        images.push('/images/noImageFound.png')
    }
    return {images, videos}
}

export async function uploadFileTOS3Bucket(file, url) {
    const responseUpload = await uploadTOS3BucketUrl(file?.blobFile, url)
    if (responseUpload.status) {
        const logoUrl = url.split('?')[0]
        return {status: true, message: "Success uploaded", data: logoUrl}
    } else {
        return {status: false, message: "Unable to upload", data: ''}
    }
}


export const handleLogoUpload = async (setIsLogoLoading, setIsLogoUploaded, setFormValue, logoFile, stateName, token) => {
    setIsLogoLoading(true);

    const responseGenrate = await generateS3BucketUrl(1, token);

    if (responseGenrate.status) {
        const url = responseGenrate.data[0]
        const response = await uploadFileTOS3Bucket(logoFile, url);

        if (response.status) {
            const url = response.data
            console.log(url);
            setFormValue(prevState => ({...prevState, [stateName]: url}));
            // setFormValue({...formValue, logo: url})
            toaster.push(<Message type="success">{'Successfully uploaded'}</Message>);
        } else {
            toaster.push(<Message type="error">{'Unable To Upload: Please upload different file'}</Message>);
        }
        setIsLogoUploaded(response.status);
    } else {
        setIsLogoUploaded(false);
        toaster.push(<Message type="error">{'Unable To Upload: Please upload different file'}</Message>);
    }

    setIsLogoLoading(false);
}

export const handleCvUpload = async (setIsCvLoading, setIsCvUploaded, setFormValue, file, extension, stateName, token) => {
    setIsCvLoading(true);

    const responseGenerate = await generateS3BucketExtensionUrl(1, extension, token);

    if (responseGenerate.status) {
        const url = responseGenerate.data[0]
        const response = await uploadFileTOS3Bucket(file, url);

        if (response.status) {
            const url = response.data
            console.log(url);
            setFormValue(prevState => ({...prevState, [stateName]: url}));
            // setFormValue({...formValue, logo: url})
            toaster.push(<Message type="success">{'Successfully uploaded'}</Message>);
        } else {
            toaster.push(<Message type="error">{'Unable To Upload: Please upload different file'}</Message>);
        }
        setIsCvUploaded(response.status);
    } else {
        setIsCvUploaded(false);
        toaster.push(<Message type="error">{'Unable To Upload: Please upload different file'}</Message>);
    }

    setIsCvLoading(false);
}


export const handleMediaDocUpload = async (setIsMediaDocLoading, setIsMediaDocUploaded, setFormValue, fileList, token) => {
    setIsMediaDocLoading(true);


    console.log(fileList);
    const filteredFileListNew = fileList.filter(fileData => fileData?.isNew && fileData.file?.blobFile.type.includes('image'));
    const filteredFileListOld = fileList.filter(fileData => !fileData?.isNew);
    console.log(filteredFileListOld);


    const responseGenrated = await generateS3BucketUrl(filteredFileListNew.length, token);
    const mediaDocs = [];

    if (responseGenrated.status) {
        for (let i = 0; i < filteredFileListNew.length; i++) {
            const file = filteredFileListNew[i].file
            const url = responseGenrated.data[i]

            const responseFile = await uploadFileTOS3Bucket(file, url);

            if (responseFile.status) {
                const url = responseFile.data
                console.log(url, file);
                mediaDocs.push({url, isNew: true, name: url.split('/').pop(), type: 'Image'})
                // toaster.push(<Message type="success">{`Successfully Uploaded ${file?.name}`}</Message>);
            } else {
                toaster.push(<Message type="error">{`Unable To Upload: ${file?.name}`}</Message>);
            }
        }

        mediaDocs.push(...filteredFileListOld)

        setFormValue(prevState => ({...prevState, mediaDoc: mediaDocs}));
        toaster.push(<Message type="success">{'Successfully Uploaded'}</Message>);
    } else {
        toaster.push(<Message type="error">{'Unable To Upload: Please upload different file'}</Message>);
    }

    setIsMediaDocUploaded(responseGenrated.status);
    setIsMediaDocLoading(false);
}

export default function humanizeString(string) {
    return string.replace(/([A-Z])/g, ' $1').trim().charAt(0).toUpperCase() + string.replace(/([A-Z])/g, ' $1').trim().slice(1);
}

export function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    // var interval = seconds / 31536000;

    // if (interval > 1) {
    //     return Math.floor(interval) + " years";
    // }
    // interval = seconds / 2592000;
    // if (interval > 1) {
    //     return Math.floor(interval) + " months";
    // }
    // interval = seconds / 86400;
    // if (interval > 1) {
    //     return Math.floor(interval) + " days";
    // }
    var interval = seconds / 86400;
    if (interval > 1) {
        return null;
    }

    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
}


export function isImage(file) {
    // const ext = getExtension(filename);
    // switch (ext.toLowerCase()) {
    const type =  file.url ? file.type : file.blobFile.type

    switch (type) {
        // case 'jpg':
        // case 'jpeg':
        // case 'webp':
        // case 'png':
        case 'Image':
            //etc
            return true;
    }

    return !!type.match('image.*');

}
function getExtension(filename) {
    const parts = filename.split('.');
    return parts[parts.length - 1];
}
