import React from "react";
import {Col, Grid, Panel, Row} from "rsuite";
import "react-multi-carousel/lib/styles.css";
import {Link} from "react-router-dom";
import LatestNews from "../news/latestNews";
import "../../styles/blogFeed.css";

const BlogsFeed = () => {
    // const [resData, setResData] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const [value, setValue] = useState(4);
    // const [index, setIndex] = useState(1);
    // const handleSelect = (selectedIndex, e) => {
    //
    //   setIndex(selectedIndex);
    // };
    //
    // const params = `type=BLOG&skip=0&take=5&sortField=ann_id&sortDirection=desc`;
    //
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(async () => {
    //   await fetchData();
    // }, []);
    //
    // const fetchData = async () => {
    //   setLoading(true);
    //   const response = await getAllAnnouncements(params);
    //   setResData(response.data);
    //   setLoading(false);
    // };
    //
    // console.log(resData);

    return (
        // <Container >
        <Grid fluid>
            <Row>
                <Col xs={24} md={8} lg={8}>
                    <Panel bodyFill style={{display: "inline-block", width: 320}}>
                        <Panel header="Latest from our">
                            <h1
                                className="default-color"
                                style={{fontSize: "32pt", fontWeight: "bolder"}}
                            >
                                EDUCATION GUIDE
                            </h1>
                        </Panel>
                        <Panel>
                            <p>
                                <small >
                                    <Link className={'blogsFeed-view-all'} to={"/blogs"}>View All Blogs</Link>{" "}
                                </small>
                            </p>
                        </Panel>
                    </Panel>
                </Col>
                <Col xs={24} sm={24} md={16} lg={16}>
                    <LatestNews homeScreen={true}/>
                    {/*<CardsCarousel*/}
                    {/*  activeIndex={index}*/}
                    {/*  touch={true}*/}
                    {/*  onSelect={handleSelect}*/}
                    {/*  variant="light"*/}
                    {/*  controls={true}*/}
                    {/*  fade={false}*/}
                    {/*  keyboard={true}*/}
                    {/*  prevIcon={*/}
                    {/*    <>*/}
                    {/*      <Hidden only={["sm", "xs"]}>*/}
                    {/*        <div style={{ marginRight: "5em" }}>*/}
                    {/*          <ArrowCircleLeftIcon />*/}
                    {/*        </div>*/}
                    {/*      </Hidden>*/}
                    {/*      <Hidden only={["xl", "lg", "md"]}>*/}
                    {/*        <ArrowCircleLeftIcon />*/}
                    {/*      </Hidden>*/}
                    {/*    </>*/}
                    {/*  }*/}
                    {/*  nextIcon={*/}
                    {/*    <>*/}
                    {/*      <Hidden only={["sm", "xs"]}>*/}
                    {/*        <div style={{ marginLeft: "5em" }}>*/}
                    {/*          <ArrowCircleRightIcon />*/}
                    {/*        </div>*/}
                    {/*      </Hidden>*/}
                    {/*      <Hidden only={["xl", "lg", "md"]}>*/}
                    {/*        <ArrowCircleRightIcon />*/}
                    {/*      </Hidden>*/}
                    {/*    </>*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  {*/}
                    {/*    resData.map((item,index) => (*/}


                    {/*      <CardsCarousel.Item key={index}>*/}
                    {/*        <div style={{ marginLeft: "2.5em" }}>*/}
                    {/*          <Row className="show-grid">*/}
                    {/*            <Col xs={12}>*/}
                    {/*              <Panel*/}
                    {/*                  shaded*/}
                    {/*                  bordered*/}
                    {/*                  bodyFill*/}
                    {/*                  style={{*/}
                    {/*                    display: "inline-block",*/}
                    {/*                    width: 250,*/}
                    {/*                    background: "#fff",*/}
                    {/*                  }}*/}
                    {/*              >*/}
                    {/*                <img src={item.img} height="150" width="250" />*/}
                    {/*                <Panel>*/}
                    {/*                  <p>*/}
                    {/*                    <small> */}
                    {/*                      Female Underrepresentation is STEM should become a*/}
                    {/*                      thing of the past,*/}
                    {/*                    </small>*/}
                    {/*                  </p>*/}
                    {/*                </Panel>*/}
                    {/*              </Panel>*/}
                    {/*            </Col>*/}
                    {/*            <Col xs={12}>*/}
                    {/*              <Panel*/}
                    {/*                  shaded*/}
                    {/*                  bordered*/}
                    {/*                  bodyFill*/}
                    {/*                  style={{*/}
                    {/*                    display: "inline-block",*/}
                    {/*                    width: 250,*/}
                    {/*                    background: "#fff",*/}
                    {/*                  }}*/}
                    {/*              >*/}
                    {/*                <img src={blogimg} height="150" width="250" />*/}
                    {/*                <Panel header="">*/}
                    {/*                  <p>*/}
                    {/*                    <small>*/}
                    {/*                      Female Underrepresentation is STEM should become a*/}
                    {/*                      thing of the past,*/}
                    {/*                    </small>*/}
                    {/*                  </p>*/}
                    {/*                </Panel>*/}
                    {/*              </Panel>*/}
                    {/*            </Col>*/}
                    {/*          </Row>*/}
                    {/*        </div>*/}
                    {/*      </CardsCarousel.Item>*/}
                    {/*    ))*/}
                    {/*  }*/}
                    {/*</CardsCarousel>*/}
                </Col>
                <Panel>
                    <p className={'blogsFeed-view-all'}>
                        <small className={'blogsFeed-view-all'} >
                            <Link className={'blogsFeed-view-all'} to={"/blogs"}>View All Posts</Link>{" "}
                        </small>
                    </p>
                </Panel>
            </Row>
        </Grid>

        // </Container>
    );
};

export default BlogsFeed;
