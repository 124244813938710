import React, {useEffect, useState} from 'react';
import {Button, Content, FlexboxGrid, Panel} from "rsuite";
import {ActivateAccountWaitingSpinner, CustomSpinner} from "components/common/spinner";
import {Link, useParams} from "react-router-dom";
import {VerifyEmail} from "service/verifyEmail.service";

function ActivateAccount() {

    const [activated,setActivated] = useState(true)
    const [loading, setLoading] = useState(false)

    let { token } = useParams();
    // console.log(token);


    useEffect(async () => {
        setLoading(true)
        const response = await VerifyEmail(token)
        setActivated(response.status)
        // console.log(response);
        setLoading(false)
    },[])



    if (loading) return <CustomSpinner/>

    if (!activated) return <ActivateAccountWaitingSpinner/>


    return (
        <div className="wrapper login-page text-start">
            <Content>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={12}>
                        <Panel

                            /*header={
                                <h3 className='default-color'>Login</h3>
                            }
                            */
                            bordered shaded
                        >
                            <p style={{fontSize:'1.5rem',textAlign: 'center',margin:'10px auto'}}>Your account is activated</p>
                            <Button
                                as={Link}
                                to="/login"
                            className={'d-w-r-btn'}
                            >
                                Log In
                            </Button>
                            <p style={{fontSize:'1.5rem',textAlign: 'center',margin:'10px auto'}}>OR</p>
                            <Button
                                as={Link}
                                to="/"
                                className={'d-w-r-btn'}

                            >
                                Home
                            </Button>


                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </div>
    );
}

export default ActivateAccount;
