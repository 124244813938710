import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import {CustomSpinner} from "components/common/spinner";
import {Column} from "lib/dtc/eventListing.column";
import Config from "../../../../config.json";
import {getEvents} from "service/event.service";
import {getCurrentUser} from "service/auth.service";
import Modal from "rsuite/Modal";
import CompanyEvent from "components/profile/company/CompanyJobEventsCourses/companyEvent";
import {Button} from "rsuite";

function EventList({valueNav = 'nothing', setValueNav}) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sortField, setSortField] = useState('eve_id')
    const [sortDirection, setSortDirection] = useState('asc')
    const [searchText, setSearchText] = useState("")
    const [eveId,setEveId] = useState(null)
    const [user,setUser] = useState(getCurrentUser())
    const [open, setOpen] = React.useState(false);


    const baseUrl = Config.BASE_API_URL;
    const getEventsUrl = baseUrl + Config.GET_EVENTS_URL;

    useEffect(async () => {

        setLoading(true)
        await fetchData()
        setLoading(false)

    }, [perPage, page, sortField, sortDirection, searchText])


    const fetchData = async () => {
        const params = `type=profile&skip=${page * perPage}&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&searchText=${searchText}`
        const url = `${getEventsUrl}?${params}`
        setLoading(true);

        const response = await getEvents(url,user.access_token)

        setData(response?.status ? response?.data?.events : []);
        setTotalRows(response?.status ? response?.data?.count : 0);
        setLoading(false);
    }

    const handlePerRowsChange = async (newPerPage, page_) => {
        setPerPage(newPerPage);
        setPage(page_ - 1)
    };

    const handlePageChange = (page_) => {
        setPage(page_ - 1);
    };

    const handleSort = (column, sortDirection) => {
        setSortDirection(sortDirection);
        setSortField(column.sortField);
    };
    const handleEdit = (eve_id) => {
        setEveId(eve_id)
        // console.log(eve_id);
        handleOpen()
        // setEditOrNew(true)
    }
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const columns = Column( handleEdit);
    return (
        <div className='company-list-wrapper'>
            <DataTable
                // title="Products"
                // keyField={'com_id'}
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions={[10, 30, 50, 100, 200]}
                paginationComponentOptions={{selectAllRowsItem: true, selectAllRowsItemText: 'All'}}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                striped={true}
                fixedHeader
                fixedHeaderScrollHeight={'70vh'}
                responsive
                progressComponent={<CustomSpinner/>}

            />

            <Modal backdrop={'static'} keyboard={false} open={open} onClose={handleClose} full>
                <Modal.Header>
                    <Modal.Title>Edit Event</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <CompanyEvent  eve_id={eveId} editOrNew={true}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default EventList;