import React from "react";
import {ButtonGroup, Checkbox, Col, DatePicker, Divider, IconButton, Input, Row} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";


export const Projects = ({value = [], onChange, fieldError}) => {
    const errors = fieldError ? fieldError.array : [];
    const [projects, setProjects] = React.useState(value);
    const handleChangeProjects = nextProjects => {
        setProjects(nextProjects);
        onChange(nextProjects);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextProjects = [...projects];
        nextProjects[rowIndex] = value;
        handleChangeProjects(nextProjects);
    };

    const handleMinus = () => {
        handleChangeProjects(projects.slice(0, -1));
    };
    const handleAdd = () => {
        handleChangeProjects(projects.concat([
            {
                projectName: '',
                projectUrl: '',
                startedDate: new Date(),
                endDate: new Date(),
                isOnGoing: false,
                description: '',
            }]));
    };
    return (
        <table style={{width: 'inherit'}}>

            <tbody>
            {projects.map((rowValue, index) => (
                <ProjectsDetail
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index].object : null}
                    onChange={handleInputChange}
                />
            ))}
            </tbody>
            <tfoot>
            <tr>
                <Cell colSpan={2} style={{paddingTop: 10}}>
                    <ButtonGroup size="xs">
                        <IconButton onClick={handleAdd} icon={<PlusIcon/>}/>
                        <IconButton disabled={value.length === 1} onClick={handleMinus} icon={<MinusIcon/>}/>
                    </ButtonGroup>
                </Cell>
            </tr>
            </tfoot>
        </table>
    );
};

const ProjectsDetail = ({rowValue = {}, onChange, rowIndex, rowError}) => {
    const handleChangeProjectName = value => {
        onChange(rowIndex, {...rowValue, projectName: value});
    };
    const handleChangeProjectUrl = value => {
        onChange(rowIndex, {...rowValue, projectUrl: value});
    };
    const handleChangestartedDate = value => {
        onChange(rowIndex, {...rowValue, startedDate: value});
    };
    const handleChangeEndDate = value => {
        onChange(rowIndex, {...rowValue, endDate: value});
    };
    const handleIsOnGoing = (value, data) => {
        onChange(rowIndex, {...rowValue, isOnGoing: data});
        // console.log(data);
    };
    const handleChangeDescription = value => {
        onChange(rowIndex, {...rowValue, description: value});
    };

    return (
        <tr>
            <>
                <Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
                    <h6 style={{marginBottom: '5px'}}>Project</h6>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>Project Name</p>
                        <Input value={rowValue.projectName} onChange={handleChangeProjectName}/>
                        {rowError ? <ErrorMessage>{rowError.projectName?.errorMessage}</ErrorMessage> : null}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>Project Url</p>
                        <Input value={rowValue.projectUrl} onChange={handleChangeProjectUrl}/>
                        {rowError ? <ErrorMessage>{rowError.projectUrl?.errorMessage}</ErrorMessage> : null}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>Started Date</p>
                        <DatePicker oneTap value={new Date(rowValue.startedDate)} onChange={handleChangestartedDate}/>
                        {rowError ? <ErrorMessage>{rowError.startedDate?.errorMessage}</ErrorMessage> : null}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <p>End Date</p>
                        <DatePicker oneTap value={new Date(rowValue.endDate)} onChange={handleChangeEndDate}/>
                        {rowError ? <ErrorMessage>{rowError.endDate?.errorMessage}</ErrorMessage> : null}
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <p>Description</p>

                        <Input as="textarea" rows={3} placeholder="Description Here" value={rowValue.description}
                               onChange={handleChangeDescription}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <Checkbox checked={rowValue.isOnGoing} onChange={handleIsOnGoing}> Is On Going</Checkbox>
                        {rowError ? <ErrorMessage>{rowError.isOnGoing?.errorMessage}</ErrorMessage> : null}
                    </Col>

                </Row>
            </>

        </tr>

    );
};
const ErrorMessage = ({children}) => <span style={{color: 'red'}}>{children}</span>;
const Cell = ({children, style, ...rest}) => (
    <td style={{padding: '2px 4px 2px 0', verticalAlign: 'top', ...style}} {...rest}>
        {children}
    </td>
);
