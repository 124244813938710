import {Col, Container, Divider, Grid, Row} from "rsuite";
import RecentBlogs from "../components/blogs/recentBlogs";
import Categories from "../components/blogs/categories";
import '../styles/blogs.css';
import React, {useState} from "react";
import BlogsCardsPagination from "../components/blogs/blogsCardsPagination";
import BlogsDetailView from "../components/blogs/detailView";
import {useStateWithDeps} from "use-state-with-deps";


const Blogs = ({DetailPage = false}) => {
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [header, setHeader] = useStateWithDeps( 'EDUINFO BLOGS', [DetailPage]);


    // const [resData, setResData] = useState([]);
    // const [loading, setLoading] = useState(false);
    //
    // const params = `type=BLOG&skip=0&take=10&sortField=ann_id&sortDirection=desc`;

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(async () => {
    //     await fetchData()
    // }, []);
    //
    // const fetchData = async () => {
    //     setLoading(true);
    //     // await fetchData()
    //     const response = await  getAllAnnouncements(params);
    //     setResData(response.data);
    //     setLoading(false);
    // }
    // if (loading) return <CustomSpinner/>;
    // console.log(resData);

    return (
        <Container className={'wrapper'}>

            <Divider style={{background: 'transparent', margin: '10px 0'}}/>

            <div className={"border-default"} style={{background: 'white', textAlign: 'center', alignContent: 'center'}}>
                <h1 className={'default-color'} style={{margin: '20px 0'}}>{header}</h1>
            </div>
            <Grid fluid={true}>
                <Row>
                    <Col xs={0} sm={0} md={6} lg={6}>
                        <RecentBlogs/>
                        <Categories setHeader={setHeader} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>
                    </Col>
                    <Col xs={24} sm={24} md={18} lg={18}>
                        {
                            DetailPage ?
                                <BlogsDetailView setHeader={setHeader}/>
                                :
                                <BlogsCardsPagination selectedCategory={selectedCategory}/>
                        }
                    </Col>
                </Row>
            </Grid>

        </Container>
    );
}
export default Blogs;
