import { Col, Divider, Row } from "rsuite";
import React, { useEffect, useState } from "react";

import { getAllAnnouncements } from "../../service/announcement.service";
import BlogCard from "./blogCard";
import { CustomSpinner } from "../common/spinner";
import { PaginationCustom } from "../common/paginationCustom";

const BlogsCardsPagination = ({ selectedCategory }) => {
  // console.log(selectedCategory);

  const [resData, setResData] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);

  const params = `type=BLOG&skip=${limit * (page - 1)}&take=${
    limit * (page - 1) + limit
  }&sortField=ann_id&sortDirection=desc&aty_id=${selectedCategory}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await fetchData();
  }, [limit, page, selectedCategory]);

  const fetchData = async () => {
    setLoading(true);
    const response = await getAllAnnouncements(params);
    setResData(response.data);
    setCount(response.count);
    setLoading(false);
  };

  if (loading) return <CustomSpinner />;

  return (
    <>
      {/*<Row className={'blogsfeed-row'}  as={"ol"}>*/}
      <ol className={'blogsfeed-row'}  >
        {resData.map(
          (
            {
              ann_id,
              title,
              description,
              img,
              announcement_category,
              read_time,
              posted_by,
            },
            index
          ) => (
            // <Col as={"li"} key={index} md={12} sm={24} lg={12} xs={24}>
            <li  key={index}>
              <BlogCard
                id={ann_id}
                header={title}
                description={description}
                img={img}
                category={announcement_category}
                read_time={read_time}
                postedBy={posted_by}
              />
            </li>
          )
        )}
      </ol>
      <Divider />
      {count > 0 ? (
        <PaginationCustom
          count={count}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      ) : (
        <div className={"text-center"}>No blogs found</div>
      )}
      <Divider />
    </>
  );
};

export default BlogsCardsPagination;
