import React, {useState} from "react"
import {Button, ButtonToolbar, Col, Container, Content, Divider, Form, Grid, Input, Panel, Row, toaster} from "rsuite";
import {TextField} from "components/textField";
import Config from "config.json";
import {Email, Global, Location, Phone} from "@rsuite/icons";
import '../styles/contactUs.css'
import {HiOutlineLocationMarker} from "react-icons/hi";
import GoogleMapReact from "google-map-react";
import {register} from "../service/auth.service";
import {NotificationCustom} from "../components/common/notification";
import {contactUsFormModel} from "../lib/formModels";
import {createContactUs} from "../service/contactUs.service";

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref}/>);

export function ContactUs() {
    const defaultFormValue = {
        fullName: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    }

    const [payload, setPayload] = useState(defaultFormValue);

    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);


    const handleSubmit = async () => {
        toaster.clear()
        setIsSubmitted(true)
        if (!formRef.current.check()) {
            console.error('Form Error');
            setIsSubmitted(false)
            return;
        }

        const res = await createContactUs(payload)
        if (res.status) {
            toaster.push(<NotificationCustom type="success" placement={'topEnd'} header='Success'>Thank you for Contacting Us. Your Query is sent, You will receive an email</NotificationCustom>);
            setIsSubmitted(false)
            setPayload(defaultFormValue)
        } else {
            toaster.push(<NotificationCustom type="error" placement={'topEnd'} header='Error'> Some Error Occurred</NotificationCustom>);
        setIsSubmitted(false)
        }
        setIsSubmitted(false)

    }


    const CurrentPin = ({text}) => {
        return (<div>
            <HiOutlineLocationMarker color={'red'} size={28}/>
            <p style={{color: 'red'}}>{text}</p>
        </div>)
    }

    function createMapOptions(maps) {
        // next props are exposed at maps
        // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
        // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
        // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
        // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
        // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
        return {
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL
            },
            mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT
            },
            mapTypeControl: true
        };
    }


    return (
        <Container className="wrapper contact-us-container  text-start ">
            <Divider style={{background: 'transparent'}}/>
            <Content>
                <Panel style={{backgroundColor: '#ffffff'}} bordered
                       header={<h1 style={{borderBottom: '1px solid #0C3144', paddingBottom: '5px'}}>Contact Us</h1>}>
                    <Divider style={{background: 'transparent', margin: '8px 0px'}}/>
                    <Grid fluid>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <div style={{marginBottom: "8px"}}>
                                    <div className="icon_div">
                                        <Location/>
                                    </div>
                                    <div className="text_div link-default">
                                        <p> Hofstra Office of Undergraduate Admissions,
                                            Hempstead, NY 11550, US</p>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <div style={{marginBottom: "8px"}}>
                                    <div className="icon_div">
                                        <Phone/>
                                    </div>
                                    <div className="text_div link-default">
                                        <a href={`tel:${Config.PHONE}`} target='_blank'>{Config.PHONE}</a>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={24} sm={24} md={8} lg={8}>
                                <div style={{marginBottom: "8px"}}>
                                    <div className="icon_div">
                                        <Global/>
                                    </div>
                                    <div className="text_div link-default">
                                        <a href={`https://www.edutrust.info`} target='_blank'>Edutrust.info</a>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6}>
                                <div style={{marginBottom: "8px"}}>
                                    <div className="icon_div">
                                        <Email/>
                                    </div>
                                    <div className="text_div link-default">
                                        <a href={`mailto:${Config.EMAIL}`} target='_blank'>{Config.EMAIL}</a>
                                    </div>

                                </div>
                            </Col>

                        </Row>
                    </Grid>
                </Panel>
                <Divider style={{background: 'transparent'}}/>
                <Grid fluid>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Panel
                                style={{backgroundColor: '#ffffff', marginBottom: '8px'}}
                                header={
                                    <h1 style={{borderBottom: '1px solid #0C3144', paddingBottom: '5px'}}>Contact Us</h1>
                                }
                                bordered
                            >

                                <Form
                                    ref={formRef}
                                    onChange={setPayload}
                                    onCheck={setFormError}
                                    formValue={payload}
                                    className={'contact-us-form'}
                                    model={contactUsFormModel}
                                    fluid
                                >
                                    <TextField name="fullName" label="Full Name" helpText='Required'/>
                                    <TextField name="email" label="Email" helpText='Required'/>
                                    <TextField name="subject" label="Subject" helpText='Required'/>
                                    <TextField name="phone" label="Contact #" helpText='Required'/>
                                    <TextField name="message" label="Message" helpText='Required' rows={5} accepter={Textarea}/>
                                    <Form.Group>
                                        <ButtonToolbar>
                                            <Button
                                                appearance="primary"
                                                onClick={handleSubmit}
                                                disabled={Object.keys(formError).length !== 0 || isSubmitted}
                                                loading={isSubmitted}
                                                className="default-bgcolor login-btn"
                                            >{isSubmitted ? 'Submitting' : 'Submit'}
                                            </Button>

                                        </ButtonToolbar>
                                    </Form.Group>


                                </Form>
                            </Panel>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Panel shaded className={'map_panel'}>
                                <div style={{height: '59vh', width: '100%'}}>
                                    <GoogleMapReact

                                        bootstrapURLKeys={{
                                            'key': Config.GOOGLE_MAP_API_KEY, language: 'en'
                                        }}
                                        // onClick={(e) => console.log(e.lat)}
                                        // center={{'lat': resData.lat, 'lng': resData.log}}
                                        center={[Config.LATITUDE, Config.LONGITUDE]}
                                        defaultCenter={[Config.LATITUDE, Config.LONGITUDE]}
                                        options={createMapOptions}
                                        // defaultZoom={11}
                                        zoom={14}
                                        layerTypes={['TrafficLayer', 'TransitLayer']}
                                    >

                                        <CurrentPin
                                            lat={Config.LATITUDE}
                                            lng={Config.LONGITUDE}
                                            text={Config.NAME}
                                        />

                                    </GoogleMapReact>
                                </div>
                            </Panel>
                        </Col>
                    </Row>
                </Grid>
            </Content>
            <Divider style={{background: 'transparent'}}/>
        </Container>
    );
}

export default ContactUs;