import Config from "../config.json";
import {convertMediaToArray} from "lib/utility";

const baseUrl = Config.BASE_API_URL;
const getReviewsUrl = baseUrl + Config.GET_REVIEWS_URL
const createReviewsUrl = baseUrl + Config.CREATE_REVIEWS_URL


export async function getReviews(url, token) {

    const response = await fetch(url, {
        method: 'GET',
        headers:  getHeader(token)
    })

    return await response.json();
}

export async function getAllReviews(url) {

    const response = await fetch(url)
    console.log(response);

    const temp = await response.json();
    console.log(temp);
    if (temp.status) {
        return {data: temp.data.reviews, count: temp.data.count};
    }
    return {data: [], count: 0};
}


export async function createReview(payload) {

    const response = await fetch(createReviewsUrl, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader()
    })

    return  await response.json();
}

// export async function updateJob(payload,token) {

//     const response = await fetch(updateJobUrl, {
//         body: JSON.stringify(payload),
//         method: 'POST',
//         headers: getHeader(token)
//     })

//     return  await response.json();
// }


function getHeader(token = '') {
    return {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
