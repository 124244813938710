import React, {useEffect, useRef, useState} from 'react';
import {Button, ButtonToolbar, Col, Content, DatePicker, Divider, Form, Grid, Loader, Message, Notification, Row, SelectPicker, toaster, Uploader} from "rsuite";
import {TextField} from "components/textField";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {formats, modules} from "components/profile/company/reactQuilConfig";
import '../../../styles/profile/company/createItem.css'
import 'react-phone-number-input/style.css'
import {MediaUrlInputControl} from "components/profile/company/inputs/addMediaURLInput";
import {ContactInformation} from "components/profile/company/inputs/contactInformation";
import {CreateCompanyFormModel} from "lib/formModels";
import {getCurrentUser, getHeaderToken} from "service/auth.service";
import Avatar from "@rsuite/icons/legacy/Avatar";
import Config from "../../../config.json";
import {createCompany, getCompany, getCompanyType, updateCompany} from "service/company.service";
import {getAllCountries, getCountryCities} from "service/setting.service";
import {CustomSpinner} from "components/common/spinner";

import {handleLogoUpload, handleMediaDocUpload, isImage} from "../../../lib/utility";
import {ErrorMessageImages} from "../../common/errorMessageImages";

function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result);
    };
    reader.readAsDataURL(file);
}

const styles = {
    width: 150,
    height: 150,
    marginBottom: 10
};


const CreateItem = ({editOrNew = false, com_id}) => {
    const [user, setUser] = useState(getCurrentUser());
    const defaultFormValue = {
        com_id,
        instituteName: '',
        category: '',
        country: '',
        city: '',
        use_id: user.use_id,
        description: '',
        address: '',
        website: '',
        phone: '',
        email: '',
        foundedIn: new Date(),
        gender: '',
        lat: 0,
        log: 0,
        mediaUrl: [{path: '', mediaType: 'Video'}],
        companyContacts: [{personName: '', designation: '', email: '', phone: ''}],
        twitterURL: '',
        youtubeURL: '',
        instaURL: '',
        fbURL: '',
        logo: '',
        mediaDoc: []
    }
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState(defaultFormValue);
    const [uploading, setUploading] = useState(false);
    const [fileInfo, setFileInfo] = useState(null);
    const [category, setCategory] = useState([]);
    const [country, setCountry] = useState([]);
    const [city, setCity] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [mediaDocs, setMediaDocs] = useState([]);
    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [isLogoUploaded, setIsLogoUploaded] = useState(editOrNew);
    const [isMediaDocLoading, setIsMediaDocLoading] = useState(false);
    const [isMediaDocUploaded, setIsMediaDocUploaded] = useState(editOrNew);
    const [isImageType, setIsImageType] = useState(true);


    const baseUrl = Config.BASE_API_URL;
    const upload_user_img_url = baseUrl + Config.UPLOAD_USER_IMG_URL

    // console.log(com_id, editOrNew)

    useEffect(async () => {
        setLoading(true)

        setCategory(await getCompanyType())
        setCountry(await getAllCountries())
        setCity([])

        if (editOrNew) {

            await handleComapnyDataSet()
        }

        setLoading(false)

    }, [])

    if (loading) return <CustomSpinner/>

    const handleComapnyDataSet = async () => {
        const response = await getCompany(com_id, user.access_token)
        if (response.status) {
            const data = response.data

            setFileInfo(data.logo)

            const mediaDoc = data.company_mediadoc.map((media, index) => {
                return {
                    url: media.mediadoc.path,
                    type: media.mediadoc.mediaType,
                    fileKey: index, name: media.mediadoc.path.split('/').pop(),
                    path: media.mediadoc.path,
                    mediaType: media.mediadoc.mediaType,
                    med_id: media.med_id
                }
            })

            const s3BaseURl = Config.S3_BUCKET_BASE_URL

            const filterMediaDoc = mediaDoc.filter((media) => media.url.includes(s3BaseURl))
            // console.log(filterMediaDoc)

            setFileList(filterMediaDoc.map((media) => {
                return {
                    url: media.url,
                    type: media.type,
                    fileKey: media.fileKey,
                    name: media.name,
                    med_id: media.med_id
                }
            }))

            // console.log(fileList)

            setCity(await getCountryCities(data.city.country.cou_id))

            const comapny = {
                com_id: data.com_id,
                instituteName: data.name,
                category: data.companytype.cot_id,//{value:, label: data.companytype.name},
                country: data.city.country.cou_id,//{value:data.city.country.cou_id, label: data.city.country.name},
                city: data.city.cit_id,//{value:data.city.cit_id, label: data.city.name},
                use_id: user.use_id,
                description: data.description,
                address: data.address,
                website: data.url,
                phone: parseInt(data.phone),
                email: data.email,
                foundedIn: new Date(`${data.foundYear}`),
                gender: '',
                lat: data.lat,
                log: data.log,
                companyContacts: data.companycontacts.map((contact) => {
                    return {
                        personName: contact.personName,
                        designation: contact.designation,
                        email: contact.email,
                        phone: contact.phone
                    }
                }),
                twitterURL: data.twitterURL,
                youtubeURL: data.youtubeURL,
                instaURL: data.instaURL,
                fbURL: data.fbURL,
                logo: data.logo,
                mediaDoc: mediaDoc.filter((media) => media.type !== 'Video'),
                mediaUrl: mediaDoc.filter((media) => media.type === 'Video')
            }
            // console.log(comapny)
            setFormValue(comapny)

        }
    }


    const handleSubmit = async () => {

        setIsSubmitted(true)

        if (!formRef.current.check()) {
            console.error('Form Error');
            setIsSubmitted(false)
            return;
        }

        if (!editOrNew) {
            const response = await createCompany(formValue, user.access_token)

            if (response.status) {
                toaster.push(<Message type="success">Company Created successfully</Message>);
                setFormValue(defaultFormValue)
                setFileList([])
            } else {
                toaster.push(<Message type="error">{response.message}</Message>);
            }
        } else {
            const response = await updateCompany(formValue, user.access_token)

            if (response.status) {
                toaster.push(<Message type="success">Company Updated successfully</Message>);
                // setFormValue(defaultFormValue)
            } else {
                toaster.push(<Message type="error">{response.message}</Message>);
            }
        }
        setIsSubmitted(false)

        console.log(formValue, 'Form Value')
    };

    const handleChange = (name, value) => {
        setFormValue(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(formValue, 'Form Value')
    };

    const handleLogoChange = (file) => {
        console.log(file.pop())

    };

    // const handleLogoUpload_old = async () => {
    //     setIsLogoLoading(true);
    //
    //     const response = await uploadLogo(logoFile, user.access_token);
    //
    //     if (response.status) {
    //         const url = response.data
    //         console.log(url);
    //         setFormValue({...formValue, logo: url})
    //     }else{
    //         toaster.push(<Message type="error">{response.message}</Message>);
    //     }
    //     setIsLogoUploaded(response.status);
    //     setIsLogoLoading(false);
    //     console.log(formValue.logo, 'logo')
    // }


    // console.log(formValue, 'form Value')

    return (
        <Content>
            <Grid fluid className='create-item-form' style={{marginLeft: 15}}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={CreateCompanyFormModel}
                    disabled={isSubmitted}
                    fluid
                >

                    <Row>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="instituteName" label="Institute Name" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select Category:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="category" data={category} accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>Category Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select Country:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control
                                        name="country"
                                        data={country}
                                        onChange={async (cou_id) => {
                                            setFormValue(prevState => ({
                                                ...prevState,
                                                city: '',
                                                country: cou_id
                                            }))
                                            setCity(await getCountryCities(cou_id))
                                        }}
                                        accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>Country Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>

                            <Form.Group controlId="selectPicker">
                                <Form.ControlLabel>Select City:</Form.ControlLabel>
                                <div style={{display: "flex"}}>
                                    <Form.Control name="city" data={city} accepter={SelectPicker}/>
                                    <Form.HelpText tooltip>City Required</Form.HelpText>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Group controlId="textarea">
                                <Form.ControlLabel>Description</Form.ControlLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={formValue?.description}
                                    onChange={(e) => handleChange('description', e)}
                                    modules={modules}
                                    formats={formats}
                                    style={{marginRight: 25}}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <TextField name="address" label="Address" helpText='Required'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="email" label="Email" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="website" label="Website Url" helpText='Required'/>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>

                            {/*todo: Mask Contact*/}
                            <TextField name="phone"
                                       label="Phone"
                                       helpText='Required'
                                // mask={mask}
                                // accepter={InputMask}
                            />

                            {/* <PhoneInput
                                placeholder="Enter phone number"
                                value={formValue.phone}
                                onChange={(e)=> handleChange('phone' ,e )}
                                error={()=>setFormError(formValue.phone ? (isValidPhoneNumber(formValue.phone) ? undefined : 'Invalid phone number') : 'Phone number required')}/>
*/}

                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>

                            <TextField name='foundedIn' format="yyyy-MM" label="Founded In" accepter={DatePicker}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="lat" label="Latitude" helpText='Required' type='number' min={0} step='any'/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <TextField name="log" label="Longitude" helpText='Required' type='number' min={0}/>

                        </Col>
                    </Row>

                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>

                    <Row>

                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Social Links</h6>

                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="fbURL" label="Facebook's Link" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="instaURL" label="Instagram's Link" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="youtubeURL" label="Youtube's Link" helpText='Required'/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <TextField name="twitterURL" label="Twitter's Link" helpText='Required'/>
                        </Col>
                    </Row>

                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>

                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Images and Videos</h6>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <Form.Group controlId={'avatar'}>
                                <Form.ControlLabel>Company Logo </Form.ControlLabel>
                                <Uploader
                                    headers={getHeaderToken(user?.access_token)}
                                    fileListVisible={false}
                                    listType="picture"
                                    name='logo'
                                    autoUpload={false}
                                    accept={'image/*'}
                                    action={''}
                                    data={{use_id: user.use_id}}
                                    onChange={async (file) =>
                                    {
                                        const logo_file = file.pop()
                                        if (isImage(logo_file)){

                                        setIsLogoUploaded(false);
                                        setLogoFile(logo_file);
                                        previewFile(logo_file.blobFile, value => setFileInfo(value))
                                        }
                                        else{

                                            setIsLogoUploaded(false);
                                            toaster.push(<Message type="error">`${logo_file.name} filetype is not Allowed` </Message>, {
                                                duration: 7000,
                                                position: 'top-right',
                                                type: 'danger'
                                            })
                                        }
                                    }}

                                >
                                    <button style={styles}>
                                        {uploading && <Loader backdrop center/>}
                                        {fileInfo ?
                                            (<img src={fileInfo} width="100%" height="100%" alt='...'/>)
                                            :
                                            (<Avatar src={user.image}
                                                     style={{fontSize: 80}}/>)
                                        }
                                    </button>
                                </Uploader>
                                <br/>
                                <Button
                                    disabled={isLogoUploaded || !logoFile}
                                    onClick={async () => handleLogoUpload(setIsLogoLoading, setIsLogoUploaded, setFormValue, logoFile, 'logo', user.access_token)}
                                    loading={isLogoLoading}
                                    style={{marginTop: 10, width: 'auto'}}
                                    className={'d-w-r-btn'}
                                >
                                    Start Upload
                                </Button>


                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Control
                                style={{width: 'inherit'}}
                                name="mediaUrl"
                                accepter={MediaUrlInputControl}
                                fieldError={formError.mediaUrl}
                            />

                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>

                            {/*<TextField name="images" label="Upload Images" helpText='Only Upload Images' accepter={Uploader} draggable={true}/>*/}
                            <Form.Group controlId="uploader" style={{marginRight: '25px'}}
                            >
                                <Form.ControlLabel>Images</Form.ControlLabel>
                                <Uploader
                                    fileList={fileList}
                                    listType="picture"
                                    action=""
                                    draggable
                                    disabled={fileList.length >= 5}
                                    name="mediaDoc"
                                    autoUpload={false}
                                    multiple={false}
                                    accept={'image/*'}
                                    onRemove={(file) => {
                                        setIsImageType(isImage(file));
                                        setFileList(fileList.filter(f => f.fileKey !== file.fileKey));
                                        setFormValue({...formValue, mediaDoc: fileList.filter(f => f.fileKey !== file.fileKey)})
                                    }}
                                    onChange={async (files) => {
                                        // console.log(files);

                                        setFileList(files);
                                        let mediaDocData = []
                                        for (const f of files) {
                                            // console.log(isImage(f), 'files')
                                            if (isImage(f)) {
                                                if (f.blobFile) {
                                                    setIsMediaDocUploaded(false);
                                                    mediaDocData.push({file: f, isNew: true})
                                                } else {
                                                    mediaDocData.push({...f, isNew: false})
                                                }
                                                setIsImageType(true);

                                            } else {

                                                toaster.push(<Message type="error">`${f.name} filetype is not Allowed` </Message>, {
                                                    duration: 7000,
                                                    position: 'top-right',
                                                    type: 'danger'
                                                })
                                                setIsImageType(false);
                                            }
                                        }
                                        setMediaDocs(mediaDocData)

                                        // setFormValue({...formValue, mediaDoc: mediaDocData})
                                    }}
                                    renderFileInfo={(file, fileElement) => {
                                        return (
                                            <div>
                                                <span>File Name: {file.name}</span>

                                            </div>
                                        );
                                    }}
                                >
                                    <div style={{lineHeight: '200px'}}>Click or Drag images to this area to upload</div>
                                </Uploader>
                                <br/>
                                <Button
                                    style={{marginTop: 10,marginBottom: 10, width: 'auto'}}
                                    className={'d-w-r-btn'}
                                    disabled={isMediaDocUploaded || !mediaDocs.length || !isImageType}
                                    onClick={async () => handleMediaDocUpload(setIsMediaDocLoading, setIsMediaDocUploaded, setFormValue, mediaDocs, user.access_token)}
                                    loading={isMediaDocLoading}
                                >
                                    Start Upload

                                </Button>
                                {
                                    !isImageType &&
                                   <ErrorMessageImages/>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>

                    <Row>
                        <h6 style={{padding: '0 5px', margin: '3px auto'}}>Company's Contact Information</h6>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Control
                                name="companyContacts"
                                accepter={ContactInformation}
                                fieldError={formError.companyContacts}
                            />

                        </Col>

                    </Row>

                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary"
                                // type="submit"
                                    className='d-w-r-btn'
                                    onClick={handleSubmit}
                                    style={{width: 'auto'}}
                                    disabled={Object.keys(formError).length !== 0 || isSubmitted || !isLogoUploaded || !isMediaDocUploaded}
                                    loading={isSubmitted}
                            >
                                Submit
                            </Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Grid>
        </Content>

    );
};

export default CreateItem;