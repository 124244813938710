// noinspection JSUnusedLocalSymbols

import React, {useEffect, useState} from "react";
import ReactPlayer from 'react-player/lazy';
import ReactHtmlParser from 'react-html-parser';
import {CustomSpinner} from "components/common/spinner";
import { Carousel, Col, Container, Divider, Grid, Panel, Row,List} from "rsuite";
import '../../styles/job.css'
import {Link, useLocation, useParams} from "react-router-dom";
import {getJobDetail} from "service/job.service";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Hidden from "@mui/material/Hidden";
import TextField from "@mui/material/TextField";
import RatingCarousel from "react-bootstrap/Carousel";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Config from "config.json";
import {ReadMore} from "../../lib/utility";



// const CommunityRating = () => {
//     const [value, setValue] = React.useState(4);
//     const [index, setIndex] = useState(0);
//
//     const handleSelect = (selectedIndex, e) => {
//       setIndex(selectedIndex);
//     };
//     return (
//       <div>
//         <h1 style={{ color: "red", textAlign: "left" }}>Community Rating</h1>
//         <Rating name="read-only" value={value} readOnly />
//         <RatingCarousel
//           activeIndex={index}
//           touch={true}
//           onSelect={handleSelect}
//           variant="light"
//           controls={true}
//           fade={false}
//           keyboard={true}
//           prevIcon={
//             <>
//               <Hidden only={["xs", "sm"]}>
//                 <div style={{ marginRight: "7em" }}>
//                   <ArrowCircleLeftIcon />
//                 </div>
//               </Hidden>
//               <Hidden only={["xl", "lg", "md"]}>
//                 <div>
//                   <ArrowCircleLeftIcon />
//                 </div>
//               </Hidden>
//             </>
//           }
//           nextIcon={
//             <>
//               <Hidden only={["xs", "sm"]}>
//                 <div style={{ marginLeft: "7em" }}>
//                   <ArrowCircleRightIcon />
//                 </div>
//               </Hidden>
//               <Hidden only={["xl", "lg", "md"]}>
//                 <div>
//                   <ArrowCircleRightIcon />
//                 </div>
//               </Hidden>
//             </>
//           }
//         >
//           <RatingCarousel.Item>
//             <Box
//               sx={{
//                 "& > legend": { mt: 2 },
//                 marginLeft: "auto",
//                 marginRight: "auto",
//               }}
//             >
//               <List hover>
//                 <List.Item
//                   style={{
//                     maxWidth: "50em",
//                     marginLeft: "auto",
//                     marginRight: "auto",
//                   }}
//                 >
//                   <ListItem alignItems="flex-start">
//                     <ListItemAvatar>
//                       <Avatar
//                         alt="Remy Sharp"
//                         src="https://avatars.githubusercontent.com/u/9625224"
//                       />
//                     </ListItemAvatar>
//                     <ListItemText
//                       primary="Muhammad  Abid Husain"
//                       secondary={
//                         <React.Fragment>
//                           <Typography
//                             sx={{ display: "inline" }}
//                             component="span"
//                             variant="body2"
//                             color="text.primary"
//                           >
//                             Ali Connors
//                           </Typography>
//                           {" — I'll be in your neighborhood doing errands this…"}
//                         </React.Fragment>
//                       }
//                     />
//                   </ListItem>
//                   <ListItem>
//                     <Typography
//                       variant="body1"
//                       gutterBottom
//                       style={{ color: "#333" }}
//                     >
//                       Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//                       Quos blanditiis tenetur unde suscipit, quam beatae rerum
//                       inventore consectetur, neque doloribus, cupiditate numquam
//                       dignissimos laborum fugiat deleniti? Eum quasi quidem
//                       quibusdam.
//                     </Typography>
//                   </ListItem>
//                   <ListItem>
//                     <Rating name="read-only" value={value} readOnly />
//                   </ListItem>
//                 </List.Item>
//               </List>
//             </Box>
//           </RatingCarousel.Item>
//           <RatingCarousel.Item>
//             <Box
//               sx={{
//                 "& > legend": { mt: 2 },
//               }}
//             >
//               <List hover>
//                 <List.Item
//                   style={{
//                     maxWidth: "50em",
//                     marginLeft: "auto",
//                     marginRight: "auto",
//                   }}
//                 >
//                   <ListItem alignItems="flex-start">
//                     <ListItemAvatar>
//                       <Avatar
//                         alt="Remy Sharp"
//                         src="https://avatars.githubusercontent.com/u/9625224"
//                       />
//                     </ListItemAvatar>
//                     <ListItemText
//                       primary="Muhammad  Abid Husain"
//                       secondary={
//                         <React.Fragment>
//                           <Typography
//                             sx={{ display: "inline" }}
//                             component="span"
//                             variant="body2"
//                             color="text.primary"
//                           >
//                             Ali Connors
//                           </Typography>
//                           {" — I'll be in your neighborhood doing errands this…"}
//                         </React.Fragment>
//                       }
//                     />
//                   </ListItem>
//                   <ListItem>
//                     <Typography
//                       variant="body1"
//                       gutterBottom
//                       style={{ color: "#333" }}
//                     >
//                       Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//                       Quos blanditiis tenetur unde suscipit, quam beatae rerum
//                       inventore consectetur, neque doloribus, cupiditate numquam
//                       dignissimos laborum fugiat deleniti? Eum quasi quidem
//                       quibusdam.
//                     </Typography>
//                   </ListItem>
//                   <ListItem>
//                     <Rating name="read-only" value={value} readOnly />
//                   </ListItem>
//                 </List.Item>
//               </List>
//             </Box>
//           </RatingCarousel.Item>
//           <RatingCarousel.Item>
//             <Box
//               sx={{
//                 "& > legend": { mt: 2 },
//               }}
//             >
//               <List hover>
//                 <List.Item
//                   style={{
//                     maxWidth: "50em",
//                     marginLeft: "auto",
//                     marginRight: "auto",
//                   }}
//                 >
//                   <ListItem alignItems="flex-start">
//                     <ListItemAvatar>
//                       <Avatar
//                         alt="Remy Sharp"
//                         src="https://avatars.githubusercontent.com/u/9625224"
//                       />
//                     </ListItemAvatar>
//                     <ListItemText
//                       primary="Muhammad  Abid Husain"
//                       secondary={
//                         <React.Fragment>
//                           <Typography
//                             sx={{ display: "inline" }}
//                             component="span"
//                             variant="body2"
//                             color="text.primary"
//                           >
//                             Ali Connors
//                           </Typography>
//                           {" — I'll be in your neighborhood doing errands this…"}
//                         </React.Fragment>
//                       }
//                     />
//                   </ListItem>
//                   <ListItem>
//                     <Typography
//                       variant="body1"
//                       gutterBottom
//                       style={{ color: "#333" }}
//                     >
//                       Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//                       Quos blanditiis tenetur unde suscipit, quam beatae rerum
//                       inventore consectetur, neque doloribus, cupiditate numquam
//                       dignissimos laborum fugiat deleniti? Eum quasi quidem
//                       quibusdam.
//                     </Typography>
//                   </ListItem>
//                   <ListItem>
//                     <Rating name="read-only" value={value} readOnly />
//                   </ListItem>
//                 </List.Item>
//               </List>
//             </Box>
//           </RatingCarousel.Item>
//         </RatingCarousel>
//       </div>
//     );
//   };
//   const YourReviews = () => {
//     const [value, setValue] = React.useState(4);
//     const [fullName, setFullName] = useState("");
//     const [email, setEmail] = useState("");
//     const [comment, setComment] = useState("");
//     const formData = {
//       "Rating:": value,
//       "Full Name:": fullName,
//       "Email:": email,
//       "Comment:": comment,
//     };
//     const getReviewValues = () => {
//       console.log("Review Values :", formData);
//     };
//     const handleSubmit = (event) => {
//       event.preventDefault();
//       const data = new FormData(event.currentTarget);
//       console.log({
//         email: data.get("email"),
//         name: data.get("name"),
//         comment: data.get("comment"),
//         rating: data.get("simple-controlled"),
//       });
//     };
//     return (
//       <div>
//         <h1 style={{ color: "red", textAlign: "left" }}>Your Reviews</h1>
//         <Box sx={{ width: "100%" }} component="form" onSubmit={handleSubmit}>
//           <Rating
//             name="simple-controlled"
//             value={value}
//             onChange={(event, newValue) => {
//               setValue(newValue);
//               console.log("Rating :", value);
//             }}
//           />
//           <Hidden only={["xs", "sm"]}>
//             <Row>
//               <Col>
//                 &nbsp;
//                 <TextField
//                   required
//                   id="outlined-required"
//                   autoComplete="name"
//                   name="name"
//                   label="Full Name"
//                   style={{ width: "26em", height: "5em" }}
//                 />
//               </Col>
//               &nbsp; &nbsp; &nbsp; &nbsp;
//               <Col>
//                 &nbsp;
//                 <TextField
//                   required
//                   id="outlined-required"
//                   autoComplete="email"
//                   label="Email Address"
//                   name="email"
//                   style={{ width: "26em", height: "5em" }}
//                 />
//               </Col>
//             </Row>
//           </Hidden>
//           <Hidden only={["xl", "lg", "md"]}>
//             <Row>
//               <Col>
//                 &nbsp;
//                 <TextField
//                   required
//                   id="outlined-required"
//                   autoComplete="name"
//                   name="name"
//                   label="Full Name"
//                   style={{ width: "18em", height: "5em" }}
//                 />
//               </Col>
//               &nbsp; &nbsp; &nbsp; &nbsp;
//               <Col>
//                 &nbsp;
//                 <TextField
//                   required
//                   id="outlined-required"
//                   autoComplete="email"
//                   label="Email Address"
//                   name="email"
//                   style={{ width: "18em", height: "5em" }}
//                 />
//               </Col>
//             </Row>
//           </Hidden>
//           <Box
//             sx={{
//               width: "100%",
//               maxWidth: "100%",
//             }}
//           >
//             <Hidden only={["sm", "xs"]}>
//               <TextField
//                 id="outlined-multiline-static"
//                 label="Comment"
//                 name="comment"
//                 multiline
//                 autoComplete="comment"
//                 rows={6}
//                 style={{ width: "53.2em" }}
//                 fullWidth
//                 onChange={(e) => {
//                   setComment(e.target.value);
//                 }}
//               />
//             </Hidden>
//             <Hidden only={["lg", "xl", "md"]}>
//               <TextField
//                 id="outlined-multiline-static"
//                 label="Comment"
//                 name="comment"
//                 multiline
//                 autoComplete="comment"
//                 rows={6}
//                 style={{ width: "18.5em" }}
//                 fullWidth
//                 onChange={(e) => {
//                   setComment(e.target.value);
//                 }}
//               />
//             </Hidden>
//           </Box>
//           <br />
//           <Box
//             sx={{
//               width: "100%",
//               maxWidth: "100%",
//             }}
//           >
//             <Button
//               variant="outlined"
//               size="large"
//               style={{ color: "red", borderColor: "red" }}
//               type="submit"
//             >
//               Send Message
//             </Button>
//           </Box>
//         </Box>
//       </div>
//     );
//   };
//
//



export default function JobDetailView({data}) {

    const location = useLocation()
    // console.log(location.state?.job_id)
    // const job_id = location.state?.job_id
    let {job_id} = useParams();

    // const {data: {accessToken, user, type}} = session
    const [resData, setResData] = useState();
    const [reason, setReason] = useState('');
    const [isChecked, setIsChecked] = useState(false)
    const [toggleDetailView, setToggleDetailView] = useState(false)
    const [rowData, setRowData] = useState({})
    const [loading, setLoading] = useState(false)


    const baseUrl = Config.BASE_API_URL;
    const getJobsUrl = baseUrl + Config.GET_JOBS_URL;


    useEffect(async () => {
        setLoading(true)
        const res = await getJobDetail(`${getJobsUrl}/${job_id}`)
        setResData(res?.status ? res?.data : {});

        setLoading(false)
    }, [data])


    /*    const CurrentPin = ({text}) => {
            return (<div>
                <HiOutlineLocationMarker color={'red'} size={28}/>
                <p style={{color: 'red'}}>{text}</p>
            </div>)
        }*/


    const toggleDetail = async (id) => {
        setRowData(id)
        setToggleDetailView(!toggleDetailView)
    }

    if (loading) return <CustomSpinner/>

    console.log(resData?.media?.images.includes('noImageFound'))

    return (

        <Container className='wrapper'>

            <h1 style={{color: 'red', textAlign: 'center'}}> {resData?.title}</h1>
            <Divider/>
            <Grid fluid style={{backgroundColor: 'white'}}>
                <Divider style={{margin: ''}}/>


                <Row gutter={30}>
                    <Panel>


                        <Col lg={8} md={8} sm={24} xs={24}>
                            <Panel shaded header={
                                <h3>Details
                                    {
                                        resData?.apply_url &&
                                        <Button
                                            style={{margin: '10', color: 'cream'}}
                                            // appearance="link"
                                            className='d-w-r-btn'
                                            as='a'
                                            target='_blank'
                                            href={`${resData?.apply_url}`}
                                        >
                                            Apply Here
                                        </Button>
                                    }
                            </h3> }>

                                {/* <div className='mb-3'>&nbsp;</div>

                            <div style={{padding: '20px'}}>

                                <div style={{
                                    width: 'fit-content',
                                    height: 'auto',
                                    border: "2px solid #E91F20",
                                    borderRadius: "50%",
                                    overflow: 'hidden',
                                    margin: '0 auto'
                                }}>
                                    <img
                                        src="/images/logo.png"
                                        alt="home"
                                    />
                                </div>
                            </div>*/}

                                {/*cards*/}

                                {/*<h2 className="my-3" style={{paddingLeft: '20px'}}>Details</h2>*/}
                                <div className='job-panel-margin'>

                                    <Panel header="Type" shaded>
                                        <p className="card-text">{resData?.employmentType}</p>
                                    </Panel>
                                    <Panel header="Minimum Experience" shaded>
                                        <p className="card-text">{resData?.minExperience} Year(s)</p>
                                    </Panel>
                                    <Panel header="Company Name" shaded>
                                        {/*<p className="card-text">{resData.company.name}</p>*/}
                                        <Button
                                            style={{padding: '0'}}
                                            appearance="link"
                                            as={Link}
                                            to={`/company/detail/${resData?.cou_id}`}
                                            // state={{com_id: resData?.com_id}}
                                        >{resData?.company?.name}</Button>
                                    </Panel>
                                </div>

                            </Panel>
                        </Col>

                        <Col lg={16} md={16} sm={24} xs={24}>

                            <Panel shaded>
                                <div className='job-panel-margin'>

                                    {
                                        !resData?.media?.images.includes('/images/noImageFound.png') &&


                                        <Carousel autoplay>

                                            {
                                                resData?.media?.images?.map((src, index) =>

                                                    <img
                                                        key={index}
                                                        src={src}
                                                        alt={'job'}
                                                        object-fit='none'
                                                    />
                                                )
                                            }
                                        </Carousel>

                                    }
                                    <Panel shaded header={<h3>About</h3>}
                                           style={{textAlign: 'justify', paddingLeft: '20px', paddingRight: '20px'}}>

                                        {/*<h1 style={{textAlign: 'left'}}>About</h1>*/}

                                        <ReadMore text={resData?.description} words={400}/>
                                        {/*{ReactHtmlParser(resData?.description)}*/}

                                    </Panel>

                                    {
                                        resData?.media?.videos.length !== 0 &&

                                        <Panel header={<h3>Videos</h3>}>

                                            <Carousel>
                                                {resData?.media?.videos.map((src, index) =>


                                                    <div className={'player-wrapper'}>


                                                        <ReactPlayer
                                                            className='bg-black react-player'
                                                            width='100%'
                                                            // height='100%'
                                                            controls={true}
                                                            light
                                                            url={`${baseUrl}/${src}`}
                                                        />
                                                    </div>
                                                )}
                                            </Carousel>

                                        </Panel>
                                    }

                                </div>

                            </Panel>

                        </Col>
          
            {/* <br />
              <CommunityRating />
              <br />
              <YourReviews />
              <br /> */}
                    </Panel>

                </Row>


            </Grid>

        </Container>

    )
}

