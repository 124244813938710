export const Welcome = () => {
    return (

        <div className={'welcome'} >
            <p>
                WELCOME
            </p>
        </div>
    )
}

