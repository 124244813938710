import Config from "../config.json";
import {convertMediaToArray} from "lib/utility";

const baseUrl = Config.BASE_API_URL;
const getJobsUrl = baseUrl + Config.GET_JOBS_URL;
const createJobUrl = baseUrl + Config.CREATE_JOB_URL;
const updateJobUrl = baseUrl + Config.UPDATE_JOB_URL;


export async function getJobs(url, token) {

    const response = await fetch(url, {
        method: 'GET',
        headers:  getHeader(token)
    })

    return await response.json();
}

export async function getAllJobs(url) {

    const response = await fetch(url)

    return await response.json();
}

export async function getJobDetail(url) {

    const response = await fetch(url)

    let tempData=await response.json();
    tempData.data.media =  convertMediaToArray(tempData.data.job_mediadoc)

    return  tempData
}

export async function createJob(payload,token) {

    const response = await fetch(createJobUrl, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader(token)
    })

    return  await response.json();
}

export async function updateJob(payload,token) {

    const response = await fetch(updateJobUrl, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: getHeader(token)
    })

    return  await response.json();
}


function getHeader(token = '') {
    return {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

export function getHeaderToken(token = '') {
    return {
        'Authorization': `Bearer ${token}`,
    }
}
