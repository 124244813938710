import {Badge} from "rsuite";
import React from "react";
import EditIcon from "@rsuite/icons/Edit";
import {dateOptionsYYMMDD} from "../utility";

export const Column = (handleEdit,setValueNav) => [

    {
        name: 'Title',
        selector: row => row.title,
        sortable: true,
        sortField: 'title',
        grow: 2,
        wrap: true,
        // minWidth: "150px",
    },
    {
        name: 'Venue',
        selector: row => row.venue,
        sortable: true,
        sortField: 'venue',
        maxWidth: "80px",
        // grow: 2,
        // minWidth: "100px",
        // cell: (row) => <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
        //                     data-bs-toggle="tooltip" title={row.PurchaseItemName}>{row.PurchaseItemName}</div>

    },
    {
        name: 'Start Date',
        selector: row => new Date(row.startDate).toLocaleString('en-Us', dateOptionsYYMMDD),
        sortable: true,
        sortField: 'startDate',
        // width:'auto',
        // maxWidth: "80px",
        // cell: (row) => <div>{`${row.isAdmin}`}</div>

    },
    {
        name: 'End Date',
        selector: row => new Date(row.endDate).toLocaleString('en-Us', dateOptionsYYMMDD),
        sortable: true,
        sortField: 'endDate',
        // width:'auto',
        // maxWidth: "80px",
    },

    // {
    //     name: 'Event Type',
    //     selector: row => row?.eventtype?.name,
    //     // sortable: true,
    //     // sortField: 'QtyInHand',
    //     minWidth: "80px",
    //     // cell: row => <div >{row.companytype.name}</div>
    // },
    // {
    //     name: 'Event Category',
    //     selector: row => row?.eventsubcategory?.eventcategory.name,
    //     // sortable: true,
    //     // sortField: 'QtyInHand',
    //     // minWidth: "150px",
    //     // maxWidth: '300px',
    //     compact:true,
    //     wrap:true
    //     // cell: row => <div >{row.companytype.name}</div>
    // },
    // {
    //     name: 'Event Sub Category',
    //     selector: row => row?.eventsubcategory?.name,
    //     // sortable: true,
    //     // sortField: 'QtyInHand',
    //     // maxWidth: "80px",
    //     // cell: row => <div >{row.companytype.name}</div>
    // },
    // {
    //     name: 'City',
    //     selector: row => row?.city?.name,
    //     sortable: false,
    // },
    {
        name: 'Country',
        selector: row => row.city? row.city?.country?.name :  'Online',
        sortable: false,
        // cell: (row) => <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
        //                     title={row.PurchasingCategory.PurchasingCategoryName}>{row.PurchasingCategory.PurchasingCategoryName}</div>

    },
    // {
    //     name: 'From',
    //     selector: row => new Date(row.startDate).toLocaleDateString('en-US',{year:'numeric',month:'short',day:'numeric',hour:'numeric',minute:'numeric'}),
    //     sortable: true,
    //     sortField: 'startDate',
    //     // Width: 160,
    //     // compact: true,
    //     wrap: true
    // },
    // {
    //     name: 'To',
    //     selector: row => new Date(row.endDate).toLocaleDateString('en-US',{year:'numeric',month:'short',day:'numeric',hour:'numeric',minute:'numeric'}),
    //     sortable: true,
    //     sortField: 'endDate',
    //     // minWidth: 160
    //     // compact: true,
    //     wrap: true
    // },
    {
        name: 'Status',
        selector: row => row.isActive,
        sortable: true,
        sortField: 'isActive',
        // Width: "20px",
        // center: true,
        maxWidth: "50px",
        cell: row => row.isActive ? <Badge color="green" /> : <Badge color="red" />
    },
    {
        name: 'Edit',
        cell: (row)=> <a onClick={() => {
            handleEdit(row.eve_id)
        }
        } style={{fontSize: "2em",color:'#0C3144'}}><EditIcon  /> </a>

    }
]