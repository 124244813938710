import React, {useState} from 'react';
import {Button, ButtonToolbar, Col, Content, Form, Grid, Loader, Message, Row, toaster, Uploader} from "rsuite";
import {TextField} from "components/textField";
import {getCurrentUser, getHeaderToken, setCurrentUser, updateUser} from "service/auth.service";
import '../../styles/profile/profile.css'
import Config from "../../config.json";
import Avatar from "@rsuite/icons/legacy/Avatar";
import {handleLogoUpload} from "../../lib/utility";

function UserProfile(props) {
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const [user, setUser] = useState(getCurrentUser());
    const [uploading, setUploading] = React.useState(false);
    const [fileInfo, setFileInfo] = React.useState(null);
    const [formValue, setFormValue] = React.useState({
        use_id: user.use_id,
        firstName: user.firstname,
        lastName: user.lastname,
        email: user.email,
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [isLogoUploaded, setIsLogoUploaded] = useState(true  );

    const baseUrl = Config.BASE_API_URL;
    const upload_user_img_url = baseUrl + Config.UPLOAD_USER_IMG_URL

    const handleSubmit = async () => {
        setIsSubmitted(true)
        if (!formRef.current.check()) {
            console.error('Form Error');
            setIsSubmitted(false)
            return;
        }
        const res = await updateUser(formValue, user.access_token);

        if (res.status) {
            toaster.push(<Message type="success">Profile updated successfully</Message>);
            setUser(res.data);
            setCurrentUser(res.data)
        } else {
            toaster.push(<Message type="success">Error updating profile</Message>);
        }

        setIsSubmitted(false)
        // console.log(formValue, 'Form Value');
    };

    return (
        <Content>
            <Grid fluid style={{marginLeft: 15}}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    disabled={isSubmitted}
                    fluid
                    className='user-profile-edit-form'
                >

                    <Row>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Form.Group controlId={'avatar'}>
                                        <Form.ControlLabel>Profile Image </Form.ControlLabel>
                                        <Uploader
                                            headers={getHeaderToken(user.access_token)}
                                            fileListVisible={false}
                                            listType="picture"
                                            name="image"
                                            accept={'image/*'}
                                            action={''}
                                            autoUpload={false}
                                            data={{use_id: user.use_id}}
                                            onChange={async (file) => {
                                                setIsLogoUploaded(false);
                                                const logo_file = file.pop()
                                                setLogoFile(logo_file);
                                                previewFile(logo_file.blobFile, value => setFileInfo(value))
                                            }}
                                        >
                                            <button style={styles}>
                                                {uploading && <Loader backdrop center/>}
                                                {fileInfo ?
                                                    (<img src={fileInfo} width="100%" height="100%" alt='...'/>)
                                                    : user.image ?
                                                        (<img src={user.image} width="100%" alt='...'/>)
                                                        :
                                                        (<Avatar src={user.image}
                                                                 style={{fontSize: 80}}/>)
                                                }
                                            </button>
                                        </Uploader>
                                        <br/>
                                        <Button
                                            disabled={isLogoUploaded || !logoFile}
                                            onClick={async () => handleLogoUpload(setIsLogoLoading, setIsLogoUploaded, setFormValue, logoFile,'image', user.access_token)}
                                            loading={isLogoLoading}
                                            style={{marginTop: 10, width: 'auto'}}
                                            className={'d-w-r-btn'}
                                        >
                                            Start Upload
                                        </Button>



                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <TextField name="firstName" label="First Name" helpText='Required'/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>

                                    <TextField name="lastName" label="Last Name" helpText='Required'/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <TextField
                                        name="email"
                                        // checkAsync={true}
                                        label="Email" helpText='Cannot Edit Email' type="email" disabled/>

                                </Col>
                            </Row>
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary"
                                        // type="submit"
                                            className='d-w-r-btn'
                                            style={{width: 'auto'}}
                                            onClick={handleSubmit}
                                            disabled={Object.keys(formError).length !== 0 || isSubmitted || !isLogoUploaded }
                                            loading={isSubmitted}
                                    >Submit</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            {/*<div className='default-bgcolor' style={{padding:'20px'}}>
                                <Placeholder.Graph/>
                            </div>*/}
                        </Col>
                    </Row>

                </Form>

            </Grid>
        </Content>

    );
}

export default UserProfile;

function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result);
    };
    reader.readAsDataURL(file);
}

const styles = {
    width: 150,
    height: 150
};