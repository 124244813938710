import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Content, Grid, Nav, Row} from "rsuite";
import '../styles/resgister.css'
import CompanyRegister from "components/register/company";
import {AppContext} from "../lib/contextLib";
import {useNavigate} from "react-router-dom";
import {CustomSpinner} from "../components/common/spinner";


const Register = () => {
    const {user, setUser} = useContext(AppContext);
    const [event, setEvent] = useState('')
    const [loading, setLoading] = useState(false)
    const [formValue, setFormValue] = useState({
        firstName: '',
        lastName: '',
        type:'',
        email: '',
        password: '',
        verifyPassword: ''
    });

    const navigate = useNavigate();

    const handleChange = (name, value) => {
        setFormValue(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        setLoading(true)
        if (localStorage.getItem('user')) {
            navigate('/');
        }
        handleChange('type', event);
        setLoading(false)
    }, [event]);


    console.log(formValue);


    if (loading) return <CustomSpinner/>

    return (
        <Container className='wrapper '>
            <Content>
                <h3 className='border-default default-bgcolor text-start register-h2'>REGISTER AS</h3>

                <Grid fluid className='register-row'>
                    <Row>
                        <Col xs={24} sm={24} md={5} lg={5} className='text-start'>
                            <Nav vertical className='register-nav' onSelect={setEvent} activeKey={event}>
                                <Nav.Item eventKey="COMPANY">COMPANY</Nav.Item>
                                <Nav.Item eventKey="JOBSEEKER">JOB SEEKER</Nav.Item>
                                <Nav.Item eventKey="TUTOR">TUTOR</Nav.Item>
                            </Nav>
                        </Col>
                        <Col xs={24} sm={24} md={19} lg={19} className='text-start register-col'
                             style={{padding: '10px 20px'}}>
                            <CompanyRegister type={event} formValue={formValue} setFormValue={setFormValue}/>
                            {/*<SwitchCase value={event}/>*/}
                        </Col>
                    </Row>
                </Grid>
            </Content>
        </Container>);
};

export default Register;
