// noinspection JSUnusedLocalSymbols

import React, {useEffect, useState} from "react";
import ReactHtmlParser from 'react-html-parser';
import {HiOutlineLocationMarker} from "react-icons/hi";
import {CustomSpinner} from "components/common/spinner";
import {Col, Container, Grid, List, Panel, Row} from "rsuite";
import {getJobSeeker} from "service/jobseeker.service";
import {getCurrentUser} from "service/auth.service";
import Config from "../../../config.json";


export default function JobSeekerDetailView({data}) {

    const [user, setUser] = useState(getCurrentUser());
    const [resData, setResData] = useState();
    const [reason, setReason] = useState('');
    const [isChecked, setIsChecked] = useState(false)
    const [loading, setLoading] = useState(false)

    const baseUrl = Config.BASE_API_URL;

    useEffect(async () => {
        // console.log(user)
        setLoading(true)
        if (user?.jobseeker[0]){
            const Detail = await getJobSeeker(user?.jobseeker[0]?.jse_id, user.access_token)
            setResData(Detail?.data)
            setIsChecked(Detail?.data?.isActive)
            setReason(Detail?.data?.reason)
        }

        setLoading(false)

    }, [data])

    if (loading) return <CustomSpinner/>

    if (!resData) return <p>Places Fill Your Profile</p>


    const CurrentPin = ({text}) => {
        return (<div>
            <HiOutlineLocationMarker color={'red'} size={28}/>
            <p style={{color: 'red'}}>{text}</p>
        </div>)
    }


    return (

                    <Container style={{marginLeft: '13px'}}>
                        <h1 className='default-color'
                            style={{textAlign: 'center'}}> {resData?.firstName} {resData?.lastName}</h1>
                        <h4 className='default-color' style={{textAlign: 'center'}}> ({resData?.currentPosition}) </h4>


                        <Grid fluid  style={{marginTop:"20px"}}>

                            <Row gutter={25}>


                                <Col lg={6} md={6} sm={24} xs={24}>

                                        {/*<div className='mb-3'>&nbsp;</div>*/}
                                        {
                                            resData?.image ?

                                                <div style={{
                                                    width: 'fit-content',
                                                    height: 'auto',
                                                    border: "2px solid #E91F20",
                                                    borderRadius: "50%",
                                                    overflow: 'hidden',
                                                    margin: '0 auto'
                                                }}>
                                                    <img
                                                        src={resData?.image}
                                                        alt="home"
                                                        width={200}
                                                        height={200}
                                                    />
                                                </div>
                                                :
                                                <></>
                                        }

                                        {/*cards*/}
                                        <h2 style={{margin: '20px 0'}}>Details</h2>

                                        <div className="text-start jobseekers-info">


                                            <Panel header="Gender" shaded>
                                                <p className="card-text">{resData?.gender}</p>
                                            </Panel>
                                            <Panel header="phone" shaded>
                                                <a href={`tel:${resData?.phone}`}
                                                >{resData?.phone}</a>
                                            </Panel>
                                            <Panel header="Email" shaded>
                                                <a href={`mailto:${resData?.email}`}
                                                   className="card-text">{resData?.email}</a>
                                            </Panel>
                                            <Panel header="Website" shaded>
                                                <a href={resData?.website}
                                                   className="card-text">{resData?.website}</a>
                                            </Panel>
                                            <Panel header="Date of Birth" shaded>
                                                <p className="card-text">{new Date(resData?.dob).toLocaleDateString('en-US')}</p>
                                            </Panel>
                                            <Panel header="Current Salary" shaded>
                                                <p className="card-text">{resData?.currentSalary?.toLocaleString('en-US')}</p>
                                            </Panel>
                                            <Panel header="Expected Salary" shaded>
                                                <p className="card-text">{resData?.expectedSalary?.toLocaleString('en-US')}</p>
                                            </Panel>
                                            <Panel header="Address" shaded>
                                                <p className="card-text">{resData?.address}</p>
                                            </Panel>
                                            <Panel header="Postal Code" shaded>
                                                <p className="card-text">{resData?.postalCode}</p>
                                            </Panel>

                                        </div>

                                </Col>

                                <Col lg={18} md={18} sm={24} xs={24} className={'jobseekers-details'}>




                                        <Panel  shaded bordered style={{textAlign: 'justify', paddingLeft: '20px', paddingRight: '20px',hyphens:'auto'}} header={<h4>About</h4>}>
                                            {ReactHtmlParser(resData?.description)
                                            }
                                        </Panel>


                                    <Panel collapsible className="shadow p-2 mb-2" shaded header={<h4>Education</h4>}>



                                            {/*<h1 style={{textAlign: 'left'}}>Education</h1>*/}

                                            <List size='sm'>
                                                {
                                                    resData?.education?.map((data, index) =>
                                                        <List.Item key={index} index={index}>
                                                            <h5 className="mb-1">{data?.instituteName}  <small>({new Date(data?.fromDate).toLocaleDateString('en-US', {year: 'numeric'})} - {data?.isCompleted ? new Date(data?.toDate).toLocaleDateString('en-US', {year: 'numeric'}) : 'Present'})</small></h5>
                                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between'  }}>
                                                                <h6 className="mb-1">{data?.fieldOfStudy} - {data?.degree}</h6>
                                                                <small>{data?.grade}</small>
                                                            </div>
                                                            {/*<h6>{new Date(data?.fromDate).toLocaleDateString('en-US', {year: 'numeric'})} - {data?.isCompleted ? new Date(data?.toDate).toLocaleDateString('en-US', {year: 'numeric'}) : 'Present'}</h6>*/}
                                                            <p >{data?.description}</p>
                                                    </List.Item>

                                                    )
                                                }

                                            </List>




                                    </Panel>
                                    <Panel collapsible shaded bordered  header={<h4>Experience</h4>}>
                                        <List size='sm'>
                                                {
                                                    resData?.experience?.map((data, index) =>
                                                        <List.Item key={index} index={index}>
                                                            <h4 className="mb-1">{data?.title} <small>({data?.companyName})</small></h4>
                                                            {/*<h5 className="mb-1">{data?.companyName}</h5>*/}
                                                            <h6 className='mb-1'>{new Date(data?.fromDate).toLocaleDateString('en-US', {year: 'numeric'})} - {data?.isCompleted ? new Date(data?.toDate).toLocaleDateString('en-US', {year: 'numeric'}) : 'Present'}</h6>
                                                            <p className="mb-1">{data?.description}</p>
                                                        </List.Item>                                                    )
                                                }
                                        </List>


                                    </Panel>
                                    <Panel collapsible shaded bordered header={<h4>Projects</h4>}>

                                        <List size='sm'>
                                                {
                                                    resData?.project?.map((data, index) =>
                                                        <List.Item key={index} index={index}>

                                                            <h5 className="mb-1">{data?.projectName}</h5>
                                                            <h5 className="mb-1"><a
                                                                href={data?.projectUrl}> {data?.projectUrl}</a></h5>
                                                            <h6 className='mb-1'>{new Date(data?.startedDate).toLocaleDateString('en-US', {year: 'numeric'})} - {data?.isOnGoing ? 'Present' : new Date(data?.endDate).toLocaleDateString('en-US', {year: 'numeric'}) }</h6>
                                                            <p className="mb-1">{data?.description}</p>
                                                        </List.Item>                                                    )
                                                }
                                        </List>



                                    </Panel>

                                </Col>
                            </Row>

                        </Grid>

                    </Container>
          )
}

