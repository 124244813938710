import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "rsuite";
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import LocationIcon from "@rsuite/icons/Location";
import { CustomSpinner } from "components/common/spinner";
import { Link } from "react-router-dom";
import Config from "config.json";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import EmailFillIcon from "@rsuite/icons/EmailFill";
import { MdLocationPin } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { SearchCompanyComponent } from "components/searchCompany";
import InfiniteScroll from "react-infinite-scroll-component";
import { CustomSmallSpinner } from "components/common/spinner";
import { EndingNote, NoDataFoundNote } from "components/common/listDataEnd";
import { getAllCompanies } from "service/company.service";
import { useStateWithDeps } from "use-state-with-deps";
import GlobalIcon from "@rsuite/icons/Global";
import { getAllCountries } from "service/setting.service";
import { getAllJobs } from "service/job.service";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import Hidden from "@mui/material/Hidden";
import { Row, Col } from "react-bootstrap";
import {
  Container,
  Content,
  Divider,
  Header,
  Panel,
  Stack,
  List,
} from "rsuite";
import arrow from "../../images/arrow.PNG";

const JobsFeed = ({ type, name }) => {
  const [searchData, setSearchData] = useState({
    text: "",
    country: 0,
    city: 0,
  });
  const [key, setKey] = useState("list");
  const [country, setCountry] = React.useState([]);
  const [city, setCity] = React.useState([]);
  const [totalRecords, setTotalRecord] = React.useState(0);
  const [resData, setResData] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("com_id");
  const [sortDirection, setSortDirection] = useState("asc");

  const baseUrl = Config.BASE_API_URL;
  const getJobsUrl = baseUrl + Config.GET_JOBS_URL;
  const params = `skip=${
    page * perPage
  }&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}`;
  const searchParam = `cit_id=${searchData?.city}&searchText=${searchData?.text}`;
  const url = `${getJobsUrl}?${params}&${searchParam}`;

  useEffect(async () => {
    setLoading(true);
    setCountry(await getAllCountries());
    setCity([]);
    await fetchData();
    setPage(1);
    setLoading(false);
  }, []);

  if (loading) return <CustomSpinner />;

  const handleChange = (name, value) => {
    setSearchData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    const params = `skip=0&take=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}`;
    const url = `${getJobsUrl}?${params}&${searchParam}`;
    const response = await getAllJobs(url);
    setResData(response?.status ? response?.data?.jobs : []);
    setTotalRecord(response?.status ? response?.data?.count : 0);
    setHasMore(response?.data.count > 0);
    console.log("Here is job data: ", response?.data?.jobs);
  };

  const handleSubmit = () => {
    // setSearchResult(true)

    console.log(searchData);
  };

  const fetchDataOnScroll = async () => {
    if (resData.length >= totalRecords) {
      setHasMore(false);
      return;
    }
    const response = await getAllCompanies(url);
    setResData((prevState) =>
      prevState.concat(response?.status ? response?.data?.jobs : [])
    );
  };
  return (
    <div className="jobs-feed" style={{ background: "transparent" }}>
      <div className="heading">
        <h2 className="default-color">Jobs Feed</h2>
      </div>
      <List
        size={"sm"}

        
      >
        {resData?.map((d, index) => (
          <List.Item
            disabled
            key={index}
            style={{
              overflow: "hidden",
              padding: "10px 20px",
              background: "transparent",
              border: "1px solid #f5f5f5",
              boxShadow: "none",
              outline: "none",
            }}
          >
            <Hidden only={["lg", "xl", "md"]}>
              <Panel
                shaded
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                  margin: "5px",
                }}
                header={
                  <Stack spacing={20} className="">
                    <Divider
                      style={{ background: "transparent", margin: "5px 0" }}
                    />
                    <div
                      // style={{
                      //   width: "15em",
                      //   textAlign: "left",
                      //   whiteSpace: "nowrap",

                    >
                      <Link to={`/job/detail/${d.job_id}`}>
                        <h5
                          style={{
                            backgroundColor: "#fff",
                            color: "#0C3144",
                            // textAlign: "left",
                            textOverflow: "ellipsis",
                            // resize: "horizontal",
                            overflow: "hidden",
                          }}
                        >
                          {d?.title}
                        </h5>
                      </Link>
                    </div>
                  </Stack>
                }
              >
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                  }}
                >
                  {/*<br />*/}
                  <div
                    className="text-start "
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ marginBottom: "8px", fontWeight: "500" }}>
                      <span>Min Experience: &nbsp;</span>
                      <div
                        className="text_div"
                        style={{ width: "calc(100% - 150px)" }}
                      >
                        <span>{d.minExperience} Years</span>
                      </div>
                    </div>
                  </div>
                  {/*<br />*/}
                  <div>
                    {" "}
                    <h6 style={{ fontSize: "16px", textAlign: "left" }}>
                      Type: &nbsp;
                      {d?.employmentType === "part_time"
                        ? "Part Time"
                        : "Full Time"}
                    </h6>
                  </div>
                  {/*<br />*/}
                  {/*<div style={{ fontSize: "12pt" }} className="text-start">*/}
                  {/*  <div>*/}
                  {/*    <span>Posted Date: &nbsp;</span>*/}
                  {/*    <div*/}
                  {/*      className="text_div"*/}
                  {/*      style={{ width: "calc(100% - 150px)" }}*/}
                  {/*    >*/}
                  {/*      <span style={{ fontWeight: "bold", fontSize: "12pt" }}>*/}
                  {/*        29/06/2022  */}
                  {/*      </span>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </Panel>
            </Hidden>

            <Hidden only={["sm", "xs"]}>
              <Container>
                <Panel
                  shaded
                  style={{ backgroundColor: "#fff", margin: "5px" }}
                >
                  <Divider
                    style={{ background: "transparent", margin: "5px 0" }}
                  />
                  <Row>
                    <Col xs={1}>
                      <div>
                        <Link to={`/job/detail/${d.job_id}`}>
                          <img
                            src={arrow}
                            alt={d?.name}
                            style={{
                              objectFit: "contain",
                              background: "transparent",
                              margin: 0,
                              padding: 0,
                              width: "5em",
                              height: "5em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                    <Col xs={8}>
                      <div
                        style={{
                          textAlign: "left",
                          background: "#fff",
                        }}
                      >
                        <div>
                          <h4
                            style={{
                              padding: "0px 20px ",
                              // backgroundColor: "#fff",
                              color: "#0C3144",
                              // textOverflow: "ellipsis",
                              // resize: "horizontal",
                              // overflow: "hidden",
                            }}
                          >
                            {d?.title}
                          </h4>
                        </div>
                        <div
                        // style={{ marginLeft: "3em" }}
                        >
                          {" "}
                          <h6
                            style={{
                              fontSize: "14px",
                              padding: "0px 20px ",
                              color: "#0C3144",
                            }}
                          >
                            Type: &nbsp;
                            {d?.employmentType === "part_time"
                              ? "Part Time"
                              : "Full Time"}
                          </h6>
                        </div>
                        <div
                          className="text-start "
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                            padding: "0px 20px ",
                            fontSize: "14px",
                            marginTop: "-0.5em",
                          }}
                        >
                          <div style={{ fontWeight: "500" }}>
                            <span>Min Experience: &nbsp;</span>
                            <div
                              className="text_div"
                              style={{ width: "calc(100% - 150px)" }}
                            >
                              <span>{d.minExperience} Years</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div style={{ marginTop: "2em" }}>
                        <div
                          style={{
                            fontSize: "14px",
                          }}
                          className="text-start"
                        >
                          {/*<div>*/}
                          {/*  <span>Posted Date: &nbsp;</span>*/}
                          {/*  <div*/}
                          {/*    className="text_div"*/}
                          {/*    style={{ width: "calc(100% - 150px)" }}*/}
                          {/*  >*/}
                          {/*    <span*/}
                          {/*      style={{ fontWeight: "500", fontSize: "14px" }}*/}
                          {/*    >*/}
                          {/*      29/06/2022*/}
                          {/*    </span>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Panel>
              </Container>
            </Hidden>
          </List.Item>
        ))}
        <br />
        <h6
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            textDecoration: "underline",
            color: "red",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "14pt",
          }}
        >
          <Link to={"/jobs"}>See More</Link>
        </h6>
        <br />
      </List>
    </div>
  );
};

export default JobsFeed;