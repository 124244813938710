import {Button, Carousel, Col, Grid, List, Row} from "rsuite";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {CustomSpinner} from "components/common/spinner";
import {getAllEvents} from "service/event.service";
import Config from "../../config.json";
import {getAllBanners} from "../../service/banners.service";


export const EventsHomePage = () => {

    const [resData, setResData] = useState([]);
    const [resImages, setResImages] = useState([]);
    const [loading, setLoading] = useState(false);

    const baseUrl = Config.BASE_API_URL;
    const getEventsUrl = baseUrl + Config.GET_EVENTS_URL;

    useEffect(async () => {

            const params = `type=listing&skip=0&take=10`
            const url = `${getEventsUrl}?${params}`
            setLoading(true)
            const response = await getAllEvents(url)
            const resImg = await getAllBanners(url)
            setResImages(resImg?.data)
            setResData(response.status ? response.data.events : [])

            setLoading(false)
        }, []
    )


    return (
        <Grid fluid>
            <Row className="show-grid">
                <Col xs={24} sm={24} md={16} lg={16}>
                    {/*<Carousel autoplay className="custom-slider" style={{height: '600px'}}>*/}
                    {/*    {data.map((src, index) => */}
                    {/*        <img alt={'image'} key={index} src={src.download_url}/>*/}
                    {/*    )}*/}
                    {/*</Carousel>*/}
                    <Carousel style={{height:"600px"}}>
                        {
                            resImages.map((src, index) =>

                                <div key={index} className='image-cover events-pic' >

                                    {
                                        src.slag ?
                                            src.slag.includes('http') ?
                                                <a rel="noreferrer"  href={src.slag}  target="_blank">
                                                    <img alt='events-pic' src={src.img}/>
                                                </a>
                                                :
                                            <Link to={src.slag}>

                                                <img alt='events-pic' src={src.img}/>
                                            </Link> :
                                                <img alt='events-pic' src={src.img}/>

                                    }
                                </div>
                            )
                        }
                    </Carousel>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                    <div style={{backgroundColor: "#fff",}}>
                        <h4
                            style={{
                                padding: '0px 10px ',
                                backgroundColor: "#fff",
                                color: "#0C3144",
                                // borderBottom: '1px solid #ccc'
                            }}>
                            Events
                        </h4>
                        <div className=" custom-scroll display-list">
                            {loading ? <CustomSpinner/>
                                :
                                <List autoScroll={true}>
                                    {resData.map((item, index) => (
                                        <List.Item key={index} index={index}>
                                            <div style={{width: '70%'}}>
                                                <p title={item.title}>
                                                    {item.title?.length > 20 ? `${item?.title?.slice(0, 20)}... ` : item?.title}
                                                    <small>({item?.city?.name})</small>
                                                    <small>{` ${new Date(item?.startDate).toLocaleDateString('en-GB', {day: 'numeric'})} - ${new Date(item?.endDate).toLocaleDateString('en-GB', {
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: "numeric"
                                                    })}`}</small>
                                                    {/*<br/> <small>({item?.eventtype?.name})</small>*/}
                                                </p>
                                                {/*<p>
                                                {item?.eventsubcategory?.name}, <small>{item?.eventsubcategory?.eventcategory?.name}</small>
                                            </p>*/}
                                            </div>
                                            <Button
                                                as={Link}
                                                to={`/event/detail/${item.eve_id}`}
                                                // state={{eve_id: item.eve_id}}
                                                className="default-color round-btn"
                                                style={{width: 'auto', margin: '0px', height: 'fit-content'}}


                                            >View Details</Button>

                                        </List.Item>
                                    ))}
                                </List>
                            }

                        </div>
                        <Button
                            className='d-w-r-btn'
                            as={Link}
                            to={`/events`}
                            // style={{borderRadius: '20px'}}
                        >
                            See All
                        </Button>
                    </div>
                </Col>

            </Row>

        </Grid>

    )
}