export const Constants = {
    'GENDER' : [
        {value: 'M', label: 'Male'},
        {value: 'F', label: 'Female'},
        {value: 'O', label: 'Other'}
    ],
    'OFFERED_AT': [
        {value: 0, label: 'Online'},
        {value: 1, label: 'On Campus'},
    ],
}

