import React from "react";
import {ButtonGroup, IconButton, Input, Radio, RadioGroup} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";

export const MediaUrlInputControl = ({value = [{path: '', mediaType: 'Video'}], onChange, fieldError}) => {
    const errors = fieldError ? fieldError.array : [];
    const [media, setMedia] = React.useState(value.length === 0 ? [{path: '', mediaType: 'Video'}] : value);
    const handleChangeProducts = nextProducts => {
        setMedia(nextProducts);
        onChange(nextProducts);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextProducts = [...media];
        nextProducts[rowIndex] = value;
        handleChangeProducts(nextProducts);
    };

    const handleMinus = () => {
        handleChangeProducts(media.slice(0, -1));
    };
    const handleAdd = () => {
        handleChangeProducts(media.concat([{path: '', mediaType: 'Video'}]));
    };
    return (
        <table style={{width:'inherit'}}>
            <thead>
            <tr>
                <Cell>Url Only For Videos</Cell>
                <Cell>Type</Cell>
            </tr>
            </thead>
            <tbody>
            {media.map((rowValue, index) => (
                <ProductItem
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index].object : null}
                    onChange={handleInputChange}
                />
            ))}
            </tbody>
            <tfoot>
            <tr>
                <Cell colSpan={2} style={{paddingTop: 10}}>
                    <ButtonGroup size="xs">
                        <IconButton onClick={handleAdd} icon={<PlusIcon/>}/>
                        <IconButton disabled={value.length === 1} onClick={handleMinus} icon={<MinusIcon/>}/>
                    </ButtonGroup>
                </Cell>
            </tr>
            </tfoot>
        </table>
    );
};
const ProductItem = ({rowValue = {}, onChange, rowIndex, rowError}) => {
    const handleChangeName = value => {
        onChange(rowIndex, {...rowValue, path: value});
    };
    const handleChangeType = value => {
        onChange(rowIndex, {...rowValue, mediaType: value});
    };

    return (
        <tr>
            <Cell>
                <Input value={rowValue.path} onChange={handleChangeName}/>
                {rowError ? <ErrorMessage>{rowError.path.errorMessage}</ErrorMessage> : null}
            </Cell>
            <Cell>
                {/*<InputNumber
                    min={0}
                    value={rowValue.quantity}
                    onChange={handleChangeAmount}
                    style={{ width: 100 }}
                />*/}
                {rowError ? <ErrorMessage>{rowError.mediaType.errorMessage}</ErrorMessage> : null}

                <RadioGroup appearance="picker" inline value={rowValue.mediaType} onChange={handleChangeType}>
                    {/*<span >Change status: </span>*/}
                    {/*<Radio value="Image">Image</Radio>*/}
                    <Radio value="Video">Video</Radio>
                </RadioGroup>
            </Cell>
        </tr>
    );
};
const ErrorMessage = ({children}) => <span style={{color: 'red'}}>{children}</span>;
const Cell = ({children, style, ...rest}) => (
    <td style={{padding: '2px 4px 2px 0', verticalAlign: 'top', ...style}} {...rest}>
        {children}
    </td>
);
