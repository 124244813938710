import {Button, Col, Grid, Input, Row, SelectPicker} from "rsuite";
import React, {useEffect, useState} from "react";
import '../../styles/searchBox.css'
import {Link, useNavigate} from "react-router-dom";
import {getAllCountries, getCountryCities} from "service/setting.service";
import {getCompanyType} from "service/company.service";

export const SearchComponent = ( ) => {
    const [searchData , setSearchData] = useState({
        text:'',
        type:0,
        country:0,
        city:0,

    });

    const [country, setCountry] = React.useState([]);
    const [city, setCity] = React.useState([]);
    const [type, setType] = React.useState([]);
    const navigate = useNavigate();

    const handleChange = (name, value) => {
        // console.log(name, value);
        setSearchData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    useEffect(async () => {
        setCountry(await getAllCountries())
        setCity([])
        setType(await getCompanyType())

    }, [])

    const handleSubmit = ()=>{
        // setSearchResult(true)

        // console.log(searchData);

    }
    return (
        <div
            className="search-box"
        >

            <div className="wrapper-search">


                <Grid fluid>
                    <Row >
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Input id='search-bar' placeholder="LOOKING FOR ....." onChange={(e)=>handleChange('text',e)}/>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} >
                            <SelectPicker placeholder="Select Type" data={type} onChange={(e)=>handleChange('type',e)}/>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} >
                            <SelectPicker placeholder="Select Country" data={country}
                                          onChange={async (cou_id) => {
                                              setSearchData(prevState => ({
                                                  ...prevState,
                                                  city: '',
                                                  country: cou_id
                                              }))
                                              setCity(await getCountryCities(cou_id))
                                          }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} >
                            <SelectPicker placeholder="Select City" data={city} onChange={(e)=>handleChange('city',e)} />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} style={{ backgroundColor: '#EA1F24' }}>
                            <Button
                                as={Link}
                                to="/company"
                                state={{searchData  }}

                                className='d-w-r-btn'
                                // onClick={handleSubmit}

                            >
                                SEARCH
                            </Button>
                        </Col>

                    </Row>

                </Grid>

            </div>
        </div>
    )
}