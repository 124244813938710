import React, {useState} from "react";
import {ButtonGroup, Col, Divider, IconButton, Input, Row} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";
import ReactQuill from "react-quill";
import {formats, modules} from "components/profile/company/reactQuilConfig";


export const TicketPrice = ({value = [], onChange, fieldError}) => {
    const errors = fieldError ? fieldError.array : [];
    const [tickets, setTickets] = useState(value);
    const handleChangeTicketPrice = nextTicket => {
        setTickets(nextTicket);
        onChange(nextTicket);
    };
    const handleInputChange = (rowIndex, value) => {
        const nextTicket = [...tickets];
        nextTicket[rowIndex] = value;
        handleChangeTicketPrice(nextTicket);
    };

    const handleMinus = () => {
        handleChangeTicketPrice(tickets.slice(0, -1));
    };
    const handleAdd = () => {
        handleChangeTicketPrice(tickets.concat([{name: '', price: 0, description: ''}]));
    };
    return (
        <table style={{width: 'inherit'}}>
            {/*            <thead>
            <tr>
                <Cell>Name</Cell>
                <Cell>Designation</Cell>
                <Cell>Description</Cell>
                <Cell>URL</Cell>
            </tr>
            </thead>*/}
            <tbody>
            {tickets.map((rowValue, index) => (
                <TicketsPricingDetail
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index].object : null}
                    onChange={handleInputChange}
                />
            ))}
            </tbody>
            <tfoot>
            <tr>
                <Cell colSpan={2} style={{paddingTop: 10}}>
                    <ButtonGroup size="xs">
                        <IconButton onClick={handleAdd} icon={<PlusIcon/>}/>
                        <IconButton disabled={value.length === 1} onClick={handleMinus} icon={<MinusIcon/>}/>
                    </ButtonGroup>
                </Cell>
            </tr>
            </tfoot>
        </table>
    );
};

const TicketsPricingDetail = ({rowValue = {}, onChange, rowIndex, rowError}) => {
    const handleChangeName = value => {
        onChange(rowIndex, {...rowValue, name: value});
    };
    const handleChangePrice = value => {
        onChange(rowIndex, {...rowValue, price: value});
    };
    const handleChangeDescription = value => {
        onChange(rowIndex, {...rowValue, description: value});
    };

    return (
        <tr>
            <Divider style={{backgroundColor: '#0C3144', margin: '24px 0'}}/>
            <h6 style={{marginBottom: '5px'}}> Ticket {rowIndex + 1}</h6>
            <Row>
                <Col xs={24} sm={24} md={6} lg={6}>
                    <p>Ticket Type</p>
                    <Input value={rowValue.name} onChange={handleChangeName}/>
                    {rowError ? <ErrorMessage>{rowError.name.errorMessage}</ErrorMessage> : null}
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                    <p>Price</p>
                    <Input value={rowValue.price} onChange={handleChangePrice} min={0} type={'number'}/>
                    {rowError ? <ErrorMessage>{rowError.price.errorMessage}</ErrorMessage> : null}
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                <ReactQuill
                    theme="snow"
                    value={rowValue?.description}
                    onChange={handleChangeDescription}
                    modules={modules}
                    formats={formats}
                    style={{marginRight: 25}}
                />
                </Col>
            </Row>

        </tr>

    );
};
const ErrorMessage = ({children}) => <span style={{color: 'red'}}>{children}</span>;
const Cell = ({children, style, ...rest}) => (
    <td style={{padding: '2px 4px 2px 0', verticalAlign: 'top', ...style}} {...rest}>
        {children}
    </td>
);
