import React, {useState} from 'react';
import {Avatar, Container, Content, Divider, Nav, Panel, Stack} from "rsuite";
import '../../../styles/profile/company/companyHeader.css'
import UserProfile from "components/profile/userProfile";
import ChangePassword from "components/profile/changePassword";
import Config from "../../../config.json";
import {getCurrentUser} from "service/auth.service";
import JobSeekerDetailView from "components/profile/jobSeeker/jobSeekersProfile";
import '../../../styles/profile/jobseekers.css'
import JobSeekerProfileForm from "components/profile/jobSeeker/jobSeekersProfileForm";
import humanizeString from "../../../lib/utility";

function JobSeekerHeader({data}) {
    const [valueNav, setValueNav] = useState('viewProfile')

    const [user, setUser] = useState(getCurrentUser());
    const [editOrNew, setEditOrNew] = useState(false)
    const baseUrl = Config.BASE_API_URL;


    function SwitchCase({value}) {
        switch (value) {
            case 'viewProfile':
                return <JobSeekerDetailView/>;
            case 'profile':
                return <UserProfile/>;
            case 'jobSeekerProfile':
                return <JobSeekerProfileForm jse_id={user?.jobseeker[0]?.jse_id} editOrNew={editOrNew}/>;
            case 'changePassword':
                return <ChangePassword/>;
            default:
                return <JobSeekerDetailView/>;
        }
    }

    return (
        <Container>
            <Content>
                <Divider/>
                <Panel className='company-header-wrapper'>
                    <Stack spacing={20} className="header-flex-col">
                        <Avatar
                            size="lg"
                            src={user.image}
                            alt="User Profile"
                        />
                        <div>
                            <h2>{data?.firstname} {data?.lastname}</h2>
                            <h4>{data?.email}</h4>
                        </div>


                    </Stack>
                </Panel>
                <Divider style={{backgroundColor: 'var(--rs-divider-border)', margin: '12px 0'}}/>
                <Container>
                    <Panel>
                        <Nav justified className='company-header-nav' onSelect={setValueNav} activeKey={valueNav}>
                            <Nav.Item eventKey='viewProfile'>
                                View Profile
                            </Nav.Item>
                            <Nav.Item eventKey='jobSeekerProfile'>Edit Profile</Nav.Item>
                            <Nav.Item eventKey='profile'>User Profile</Nav.Item>
                            <Nav.Item eventKey='changePassword'>Change Password</Nav.Item>
                        </Nav>
                        <Divider/>
                        <Content style={{
                            backgroundColor: 'white',
                            padding: '15px',
                            boxShadow: 'var(--rs-panel-shadow)',
                            borderRadius: '3px'
                        }}>
                            <Stack>

                                <h1 className='default-color'
                                    style={{margin: '15px', textTransform: 'uppercase', fontSize: '30px'}}>
                                    {humanizeString(valueNav)}
                                </h1>

                            </Stack>
                            <SwitchCase value={valueNav}/>
                        </Content>

                    </Panel>
                </Container>

            </Content>

        </Container>);
}

export default JobSeekerHeader;