import React, {useContext} from "react";
import {Button, ButtonToolbar, Dropdown, Popover, Whisper} from "rsuite";
import {LinkButtonSm, PrimaryButtonSm} from "../../common/buttons/button";
import {Link} from "react-router-dom";
import {AppContext} from "../../../lib/contextLib";
import Config  from '../../../config.json'

const Topbar = () => {
    const {user, setUser} = useContext(AppContext);
    return (

            <div className="topbar">

                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <ButtonToolbar>
                        <LinkButtonSm
                            style={{color: '#ffffff'}}
                            href={`mailto:${Config.EMAIL}`}
                        >
                            {Config.EMAIL}
                        </LinkButtonSm>

                        <LinkButtonSm
                            style={{color: '#ffffff'}}
                            href={`tel:${Config.PHONE}`}
                        >
                            {Config.PHONE}
                        </LinkButtonSm>
                    </ButtonToolbar>




                <ButtonToolbar style={{display: 'flex', justifyContent: 'flex-end', float: 'right'}}>

                    {/*<Whisper*/}
                    {/*    trigger="click"*/}
                    {/*    placement={'autoVertical'}*/}
                    {/*    controlId={`control-id-autoVertical`}*/}
                    {/*    speaker={*/}
                    {/*        <Popover>*/}
                    {/*            <Dropdown.Menu>*/}
                    {/*                <Dropdown.Item>+1 778 804 9199</Dropdown.Item>*/}
                    {/*                <Dropdown.Item>CHAT NOW</Dropdown.Item>*/}
                    {/*                <Dropdown.Item>OPEN A TICKET</Dropdown.Item>*/}
                    {/*                <Dropdown.Item*/}
                    {/*                    as='div'*/}
                    {/*                >*/}
                    {/*                    <a href='/contactus'*/}
                    {/*                    >*/}
                    {/*                        CONTACT US*/}
                    {/*                    </a>*/}
                    {/*                </Dropdown.Item>*/}
                    {/*            </Dropdown.Menu>*/}
                    {/*        </Popover>*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <Button*/}
                    {/*        appearance="link"*/}
                    {/*        size="sm"*/}
                    {/*        // href={href}*/}
                    {/*        as={'button'}*/}
                    {/*        style={{color: '#ffffff'}}*/}

                    {/*    >*/}
                    {/*        Contact*/}
                    {/*    </Button>*/}
                    {/*</Whisper>*/}


                    {/*{*/}

                    {/*    !!user &&*/}

                        <LinkButtonSm

                            as={Link}
                            to={'/profile'}
                            color='cyan'
                            style={{color: '#ffffff'}}
                            className='display-none-sm'

                        >
                            Client Area
                        </LinkButtonSm>

                    <PrimaryButtonSm
                        style={{backgroundColor: '#EB1F23',height:'fit-content'}}
                        as={Link}
                        to={'/profile'}
                        state= {{addNew: 'addNew'}}

                    >
                        + ADD LISTING
                    </PrimaryButtonSm>

                </ButtonToolbar>
                </div>

            </div>
    );

};

export default Topbar;
