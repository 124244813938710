import React, {useState} from 'react';
import {Avatar, Container, Content, Divider, Nav, Panel, Stack} from "rsuite";
import '../../../styles/profile/company/companyHeader.css'
import ListIcon from '@rsuite/icons/List';
import AdminIcon from '@rsuite/icons/Admin';
import EditIcon from '@rsuite/icons/Edit';
import UserProfile from "components/profile/userProfile";
import ChangePassword from "components/profile/changePassword";
import Config from "../../../config.json";
import {getCurrentUser} from "service/auth.service";
// import humanizeString from 'humanize-string';
import HandlerListing from "components/profile/company/listing/handlerListing";
import humanizeString from "../../../lib/utility";

function CompanyHeader({data}) {
    const [valueNav, setValueNav] = useState('listing')

    const [user, setUser] = useState(getCurrentUser());
    const baseUrl = Config.BASE_API_URL;


    function SwitchCase({value}) {
        switch (value) {
            case 'listing':
                return <HandlerListing/>;
            case 'profile':
                return <UserProfile/>;
            case 'changePassword':
                return <ChangePassword/>;
            default:
                return <HandlerListing/>;
        }
    }

    return (
        <Container>
            <Content>
                <Divider/>
                <Panel className='company-header-wrapper'>
                    <Stack spacing={20} className="header-flex-col">
                        <Avatar
                            size="lg"
                            src={ user.image}
                            alt="@SevenOutran"
                        />
                        <div>
                            <h2>{data?.firstname} {data?.lastname}</h2>
                            <h4>{data?.email}</h4>
                        </div>
                    </Stack>
                </Panel>
                <Divider style={{backgroundColor: 'var(--rs-divider-border)', margin: '12px 0'}}/>
                <Container>
                    <Panel>
                        <Nav justified className='company-header-nav' onSelect={setValueNav} activeKey={valueNav}>
                            <Nav.Item eventKey='listing' icon={<ListIcon/>}>
                                Listing
                            </Nav.Item>
                            <Nav.Item eventKey='profile' icon={<AdminIcon/>}>User Profile</Nav.Item>
                            <Nav.Item eventKey='changePassword' icon={<EditIcon/>}>Change Password</Nav.Item>
                        </Nav>
                        <Divider/>
                        <Content style={{
                            backgroundColor: 'white',
                            padding: '15px',
                            boxShadow: 'var(--rs-panel-shadow)',
                            borderRadius: '3px'
                        }}>
                            <Stack>

                                {
                                    valueNav !== 'listing' &&
                                    <h1 className='default-color'
                                        style={{margin: '15px', textTransform: 'uppercase', fontSize: '30px'}}>
                                        {humanizeString(valueNav)}
                                    </h1>
                                }
                            </Stack>
                            <SwitchCase value={valueNav}/>
                        </Content>

                    </Panel>
                </Container>

            </Content>

        </Container>);
}

export default CompanyHeader;